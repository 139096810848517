import { PlateSize } from "@console/shared";
import { z } from "zod";

export enum DragObjects {
  Oligo = "Oligo",
  Well = "Well",
}

const oligoSchema = z.object({
  geneId: z.string().optional(),
  genePlateId: z.string().optional(),
  id: z.string(),
  name: z.string(),
  platePositioningExpected: z.string(),
  sequence: z.string(),
  synthesized: z.boolean(),
});
export type Oligo = z.infer<typeof oligoSchema>;

const wellSchema = z.object({
  column: z.number(),
  index: z.string(),
  oligoId: z.string().optional(),
  row: z.string(),
});
export type Well = z.infer<typeof wellSchema>;

export const plateSchema = z.object({
  id: z.string(),
  kit: z.string(),
  locked: z.boolean(),
  name: z.string().min(2, "Name must be at least 2 characters"),
  size: z.nativeEnum(PlateSize),
  wells: z.array(wellSchema),
});
export type Plate = z.infer<typeof plateSchema>;

export const workflowSchema = z.object({
  oligos: z.array(oligoSchema),
  plates: z.array(plateSchema).superRefine((plates, ctx) => {
    const names = new Set<string>();
    const duplicateNames = new Set<string>();
    plates.forEach((plate) => {
      if (names.has(plate.name)) {
        duplicateNames.add(plate.name);
      } else {
        names.add(plate.name);
      }
    });

    if (duplicateNames.size > 0) {
      plates.forEach((plate, index) => {
        if (duplicateNames.has(plate.name)) {
          ctx.addIssue({
            code: "custom",
            message: "Plate name must be unique",
            path: [`${index}.name` as const],
          });
        }
      });
    }
  }),
});
export type Workflow = z.infer<typeof workflowSchema>;
