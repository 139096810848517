import { getValidPrimerPosition, updateBlock, updateOligos } from "./utils";

import {
  type GeneDesignParameters,
  type GeneDesign,
  type OligoDirectionType,
  geneDesignSchema,
} from "../logic/types";

// TODO: how to optimize?
// 1. I should not save full sequence in data because its too big and will not changed
export type GeneDesignReducerState = {
  activeBlock: number | null;
  currentLocalTemporaryDesign: GeneDesign | null;
  history: GeneDesign[];
  historyIndex: number | null;
  initialData: GeneDesign | null;
  parameters: GeneDesignParameters;
  validationErrors?: Record<string, string>;
};

export const DEFAULT_PARAMETERS: GeneDesignParameters = {
  blocks: {
    oligos: {
      overlap: {
        max: 19,
        min: 13,
      },
      size: {
        max: 38,
        min: 26,
      },
    },
    overlap: {
      max: 60,
      min: 40,
    },
    primers: {
      size: {
        max: 30,
        min: 20,
      },
    },
    size: {
      max: 400,
      min: 200,
    },
  },
  primers: {
    size: {
      max: 30,
      min: 20,
    },
  },
};

function validate(
  data: GeneDesign,
  parameters: GeneDesignParameters,
): Record<string, string> | undefined {
  const result = geneDesignSchema(parameters).safeParse(data);

  if (!result.success) {
    const errors: { [key: string]: string } = {};

    result.error.errors.forEach((err) => {
      errors[err.path.join(".")] = err.message;
    });

    return errors;
  }
}

function addToHistoryAndValidate(
  data: GeneDesign | null,
  history: GeneDesign[],
  historyIndex: number | null,
  parameters: GeneDesignParameters,
) {
  if (!data) {
    return {
      history: [],
      historyIndex: null,
      validationErrors: {},
    };
  }

  let newHistory = [...history];

  if (newHistory.length >= 10) {
    newHistory = newHistory.slice(newHistory.length - 9);
  }

  if (historyIndex !== null && historyIndex < history.length - 1) {
    newHistory = newHistory.slice(0, historyIndex + 1);
  }

  newHistory.push(structuredClone(data));

  const newHistoryIndex = Math.max(0, newHistory.length - 1);

  const validationErrors = validate(data, parameters);

  return {
    history: newHistory,
    historyIndex: newHistoryIndex,
    validationErrors,
  };
}

export enum GeneDesignActionType {
  UPDATE_PRIMER_POSITION = 0,
  UPDATE_BLOCK_POSITION,
  UPDATE_BLOCK_PRIMER_POSITION,
  UPDATE_OLIGO_POSITION,
  SET_GENE_DATA,
  SET_GENE_PARAMETERS,
  SET_ACTIVE_BLOCK,
  ADD_TO_HISTORY_AND_VALIDATE,
  UNDO,
  REDO,
  RESET,
}

export type UpdateFunctionType = (action: GeneDesignAction) => void;
export type GeneDesignAction =
  | {
      payload: {
        blockIndex: number;
        currentPosition: number;
        location: "start" | "end";
        newPosition: number;
      };
      type: GeneDesignActionType.UPDATE_BLOCK_POSITION;
    }
  | {
      payload: {
        blockIndex: number;
        currentPosition: number;
        location: "start" | "end";
        newPosition: number;
        oligoIndex: number;
      };
      type: GeneDesignActionType.UPDATE_OLIGO_POSITION;
    }
  | {
      payload: {
        blockIndex: number;
        location: "start" | "end";
        newPosition: number;
        primerType: OligoDirectionType;
      };
      type: GeneDesignActionType.UPDATE_BLOCK_PRIMER_POSITION;
    }
  | {
      payload: {
        location: "start" | "end";
        newPosition: number;
        primerType: OligoDirectionType;
      };
      type: GeneDesignActionType.UPDATE_PRIMER_POSITION;
    }
  | {
      payload: GeneDesign;
      type: GeneDesignActionType.SET_GENE_DATA;
    }
  | {
      payload: GeneDesignParameters;
      type: GeneDesignActionType.SET_GENE_PARAMETERS;
    }
  | {
      payload: number;
      type: GeneDesignActionType.SET_ACTIVE_BLOCK;
    }
  | {
      type: GeneDesignActionType.ADD_TO_HISTORY_AND_VALIDATE;
    }
  | {
      type: GeneDesignActionType.UNDO;
    }
  | {
      type: GeneDesignActionType.REDO;
    }
  | {
      type: GeneDesignActionType.RESET;
    };

export const geneDesignInitialReducerState = {
  activeBlock: null,
  currentLocalTemporaryDesign: null,
  history: [],
  historyIndex: null,
  initialData: null,
  parameters: DEFAULT_PARAMETERS,
  validationErrors: {} as GeneDesignReducerState["validationErrors"],
} as GeneDesignReducerState;

export const geneDesignReducer = (
  state: GeneDesignReducerState,
  action: GeneDesignAction,
): GeneDesignReducerState => {
  switch (action.type) {
    case GeneDesignActionType.UPDATE_BLOCK_POSITION: {
      if (!state.currentLocalTemporaryDesign) {
        return state;
      }

      return {
        ...state,
        currentLocalTemporaryDesign: {
          ...state.currentLocalTemporaryDesign,
          blocks: state.currentLocalTemporaryDesign.blocks.map(
            (block, index) => {
              if (index === action.payload.blockIndex) {
                return updateBlock({
                  blockIndex: action.payload.blockIndex,
                  blocks: state.currentLocalTemporaryDesign?.blocks ?? [],
                  location: action.payload.location,
                  newPosition: action.payload.newPosition,
                });
              }
              return block;
            },
          ),
        },
      };
    }
    case GeneDesignActionType.UPDATE_BLOCK_PRIMER_POSITION: {
      if (!state.currentLocalTemporaryDesign) {
        return state;
      }

      return {
        ...state,
        currentLocalTemporaryDesign: {
          ...state.currentLocalTemporaryDesign,
          blocks: state.currentLocalTemporaryDesign.blocks.map(
            (block, index) => {
              if (index === action.payload.blockIndex && block.primers) {
                return {
                  ...block,
                  primers: {
                    ...block.primers,
                    [action.payload.primerType]: {
                      ...block.primers[action.payload.primerType],
                      [action.payload.location]: getValidPrimerPosition({
                        blockEnd: block.end,
                        blockStart: block.start,
                        location: action.payload.location,
                        newPosition: action.payload.newPosition,
                        primerType: action.payload.primerType,
                      }),
                    },
                  },
                };
              }
              return block;
            },
          ),
        },
      };
    }
    case GeneDesignActionType.UPDATE_PRIMER_POSITION: {
      if (!state.currentLocalTemporaryDesign) {
        return state;
      }

      return {
        ...state,
        currentLocalTemporaryDesign: {
          ...state.currentLocalTemporaryDesign,
          primers: {
            ...state.currentLocalTemporaryDesign.primers,
            [action.payload.primerType]: {
              ...state.currentLocalTemporaryDesign.primers[
                action.payload.primerType
              ],
              [action.payload.location]: getValidPrimerPosition({
                blockEnd:
                  state.currentLocalTemporaryDesign.blocks[
                    state.currentLocalTemporaryDesign.blocks.length - 1
                  ].end,
                blockStart: state.currentLocalTemporaryDesign.blocks[0].start,
                location: action.payload.location,
                newPosition: action.payload.newPosition,
                primerType: action.payload.primerType,
              }),
            },
          },
        },
      };
    }
    case GeneDesignActionType.UPDATE_OLIGO_POSITION: {
      if (!state.currentLocalTemporaryDesign) {
        return state;
      }

      return {
        ...state,
        currentLocalTemporaryDesign: {
          ...state.currentLocalTemporaryDesign,
          blocks: state.currentLocalTemporaryDesign.blocks.map(
            (block, index) => {
              if (index === action.payload.blockIndex) {
                return updateOligos({
                  block,
                  currentPosition: action.payload.currentPosition,
                  location: action.payload.location,
                  newPosition: action.payload.newPosition,
                  oligoIndex: action.payload.oligoIndex,
                });
              }
              return block;
            },
          ),
        },
      };
    }
    case GeneDesignActionType.SET_ACTIVE_BLOCK: {
      return {
        ...state,
        activeBlock:
          state.activeBlock === action.payload
            ? null
            : action.payload >= 0 &&
                action.payload <
                  (state.currentLocalTemporaryDesign?.blocks.length ?? 0)
              ? action.payload
              : null,
      };
    }
    case GeneDesignActionType.SET_GENE_DATA: {
      return {
        ...state,
        currentLocalTemporaryDesign: action.payload,
        initialData: structuredClone(action.payload),
        ...addToHistoryAndValidate(
          action.payload,
          state.history,
          state.historyIndex,
          state.parameters,
        ),
      };
    }
    case GeneDesignActionType.ADD_TO_HISTORY_AND_VALIDATE:
      return {
        ...state,
        ...addToHistoryAndValidate(
          state.currentLocalTemporaryDesign,
          state.history,
          state.historyIndex,
          state.parameters,
        ),
      };
    case GeneDesignActionType.UNDO: {
      if (state.historyIndex === null) {
        return state;
      }

      const historyIndex = Math.max(0, state.historyIndex - 1);
      const data = structuredClone(state.history[historyIndex]);
      return {
        ...state,
        currentLocalTemporaryDesign: data,
        historyIndex: historyIndex,
        validationErrors: validate(data, state.parameters),
      };
    }
    case GeneDesignActionType.REDO: {
      if (state.historyIndex === null) {
        return state;
      }

      const historyIndex = Math.min(
        state.history.length - 1,
        state.historyIndex + 1,
      );
      const data = structuredClone(state.history[historyIndex]);
      return {
        ...state,
        currentLocalTemporaryDesign: data,
        historyIndex: historyIndex,
        validationErrors: validate(data, state.parameters),
      };
    }
    case GeneDesignActionType.RESET: {
      return {
        ...state,
        currentLocalTemporaryDesign: structuredClone(state.initialData),
        history: state.initialData ? [structuredClone(state.initialData)] : [],
        historyIndex: state.initialData ? 0 : null,
        initialData: state.initialData,
        validationErrors: state.initialData
          ? validate(state.initialData, state.parameters)
          : {},
      };
    }
    case GeneDesignActionType.SET_GENE_PARAMETERS: {
      return {
        ...state,
        parameters: action.payload,
        validationErrors: state.currentLocalTemporaryDesign
          ? validate(state.currentLocalTemporaryDesign, action.payload)
          : undefined,
      };
    }
    default:
      return state;
  }
};
