import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card";
import HomologyInfo from "../../../../../screening/components/homologyInfo";
import type { SequenceRow } from "../../../../../screening/types";
import type { PlateFromTRPC } from "../../../build/components/plate/types";

export default function Homology({ plate }: { plate: PlateFromTRPC }) {
  if (plate.stepId) {
    return null;
  }

  const rows: SequenceRow[] = plate.wells.map((well) => ({
    name: well.name,
    sequence: well.sequence,
    well: well.index,
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle>Homology</CardTitle>
      </CardHeader>
      <CardContent>
        <HomologyInfo filename={plate.name} rows={rows} />
      </CardContent>
    </Card>
  );
}
