import type { Direction } from "@console/shared";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@radix-ui/react-tooltip";
import { cn } from "@shared-ui/lib/utils";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import { MoveRight, MoveLeft, Download } from "lucide-react";
import type { SyntheticEvent } from "react";
import { useState, useCallback, useEffect, useMemo } from "react";

import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { SimpleTable } from "../../../components/ui/simple-table";
import { LoadingSpinner } from "../../../components/ui/spinner";
import { useToast } from "../../../components/ui/use-toast";
import { trpc } from "../../../config/trpc";
import { downloadHomologyResults } from "../downloadResults";
import type { SequenceRow } from "../types";

export default function HomologyInfo({
  filename,
  rows,
}: {
  filename?: string;
  rows: SequenceRow[];
}) {
  const [parameters, setParameters] = useState({
    maximumSizeDifferenceInPool: 100,
    minimumComplementarity: 90,
    minimumOverlap: 10,
  });

  const { toast } = useToast();

  const {
    mutate,
    data: homologyInformation,
    isPending,
  } = trpc.screening.getHomologyInformation.useMutation({
    onError(error) {
      toast({
        description: `Error: ${error.message}`,
        title: "Homology",
        variant: "destructive",
      });
    },
  });

  const sequencesString = useMemo(
    () => rows.map((row) => row.sequence).join(","),
    [rows],
  );

  const getHomologyInformation = useCallback(() => {
    const sequences = sequencesString.split(",");
    if (sequences.length === 0) {
      return;
    }
    mutate({
      parameters: {
        maximum_size_difference_in_pool: parameters.maximumSizeDifferenceInPool,
        minimum_complementarity: parameters.minimumComplementarity / 100,
        minimum_overlap: parameters.minimumOverlap,
      },
      sequences,
    });
  }, [mutate, parameters, sequencesString]);

  useEffect(() => {
    getHomologyInformation();
  }, [getHomologyInformation]);

  type WellHomologyInfo = {
    direction: Direction;
    position: number;
    well: string;
  };

  const interSequenceHomology = useMemo(() => {
    if (
      !homologyInformation ||
      !("inter_sequence_homology" in homologyInformation)
    ) {
      return [];
    }
    return Object.entries(homologyInformation?.inter_sequence_homology ?? {})
      .flatMap(([index, homology]) => {
        return Object.entries(homology).flatMap(([nestedIndex, homology]) => {
          return homology.flatMap((entry) => {
            return {
              complementarity: entry.complementarity,
              length: entry.length,
              lhsWell: {
                direction: entry.directions[0],
                position: entry.positions[0],
                well: rows[parseInt(index)].well,
              } as WellHomologyInfo,
              rhsWell: {
                direction: entry.directions[1],
                position: entry.positions[1],
                well: rows[parseInt(nestedIndex)].well,
              } as WellHomologyInfo,
            };
          });
        });
      })
      .sort((a, b) => b.length - a.length);
  }, [rows, homologyInformation]);

  const getWellIllustration = useCallback(
    (wellHomology: WellHomologyInfo, labelPlacement: "top" | "bottom") => {
      const size = 20;
      const padding =
        wellHomology.direction === "forward"
          ? wellHomology.position > 0
            ? "-ml-2"
            : ""
          : wellHomology.position > 0
            ? "ml-2"
            : "";
      const icon =
        wellHomology.direction === "forward" ? (
          <MoveRight className="my-[-0.2rem]" size={size} />
        ) : (
          <MoveLeft className="my-[-0.2rem]" size={size} />
        );

      const row = rows.find((row) => row.well === wellHomology.well);

      const toolTipContent = (
        <div className="flex flex-col items-start">
          <span className="text-sm font-bold">{wellHomology.well}</span>
          <span className="text-sm text-gray-500">
            {wellHomology.direction}
          </span>
          <span className="text-sm">start {wellHomology.position}</span>
          <span className="text-sm">size {row?.sequence.length}</span>
        </div>
      );

      const label =
        wellHomology.well +
        (wellHomology.direction === "reverse complement" ? "*" : "");

      return (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger>
              <div className={`flex flex-col ${padding}`}>
                {labelPlacement === "top" && <span>{label}</span>}
                {icon}
                {labelPlacement === "bottom" && <span>{label}</span>}
              </div>
              <TooltipContent className="flex flex-col">
                {toolTipContent}
              </TooltipContent>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      );
    },
    [rows],
  );

  const homologyColumnHelper =
    createColumnHelper<(typeof interSequenceHomology)[number]>();

  const homologyColumns = useMemo(
    () => [
      homologyColumnHelper.accessor("rhsWell.direction", {
        cell: (info) => {
          const homology = info.row.original;
          return (
            <div className="ml-2 flex flex-col">
              <span>{getWellIllustration(homology.lhsWell, "top")}</span>
              <span>{getWellIllustration(homology.rhsWell, "bottom")}</span>
            </div>
          );
        },
        header: "Homology",
      }),
      homologyColumnHelper.accessor("length", {
        cell: (info) => <span>{info.getValue()} bp</span>,
        header: "Overlap (bp)",
      }),
      homologyColumnHelper.accessor("complementarity", {
        cell: (info) => <span>{(info.getValue() * 100).toFixed(0)}%</span>,
        header: "Complementarity",
      }),
    ],
    [getWellIllustration, homologyColumnHelper],
  );
  const homologyTable = useReactTable({
    columns: homologyColumns,
    data: interSequenceHomology ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const pooling = useMemo(() => {
    if (!homologyInformation || !("pools" in homologyInformation)) {
      return [];
    }
    return (homologyInformation?.pools ?? []).map((pool, index) => {
      return {
        pool: `${index + 1}`,
        wells: pool.map((wellIndex) => rows[wellIndex]),
      };
    });
  }, [rows, homologyInformation]);

  const poolingColumnHelper = createColumnHelper<(typeof pooling)[number]>();

  const poolingColumns = useMemo(
    () => [
      poolingColumnHelper.accessor("pool", {
        header: "Pool",
      }),
      poolingColumnHelper.accessor("wells", {
        cell: (info) => (
          <div className="flex flex-row flex-wrap gap-2">
            {info.getValue().map((well) => (
              <Badge
                className="flex flex-row gap-1"
                key={well.well}
                variant={"outline"}
              >
                <p className="text-sm">{well.well}</p>
                <p className="text-xs text-gray-400">{well.sequence.length}</p>
              </Badge>
            ))}
          </div>
        ),
        header: "Wells",
      }),
    ],
    [poolingColumnHelper],
  );

  const poolingTable = useReactTable({
    columns: poolingColumns,
    data: pooling,
    getCoreRowModel: getCoreRowModel(),
  });

  const parameterInputs = [
    {
      id: "minimumOverlap",
      label: "Minimum overlap (bp)",
      max: undefined,
      min: 0,
      value: parameters.minimumOverlap,
    },
    {
      id: "minimumComplementarity",
      label: "Minimum complementarity (%)",
      max: 100,
      min: 0,
      value: parameters.minimumComplementarity,
    },
    {
      id: "maximumSizeDifferenceInPool",
      label: "Maximum size difference in a pool (bp)",
      max: undefined,
      min: 0,
      value: parameters.maximumSizeDifferenceInPool,
    },
  ];

  const onUpdateParameters = (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      maximumSizeDifferenceInPool: { value: string };
      minimumComplementarity: { value: string };
      minimumOverlap: { value: string };
    };

    const newParameters = {
      maximumSizeDifferenceInPool: parseInt(
        target.maximumSizeDifferenceInPool.value,
      ),
      minimumComplementarity: parseInt(target.minimumComplementarity.value),
      minimumOverlap: parseInt(target.minimumOverlap.value),
    };

    setParameters(newParameters);
  };

  const handleDownloadResults = () => {
    downloadHomologyResults(
      filename ?? "homology.xlsx",
      interSequenceHomology,
      pooling,
      rows,
    );
  };

  return (
    <div
      className={cn(
        "flex flex-col gap-6",
        isPending && "pointer-events-none cursor-wait opacity-50",
      )}
    >
      <div className="flex flex-row items-center justify-between space-x-3">
        <h2 className="flex flex-row items-center gap-2 text-xl font-bold">
          <span>NGS Preparation</span> {isPending && <LoadingSpinner />}
        </h2>
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={handleDownloadResults}
        >
          <span>Results</span>
          <Download />
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-bold">Homology Parameters</h3>
        <form
          className="flex flex-row items-end gap-4"
          onSubmit={onUpdateParameters}
        >
          {parameterInputs.map(({ id, label, value, min, max }) => (
            <div className="grid w-full max-w-sm items-center gap-1.5" key={id}>
              <Label htmlFor={id}>{label}</Label>
              <Input
                defaultValue={value}
                id={id}
                max={max}
                min={min}
                type="number"
              />
            </div>
          ))}
          <Button
            disabled={isPending}
            onClick={getHomologyInformation}
            type="submit"
            variant={"secondary"}
          >
            Update
          </Button>
        </form>
      </div>
      <div className="flex max-w-4xl flex-col space-x-3">
        <h3 className="text-lg font-bold">Pooling</h3>
        <SimpleTable table={poolingTable} />
      </div>
      <div className="flex flex-col space-x-3">
        <h3 className="text-lg font-bold">Homology</h3>
        <span className="text-sm text-gray-500">
          * denotes the reverse complement of the well sequence
        </span>
        <SimpleTable table={homologyTable} />
      </div>
    </div>
  );
}
