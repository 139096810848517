import { forwardRef, useState } from "react";

import { BlocksTopInformation } from "./topInformation";
import { useBlockLayers } from "./useBlockLayers";

import type { GeneDesignConfigurationType } from "../../logic/configuration";
import type { GeneDesignReducerState } from "../../logic/reducer";
import { type GeneDesign } from "../../logic/types";
import { GeneDesignSequenceView } from "../common";

function View(
  {
    data,
    sequence,
    configuration,
    className,
    errors,
  }: {
    className?: string;
    configuration: GeneDesignConfigurationType;
    data: GeneDesign;
    errors?: GeneDesignReducerState["validationErrors"];
    sequence: string;
  },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const startPosition = data.blocks[0].start;
  const endPosition = data.blocks[data.blocks.length - 1]?.end;
  const blockWidth = (endPosition - startPosition) * configuration.nuclWidth;

  const [currentActiveBlock, setActiveBlock] = useState(-1);

  const {
    blockBgColors,
    blockControls,
    overlapBlocks,
    primerBlocks,
    forwardPrimersBlockInfo,
    reversePrimersBlockInfo,
    oligosBgColors,
  } = useBlockLayers({
    activeBlockIndex: currentActiveBlock,
    configuration,
    data,
    errors,
    sequence,
    setActiveBlock,
  });

  return (
    <div className={`px-4 ${className}`}>
      <div className="scrollbar overflow-y-auto" ref={ref}>
        <div className="ml-auto mr-auto" style={{ width: blockWidth }}>
          <div className="relative ml-auto mr-auto w-full whitespace-nowrap">
            {forwardPrimersBlockInfo}
          </div>
          <div className="ml-auto mr-auto flex w-full items-end whitespace-nowrap">
            <BlocksTopInformation
              configuration={configuration}
              data={data}
              errors={errors}
              sequence={sequence}
            />
          </div>
          <div className="relative ml-auto mr-auto h-[52px] w-full whitespace-nowrap">
            <div className="absolute left-0 top-0 h-full w-full">
              {blockBgColors}
              {oligosBgColors}
            </div>
            <div className="absolute left-0 top-0 h-full w-full">
              {primerBlocks}
            </div>
            <div className="absolute left-0 top-0 z-[1] w-full">
              <GeneDesignSequenceView sequence={sequence} />
            </div>
            <div className="absolute left-0 top-0 h-full w-full">
              {overlapBlocks}
            </div>
            <div className="absolute left-0 top-0 h-full w-full">
              {blockControls}
            </div>
          </div>
          <div className="relative ml-auto mr-auto w-full whitespace-nowrap">
            {reversePrimersBlockInfo}
          </div>
        </div>
      </div>
    </div>
  );
}

export const BlocksView = forwardRef(View);
