import { OrderStatus } from "@console/shared";

import { Badge } from "../../../../../components/ui/badge";

export const OrderStatusComponent: Record<OrderStatus, React.ReactNode> = {
  [OrderStatus.Canceled]: (
    <Badge className="bg-gray-500">{OrderStatus.Canceled}</Badge>
  ),
  [OrderStatus.Queued]: (
    <Badge className="bg-yellow-600">{OrderStatus.Queued}</Badge>
  ),
  [OrderStatus.Opportunity]: (
    <Badge className="bg-yellow-400">{OrderStatus.Opportunity}</Badge>
  ),
  [OrderStatus.Completed]: (
    <Badge className="bg-green-600">{OrderStatus.Completed}</Badge>
  ),
  [OrderStatus.InFillNFinish]: (
    <Badge className="bg-purple-600">{OrderStatus.InFillNFinish}</Badge>
  ),
  [OrderStatus.InProduction]: (
    <Badge className="bg-blue-600">{OrderStatus.InProduction}</Badge>
  ),
  [OrderStatus.Shipping]: (
    <Badge className="bg-cyan-700">{OrderStatus.Shipping}</Badge>
  ),
};
