import { OligoStatus } from "@console/shared";
import { Copy, Trash } from "lucide-react";

import type { BacklogOligo } from "./oligo-row";

import { Button } from "../../../../../../components/ui/button";
import useRemoveOligo from "../../hooks/useRemoveOligo";
import useReplicateOligo from "../../hooks/useReplicateOligo";
import useReplicateOligos from "../../hooks/useReplicateOligos";

export const DuplicateOligosButton = ({ ids }: { ids: string[] }) => {
  const replicateOligos = useReplicateOligos();

  return (
    <Button
      onClick={(e) => {
        replicateOligos(ids);
        e.preventDefault();
        e.stopPropagation();
      }}
      variant={"ghost"}
    >
      <Copy />
    </Button>
  );
};

export const DuplicateOligoButton = ({
  id,
  size = "14",
}: {
  id: string;
  size?: string | number | undefined;
}) => {
  const replicateOligo = useReplicateOligo();

  return (
    <Button
      onClick={(e) => {
        replicateOligo(id);
        e.preventDefault();
        e.stopPropagation();
      }}
      size={size === "14" ? "xs" : undefined}
      variant={"ghost"}
    >
      <Copy size={size} />
    </Button>
  );
};

export const RemoveOligoButton = ({ oligo }: { oligo: BacklogOligo }) => {
  const removeOligo = useRemoveOligo(oligo);

  const isDisabled = Boolean(
    oligo.status !== OligoStatus.Queued ||
      oligo.assigment ||
      oligo.replicates <= 1 ||
      !oligo.isReplicate,
  );

  return (
    <Button
      disabled={isDisabled}
      onClick={(e) => {
        removeOligo(oligo.id);
        e.preventDefault();
        e.stopPropagation();
      }}
      size="xs"
      variant={"ghost"}
    >
      <Trash size="14" />
    </Button>
  );
};
