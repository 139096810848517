import {
  assemblyParameters,
  defaultLowerTM,
  defaultUpperTM,
} from "@console/shared";
import { toast } from "@frontend/components/ui/use-toast";
import type { z } from "zod";

import { trpc } from "../../../../../config/trpc";

export enum PriorityMode {
  LENGTH = "length",
  TM = "tm",
}

export const cutGeneParametersSchema = assemblyParameters;

export type CutGeneAssemblyParameters = z.infer<typeof cutGeneParametersSchema>;
export type CutGeneAssemblyParametersv1 = Extract<
  CutGeneAssemblyParameters,
  { version: "v1" }
>;
export type CutGeneAssemblyParametersv2 = Extract<
  CutGeneAssemblyParameters,
  { version: "v2" }
>;

export const DefaultAssemblyParametersv1: CutGeneAssemblyParametersv1 = {
  advanced: {
    add_stubs: null,
    n_2_design: false,
    nb_primers: 1,
    primer_first: false,
    smart_block_design: false,
    smart_oligo_design: false,
    use_error_correction: false,
  },
  lower_tm_threshold: defaultLowerTM,
  name: "",
  oligo_size: 30,
  one_block_design: false,
  overlap_subblocks: 50,
  priority: PriorityMode.TM,
  subblock_size: 300,
  upper_tm_threshold: defaultUpperTM,
  version: "v1",
};

export const DefaultAssemblyParametersv2: CutGeneAssemblyParametersv2 = {
  add_stubs: false,
  assembly_primers: {
    max_size: 30,
    max_tm: 72,
    min_size: 20,
    min_tm: 57,
    optimal_size: 25,
    optimal_tm: 65,
  },
  delta_tm: 5,
  delta_tm_primers: 2,
  error_correction_primers: {
    max_size: 25,
    max_tm: 60,
    min_size: 18,
    min_tm: 50,
    optimal_size: 20,
    optimal_tm: 55,
  },
  lower_tm_threshold: defaultLowerTM,
  name: "",
  nb_primers: 1,
  oligo_size: 30,
  one_block_design: false,
  overlap_subblocks: 50,
  primer_first: false,
  primer_first_parameters: {
    max_block_overlap_size: 50,
    max_block_size: 400,
    min_block_overlap_size: 40,
    min_block_size: 200,
  },
  reversed_oligos: false,
  subblock_size: 300,
  upper_tm_threshold: defaultUpperTM,
  use_error_correction: true,
  version: "v2",
};

export const useCreateGeneDesign = (onSuccess?: (geneId: string) => void) => {
  const utils = trpc.useUtils();
  const { mutate: newDesignMutation, isPending } =
    trpc.assay.steps.gene.design.designGene.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Design failed",
          variant: "warning",
        });
      },
      onSuccess(designId) {
        utils.assay.steps.gene.listDesigns.invalidate();
        toast({
          description: "The oligos have been designed",
          title: "Design successful",
          variant: "success",
        });

        onSuccess?.(designId);
      },
    });

  const createGeneDesign = (
    geneId: string,
    parameters: CutGeneAssemblyParameters,
  ) => {
    if (geneId === undefined) {
      return;
    }
    newDesignMutation({ ...parameters, geneId });
  };

  return { createGeneDesign, isPending };
};
