import type {
  GeneDesign,
  GeneDesignBlock,
  GeneDesignOligo,
  OligoDirectionType,
} from "./types";

// TODO: combine all logic to controller
function getLeftBlockOverlapsPositions(
  blocks: GeneDesign["blocks"],
  blockIndex: number,
): {
  end: number;
  start: number;
} {
  const validBlockIndex = Math.max(0, Math.min(blockIndex, blocks.length - 1));

  return {
    end: blocks[validBlockIndex - 1]?.end ?? blocks[validBlockIndex].start,
    start: blocks[validBlockIndex].start,
  };
}

function getRightBlockOverlapsPositions(
  blocks: GeneDesign["blocks"],
  blockIndex: number,
): {
  end: number;
  start: number;
} {
  const validBlockIndex = Math.max(0, Math.min(blockIndex, blocks.length - 1));

  return {
    end: blocks[validBlockIndex].end,
    start: blocks[validBlockIndex + 1]?.start ?? blocks[validBlockIndex].end,
  };
}

export function getBlockOverlapsPositions(
  blocks: GeneDesign["blocks"],
  blockIndex: number,
): {
  leftOverlap: {
    end: number;
    start: number;
  };
  prevOverlap: {
    end: number;
    start: number;
  };
  rightOverlap: {
    end: number;
    start: number;
  };
} {
  const validBlockIndex = Math.max(0, Math.min(blockIndex, blocks.length - 1));

  return {
    leftOverlap: getLeftBlockOverlapsPositions(blocks, validBlockIndex),
    prevOverlap: getLeftBlockOverlapsPositions(blocks, validBlockIndex - 1),
    rightOverlap: getRightBlockOverlapsPositions(blocks, validBlockIndex),
  };
}

export function isPositionInRange(
  pos: number,
  { pos1, pos2 }: { pos1: number; pos2: number },
): boolean {
  const min = Math.min(pos1, pos2);
  const max = Math.max(pos1, pos2);
  return pos >= min && pos <= max;
}

export function getValidStartBlockPosition(
  blocks: GeneDesignBlock[],
  blockIndex: number,
  newBlockPos: number,
  configuration = {
    block: {
      minWithoutOverlaps: 1,
    },
    blockOverlap: {
      min: 1,
    },
  },
): number {
  const block = blocks[blockIndex];
  const currentPosition = block.start;

  if (currentPosition === 0) {
    return currentPosition;
  }

  const direction = currentPosition < newBlockPos ? "reduce" : "increase";

  const overlaps = getBlockOverlapsPositions(blocks, blockIndex);

  if (direction === "increase") {
    // move start block position to left
    const availablePos = overlaps.prevOverlap.end;
    const validPos = availablePos + configuration.block.minWithoutOverlaps;

    return Math.max(validPos, newBlockPos);
  } else if (direction === "reduce") {
    // move start block position to right
    const availablePos = overlaps.leftOverlap.end;

    const validPos = availablePos - configuration.blockOverlap.min;

    return Math.min(validPos, newBlockPos);
  }

  return newBlockPos;
}

export function getValidEndBlockPosition(
  blocks: GeneDesignBlock[],
  blockIndex: number,
  newBlockPos: number,
  configuration = {
    block: {
      minWithoutOverlaps: 1,
    },
    blockOverlap: {
      min: 1,
    },
  },
): number {
  const block = blocks[blockIndex];
  const currentPosition = block.end;

  if (currentPosition === blocks[blocks.length - 1].end) {
    return currentPosition;
  }

  const direction = currentPosition > newBlockPos ? "reduce" : "increase";

  if (direction === "increase") {
    // move end block position to right
    const nextBlock = blocks[blockIndex + 1];
    if (nextBlock) {
      const availablePos = blocks[blockIndex + 2]?.start ?? nextBlock.end;
      const validPos = availablePos - configuration.block.minWithoutOverlaps;
      return Math.min(validPos, newBlockPos);
    }
  } else if (direction === "reduce") {
    // move end block position to left
    const availablePos = blocks[blockIndex + 1]?.start ?? block.start;
    const validPos = availablePos + configuration.blockOverlap.min;
    return Math.max(validPos, newBlockPos);
  }

  return currentPosition;
  // return newBlockPos;
}

export function getValidOligoPosition(
  block: GeneDesignBlock,
  currentPosition: number,
  newOligoPos: number,
  direction: OligoDirectionType,
  configuration = {
    oligo: {
      min: 1,
    },
  },
): number {
  if (direction === "forward") {
    if (currentPosition === block.start) {
      return block.start;
    }
  } else if (direction === "reverse") {
    if (currentPosition === block.end) {
      return block.end;
    }
  }

  const minPositions = [block.start] as number[];
  const maxPositions = [block.end] as number[];
  const minOligoSize = configuration.oligo.min;

  // TODO: validate overlaps?

  // validate oligo length
  block.oligos.forEach((oligo: GeneDesignOligo) => {
    // if current oligo is the same direction
    if (oligo.direction === direction) {
      // if new position is start pos
      if (oligo.start === currentPosition) {
        if (currentPosition < newOligoPos) {
          // if current position less than new position (move start position to right)
          maxPositions.push(oligo.end - minOligoSize);
        } else if (currentPosition > newOligoPos) {
          // if current position greater than new position (move start position to left)
          // currently do nothing
        }
      } else if (oligo.end === currentPosition) {
        // if new position end pos
        if (currentPosition > newOligoPos) {
          // if current position greater than new position (move end position to left)
          minPositions.push(oligo.start + minOligoSize);
        } else if (currentPosition < newOligoPos) {
          // if current position less than new position (move end position to right)
          // currently do nothing
        }
      }
    }
  });

  return Math.min(...maxPositions, Math.max(...minPositions, newOligoPos));
}

export function updateOligos({
  block,
  currentPosition,
  newPosition,
  location = "start",
  oligoIndex = -1,
}: {
  block: GeneDesign["blocks"][0];
  currentPosition: number;
  location?: "start" | "end";
  newPosition: number;
  oligoIndex?: number;
}): GeneDesign["blocks"][0] {
  const currentOligo = oligoIndex >= 0 ? block.oligos[oligoIndex] : undefined;
  const pos = currentOligo?.[location] ?? currentPosition;
  const direction = currentOligo?.direction;
  const validNewPos = direction
    ? getValidOligoPosition(block, pos, newPosition, direction)
    : newPosition;

  block.oligos.forEach((oligo) => {
    if (direction && direction !== oligo.direction) {
      // skip oligo if it's not the same direction - for oligos view
      return;
    }

    if (
      isPositionInRange(oligo.start, {
        pos1: pos,
        pos2: validNewPos,
      })
    ) {
      oligo.start = validNewPos;
    }
    if (
      isPositionInRange(oligo.end, {
        pos1: pos,
        pos2: validNewPos,
      })
    ) {
      oligo.end = validNewPos;
    }
  });

  // block primers position
  if (block.primers) {
    const fvPrimerLength =
      block.primers.forward.end - block.primers.forward.start;
    block.primers.forward.start = block.start;
    block.primers.forward.end = block.start + fvPrimerLength;

    const rvPrimerLength =
      block.primers.reverse.end - block.primers.reverse.start;
    block.primers.reverse.end = block.end;
    block.primers.reverse.start = block.end - rvPrimerLength;
  }

  if (block.start !== block.end) {
    block.oligos = block.oligos.filter((o) => o.start !== o.end); // remove oligos with 0 length
  }

  return {
    ...block,
  };
}

export function updateBlock({
  blocks,
  blockIndex,
  newPosition,
  location,
}: {
  blockIndex: number;
  blocks: GeneDesign["blocks"];
  location: "start" | "end";
  newPosition: number;
}): GeneDesign["blocks"][0] {
  const block = blocks[blockIndex];
  const currentPosition = block[location];
  const newBlockPos = newPosition;

  if (location === "start") {
    block.start = getValidStartBlockPosition(blocks, blockIndex, newBlockPos);
  } else if (location === "end") {
    block.end = getValidEndBlockPosition(blocks, blockIndex, newBlockPos);
  }

  const updatedBlock = updateOligos({
    block,
    currentPosition,
    newPosition: block[location],
  });

  return updatedBlock;
}

export function calculateMeltingTemperatureAndGC(
  sequence: string,
  start: number,
  end: number,
): {
  gc: number;
  tm: number;
} {
  // Tm= 64.9 +41*(gG+cC-16.4)/length
  // GC% = (gG+cC)/length

  let c = 0;
  let g = 0;
  for (let i = start; i < end; i++) {
    if (sequence[i] === "C") {
      c++;
    } else if (sequence[i] === "G") {
      g++;
    }
  }

  const length = end - start;
  const gc = ((g + c) / length) * 100;
  const temperature = 64.9 + (41 * (g + c - 16.4)) / length;

  return {
    gc: Math.round(gc),
    tm: Math.round(temperature * 10) / 10,
  };
}

export function getValidPrimerPosition({
  blockEnd,
  blockStart,
  location,
  primerType,
  newPosition,
  configuration = {
    minSize: 1,
  },
}: {
  blockEnd: number;
  blockStart: number;
  configuration?: {
    minSize: number;
  };
  location: "start" | "end";
  newPosition: number;
  primerType: "forward" | "reverse";
}) {
  const { minSize } = configuration;

  if (location === "start") {
    if (primerType === "forward") {
      return blockStart;
    }

    return Math.max(blockStart, Math.min(newPosition, blockEnd - minSize));
  }

  if (location === "end") {
    if (primerType === "reverse") {
      return blockEnd;
    }

    return Math.max(blockStart + minSize, Math.min(newPosition, blockEnd));
  }
}
