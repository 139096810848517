import { OrganizationRoutes } from "@frontend/routes/organization/organization-routes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Download } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../../components/ui/button";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import { TableActions } from "../../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { useToast } from "../../../../../components/ui/use-toast";
import type { AppRouter } from "../../../../../config/trpc";
import { trpc } from "../../../../../config/trpc";
import { downloadBlob } from "../../../../../utils/useDownload";
import { CheckIfTrueElseCross } from "../../../../admin/organizations/components/ui";

type DesignTRPC = inferProcedureOutput<
  AppRouter["assay"]["steps"]["gene"]["listDesigns"]
>[0];

export const DeleteDesignButton = ({
  designId,
  geneId,
  onSuccess,
}: {
  designId: string;
  geneId: string;
  onSuccess?: () => void;
}) => {
  const { toast } = useToast();

  const utils = trpc.useUtils();
  const { mutate: deleteDesign } =
    trpc.assay.steps.gene.design.removeDesign.useMutation({
      onSuccess() {
        toast({
          description: "Design deleted",
          title: "Success",
          variant: "success",
        });
        utils.construct.get.invalidate();
        utils.assay.steps.gene.listDesigns.invalidate();

        onSuccess?.();
      },
    });

  const handleClick = () => {
    deleteDesign({
      designId: designId,
      geneId: geneId,
    });
  };

  return (
    <Button onClick={handleClick} type="button" variant={"outline"}>
      Delete
    </Button>
  );
};

export const DownloadDesignButton = ({
  designId,
  geneId,
  designName,
}: {
  designId: string;
  designName: string;
  geneId: string;
}) => {
  const { toast } = useToast();

  const { mutate: downloadDesign } =
    trpc.assay.steps.gene.design.downloadDesign.useMutation({
      onSuccess(data) {
        toast({
          description: "Design downloaded",
          title: "Success",
          variant: "success",
        });
        const blob = new Blob([data], { type: "text/tab-separated-values" });
        downloadBlob(blob, `${designName}.tsv`);
      },
    });

  const handleClick = () => {
    downloadDesign({
      designId: designId,
      geneId: geneId,
    });
  };

  return (
    <Button
      className="flex flex-row items-center space-x-1"
      onClick={handleClick}
      type="button"
      variant={"outline"}
    >
      <span>Export</span>
      <Download />
    </Button>
  );
};

export const DownloadDesignParametersButton = ({
  geneId,
  designName,
  designId,
}: {
  designId: string;
  designName: string;
  geneId: string;
}) => {
  const { toast } = useToast();

  const { mutate: downloadDesignParameters } =
    trpc.assay.steps.gene.design.downloadDesignParameters.useMutation({
      onSuccess(data) {
        toast({
          description: "Parameters downloaded",
          title: "Success",
          variant: "success",
        });
        const blob = new Blob([data], { type: "application/json" });
        downloadBlob(blob, `${designName}_prms.json`);
      },
    });

  const handleClick = () => {
    downloadDesignParameters({
      designId: designId,
      geneId: geneId,
    });
  };

  return (
    <Button
      className="flex flex-row items-center space-x-1"
      onClick={handleClick}
      type="button"
      variant={"outline"}
    >
      <span>Parameters</span>
      <Download />
    </Button>
  );
};

const baseColumns: ColumnDef<DesignTRPC>[] = [
  {
    accessorFn: (row) => row.name,
    cell: (info) => info.getValue(),
    header: "Name",
  },
  {
    accessorFn: (row) => row.createdAt,
    cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
    header: "Created at",
  },
  {
    accessorKey: "createdBy",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "createdBy",
    meta: {
      title: "Creator",
    },
  },
  {
    accessorKey: "metrics.numberOfBlocks",
    cell: (info) => info.getValue(),
    header: "Number of blocks",
  },
  {
    accessorKey: "metrics.numberOfOligos",
    cell: (info) => info.getValue(),
    header: "Number of oligos",
  },
];

const designColumns: ColumnDef<DesignTRPC>[] = [
  {
    accessorKey: "parameters.subblock_size",
    cell: (info) => info.getValue(),
    header: "Subblock size",
  },
  {
    accessorKey: "parameters.overlap_subblocks",
    cell: (info) => info.getValue(),
    header: "Subblock overlap",
  },
  {
    accessorKey: "parameters.oligo_size",
    cell: (info) => info.getValue(),
    header: "Oligo size",
  },
  {
    cell: ({ row }) => (
      <span>
        {row.original.parameters.lower_tm_threshold}-
        {row.original.parameters.upper_tm_threshold}
      </span>
    ),
    header: "TM",
  },
  {
    accessorKey: "parameters.priority",
    cell: (info) => info.getValue(),
    header: "Priority",
  },
  {
    accessorKey: "parameters.advanced.n_2_design",
    cell: (info) => <CheckIfTrueElseCross value={info.getValue() as boolean} />,
    header: "n-2 design",
  },
  {
    accessorKey: "parameters.advanced.add_stubs",
    cell: (info) => <CheckIfTrueElseCross value={info.getValue() as boolean} />,
    header: "Add stubs",
  },
  {
    accessorKey: "parameters.advanced.use_error_correction",
    cell: (info) => <CheckIfTrueElseCross value={info.getValue() as boolean} />,
    header: "Use error correction",
  },
];

const getActionsColumn = (
  geneDesignEnabled: boolean,
): ColumnDef<DesignTRPC> => ({
  cell: ({ row }) => {
    const design = row.original;
    return (
      <TableActions
        items={[
          {
            children: (
              <DeleteDesignButton designId={design.id} geneId={design.geneId} />
            ),
            id: "Delete",
          },
          ...(geneDesignEnabled
            ? [
                {
                  children: (
                    <DownloadDesignButton
                      designId={design.id}
                      designName={design.name}
                      geneId={design.geneId}
                    />
                  ),
                  id: "Download",
                },
                {
                  children: (
                    <DownloadDesignParametersButton
                      designId={design.id}
                      designName={design.name}
                      geneId={design.geneId}
                    />
                  ),
                  id: "Download parameters",
                },
              ]
            : []),
        ]}
      />
    );
  },
  enableHiding: false,
  id: "actions",
});

export default function OldDesigns({
  constructId,
  geneDesignEnabled,
  geneId,
}: {
  constructId: string;
  geneDesignEnabled: boolean;
  geneId: string;
}) {
  const navigate = useNavigate();
  const { data: designs, isPending: isDesignListPending } =
    trpc.assay.steps.gene.listDesigns.useQuery({
      geneId,
    });

  const columns = [
    ...baseColumns,
    ...(geneDesignEnabled ? designColumns : []),
    getActionsColumn(geneDesignEnabled),
  ];

  return (
    <DataTable
      columns={columns}
      data={designs ?? []}
      disableQueryParams
      enableRowSelection={false}
      loading={isDesignListPending}
      onRowClick={(row) => {
        if (!geneDesignEnabled) {
          return;
        }
        const designId = row.original.id;
        if (!constructId) {
          return;
        }
        navigate(
          OrganizationRoutes.CONSTRUCT_GENE_DESIGN_EDIT.replace(
            ":constructId",
            constructId,
          ).replace(":designId", designId),
        );
      }}
    />
  );
}
