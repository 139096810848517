import type { RequestFormType } from "@console/shared";
import {
  requestFormSchema,
  sequenceColumnId,
  SequenceShippingFormat,
} from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { LogOut, PlusSquareIcon, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import DownloadExcelAndVerify from "./download-excel";
import RequestInstructions from "./request-instructions";
import { ShippingFormatComponent } from "./shipping-format";
import Templates from "./templates";
import { useGetFormSequencesColumns } from "./useGetFormSequencesColumns";
import useScreenCustomerSequences from "./useScreenCustomerSequences";

import { Button } from "../../components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardContent,
  Card,
  CardDescription,
} from "../../components/ui/card";
import { DataTable } from "../../components/ui/data-table/data-table";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Label } from "../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { addGtmHeadScript, BodyScript } from "../../config/googleTagManager";
import { cn } from "../../lib/utils";
import UploadSequences from "../organization/pages/construct/components/upload-sequences";

export default function RequestForm() {
  const [defaultFormat, setDefaultFormat] = useState(
    SequenceShippingFormat.Plate,
  );
  const form = useForm<RequestFormType>({
    defaultValues: {
      geneFragments: [],
      oligos: [],
    },
    resolver: zodResolver(requestFormSchema, undefined, { mode: "async" }),
  });

  useEffect(() => {
    addGtmHeadScript();
  }, []);

  const {
    handleCheck,
    isProcessingRequest,
    isVerified,
    setIsVerified,
    progress,
  } = useScreenCustomerSequences(form);

  const { append: appendOligo, update: updateOligo } = useFieldArray({
    control: form.control,
    name: "oligos",
  });
  // const {
  //   append: appendGeneFragment,
  //   update: updateGeneFragment,
  //   fields: geneFragments,
  // } = useFieldArray({
  //   control: form.control,
  //   name: "geneFragments",
  // });

  const oligoColumns = useGetFormSequencesColumns(false);
  // const geneFragmentColumns = useGetFormSequencesColumns(true);
  const topLevelErrors = (form.formState.errors as any)?.[""]?.message;

  // function downloadGenesCsv() {
  //   const csvString = "Name,Sequence,Format\n".concat(
  //     geneFragments
  //       .map((gene) => {
  //         return `${gene.name},${gene.sequence},${gene.format}`;
  //       })
  //       .join("\n"),
  //   );
  //   const csvBlob = new Blob([csvString], { type: "text/csv" });
  //   downloadBlob(csvBlob, "genes.csv");
  // }

  return (
    <div
      className={
        "flex h-screen w-full flex-col items-center space-y-8 overflow-scroll px-10 py-8"
      }
    >
      <BodyScript />
      <Card className={"w-[60vw]"}>
        <Form {...form}>
          <form className="space-y-4" onSubmit={form.handleSubmit(handleCheck)}>
            <CardHeader className="flex flex-row justify-between space-x-2">
              <div>
                <CardTitle>Request form</CardTitle>
                <CardDescription>
                  Verify your sequences and download your request file in excel
                  format
                </CardDescription>
              </div>
              <Link
                className="flex flex-row space-x-1"
                target="_blank"
                to="https://info.dnascript.com/ssdna-platform#lead-gen-form"
              >
                <span>Go to order form</span>
                <LogOut />
              </Link>
            </CardHeader>

            <FormProvider {...form}>
              {topLevelErrors && (
                <Label className="text-destructive px-6">
                  {topLevelErrors}
                </Label>
              )}
              <CardContent className="flex flex-col space-y-4 [&_td]:p-1">
                <RequestInstructions />
                <div className="flex flex-row items-start justify-between">
                  <div className="flex flex-col space-y-1">
                    <Label htmlFor="defaultFormat">
                      Default shipping format
                    </Label>
                    <Select
                      onValueChange={(value) => {
                        setDefaultFormat(value as SequenceShippingFormat);
                      }}
                      value={defaultFormat}
                    >
                      <SelectTrigger
                        className="h-8 w-[120px]"
                        id="defaultFormat"
                      >
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent side="top">
                        {Object.values(SequenceShippingFormat).map(
                          (shippingFormat) => (
                            <SelectItem
                              key={shippingFormat}
                              value={`${shippingFormat}`}
                            >
                              {ShippingFormatComponent[shippingFormat]}
                            </SelectItem>
                          ),
                        )}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                {/* <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="geneFragments"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Gene fragments</FormLabel>
                        <FormControl>
                          <DataTable
                            columns={geneFragmentColumns}
                            data={field.value}
                            disableQueryParams
                            meta={{
                              updateData: (
                                rowIndex: number,
                                columnId: string,
                                value: any,
                              ) => {
                                const isSequenceColumn =
                                  columnId === sequenceColumnId;
                                updateGeneFragment(rowIndex, {
                                  ...field.value[rowIndex],
                                  [columnId]: isSequenceColumn
                                    ? (value as string).toUpperCase()
                                    : value,
                                });
                              },
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex flex-row items-center space-x-2">
                    <Button
                      className="space-x-2"
                      onClick={() => form.setValue("geneFragments", [])}
                      type="button"
                      variant={"outline"}
                    >
                      <span>Delete all</span>
                      <Trash />
                    </Button>
                    <Button
                      className="space-x-2"
                      disabled={geneFragments.length === 0}
                      onClick={downloadGenesCsv}
                      type="button"
                      variant={"outline"}
                    >
                      <span>Download csv</span>
                      <Download />
                    </Button>
                    <Button
                      className="space-x-2"
                      onClick={() =>
                        appendGeneFragment({
                          format: defaultFormat,
                          name: "",
                          sequence: "",
                        })
                      }
                      type="button"
                      variant={"outline"}
                    >
                      <span>New gene fragment</span>
                      <PlusSquareIcon />
                    </Button>
                    <UploadSequences
                      onChange={(parsedData) => {
                        parsedData.forEach((data) => {
                          appendGeneFragment({
                            format: data.Format ?? defaultFormat,
                            name: data.Name,
                            sequence: data.Sequence,
                          });
                        });
                      }}
                    />
                    <Templates
                      templates={[
                        {
                          downloadName: "template.fasta",
                          file: "/fastaTemplateGene.fasta",
                          label: "Fasta",
                        },
                        {
                          downloadName: "template.csv",
                          file: "/csvTemplateGene.csv",
                          label: "CSV",
                        },
                        {
                          downloadName: "template.xlsx",
                          file: "/excelTemplateGene.xlsx",
                          label: "XLS",
                        },
                      ]}
                    />
                  </div>
                </div>
                <Separator /> */}
                <div className="space-y-2">
                  <div
                    className={cn(
                      "flex flex-col items-center space-y-1",
                      "lg:flex-row lg:space-x-2",
                    )}
                  >
                    <Button
                      className="space-x-2"
                      onClick={() => form.setValue("oligos", [])}
                      type="button"
                      variant={"outline"}
                    >
                      <span>Delete all</span>
                      <Trash />
                    </Button>
                    <Button
                      className="space-x-2"
                      onClick={() =>
                        appendOligo({
                          format: defaultFormat,
                          name: "",
                          sequence: "",
                        })
                      }
                      type="button"
                      variant={"outline"}
                    >
                      <span>New oligo</span>
                      <PlusSquareIcon />
                    </Button>
                    <UploadSequences
                      onChange={(parsedData) => {
                        parsedData.forEach((data) => {
                          appendOligo({
                            format: data.Format ?? defaultFormat,
                            name: data.Name,
                            sequence: data.Sequence,
                          });
                        });
                      }}
                    />
                    <Templates
                      templates={[
                        {
                          downloadName: "template.fasta",
                          file: "/fastaTemplate.fasta",
                          label: "Fasta",
                        },
                        {
                          downloadName: "template.csv",
                          file: "/csvTemplateNoWell.csv",
                          label: "CSV",
                        },
                        {
                          downloadName: "template.xlsx",
                          file: "/excelTemplateNoWell.xlsx",
                          label: "XLS",
                        },
                      ]}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="oligos"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Oligos</FormLabel>
                        <FormControl>
                          <DataTable
                            columns={oligoColumns}
                            data={field.value}
                            disableQueryParams
                            meta={{
                              updateData: (
                                rowIndex: number,
                                columnId: string,
                                value: any,
                              ) => {
                                // Skip page index reset until after next rerender
                                const isSequenceColumn =
                                  columnId === sequenceColumnId;
                                updateOligo(rowIndex, {
                                  ...field.value[rowIndex],
                                  [columnId]: isSequenceColumn
                                    ? (value as string).toUpperCase()
                                    : value,
                                });
                              },
                            }}
                            useBorders={false}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-col">
                  {isProcessingRequest && (
                    <p className="m-2 flex justify-end">
                      This may take several minutes. Please do not refresh this
                      page.
                    </p>
                  )}
                  <DownloadExcelAndVerify
                    isRemoteChecking={isProcessingRequest}
                    isVerified={isVerified}
                    progress={progress}
                    setIsVerified={setIsVerified}
                  />
                </div>
              </CardContent>
            </FormProvider>
          </form>
        </Form>
      </Card>
    </div>
  );
}
