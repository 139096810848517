import { SaveIcon } from "lucide-react";
import { useState } from "react";

import { Button } from "../../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../components/ui/dialog";
import { Input } from "../../../../../../components/ui/input";
import { Label } from "../../../../../../components/ui/label";
import { useToast } from "../../../../../../components/ui/use-toast";
import { trpc } from "../../../../../../config/trpc";

export default function MarkPlateAsPrinting({ id }: { id: string }) {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const [open, setOpen] = useState(false);
  const [prototypeName, setPrototypeName] = useState<string>("");

  const { mutate: markAsPrinting, isPending } =
    trpc.order.productionPlates.markAsPrinting.useMutation({
      onSuccess() {
        toast({
          description: "Plate marked as printing",
          title: "Success",
          variant: "success",
        });
        setOpen(false);
        utils.plate.get.invalidate(id);
      },
    });

  const submitMarkAsPrinting = () => {
    markAsPrinting({ plateId: id, prototypeName });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          className="flex flex-row items-center space-x-1"
          variant={"secondary"}
        >
          <span>Mark as printing</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Assign the plate to a prototype instrument</DialogTitle>
          <DialogDescription>Mark the plate as printing</DialogDescription>
        </DialogHeader>
        <Label htmlFor="prototypeName">Prototype name</Label>
        <Input
          id="prototypeName"
          onChange={(e) => setPrototypeName(e.target.value)}
          value={prototypeName}
        />
        <div className="flex flex-row justify-end space-x-2">
          <Button
            disabled={!prototypeName}
            isLoading={isPending}
            onClick={submitMarkAsPrinting}
          >
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
