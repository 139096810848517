import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../../components/ui/form";
import { Input } from "../../../../../../../components/ui/input";
import type { GeneDesignParameters } from "../logic/types";

export function ValidationParameters({
  parameters,
  onChange,
}: {
  onChange: (parameters: GeneDesignParameters) => void;
  parameters: GeneDesignParameters;
}) {
  const form = useForm({
    defaultValues: parameters,
    mode: "onChange",
  });

  return (
    <div className="px-2 pb-2">
      <Form {...form}>
        <form
          onChange={form.handleSubmit(onChange)}
          onSubmit={form.handleSubmit(onChange)}
        >
          <div className="grid grid-cols-6 gap-4">
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="primers.size.min"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Primer min size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Primer min size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="primers.size.max"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Primer max size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Primer max size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="blocks.primers.size.min"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Block primer min size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Block primer min size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="blocks.primers.size.max"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Block primer max size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Block primer max size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="blocks.size.min"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Block min size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Block min size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="blocks.size.max"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Block max size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Block max size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="blocks.overlap.min"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Block overlap min size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Block overlap min size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="blocks.overlap.max"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Block overlap max size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Block overlap max size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="blocks.oligos.size.min"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Oligo min size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Oligo min size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="blocks.oligos.size.max"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Oligo max size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Oligo max size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="blocks.oligos.overlap.min"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Oligo overlap min size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Oligo overlap min size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="blocks.oligos.overlap.max"
                render={({ field: { onChange, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Oligo overlap max size (bp)</FormLabel>
                    <FormControl>
                      <Input
                        {...rest}
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        placeholder="Oligo overlap max size"
                        type="number"
                      />
                    </FormControl>
                    <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
