import { useEffect } from "react";

import useNewPlateContext from "./useNewPlateContext";

function highlightRow(row: Element) {
  const rows = document.querySelectorAll(".selectable");
  // Remove highlight from all rows
  rows.forEach((r) => r.classList.remove("selected"));
  // Add highlight to the focused row
  row.classList.add("selected");
}

export default function useOligosKeyboardShortcuts() {
  const { oligos, oligoRepeats, tableRef } = useNewPlateContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        const rows = document.querySelectorAll(".selectable");
        if (rows.length === 0) return;
        const firstRow = rows[0];
        if ("focus" in firstRow && typeof firstRow.focus === "function") {
          firstRow.focus();
          highlightRow(firstRow);
        }
        return;
      }
      const focusedRow = document.activeElement;
      if (!focusedRow || !focusedRow.classList.contains("selectable")) return;
      const focusedOligoId = focusedRow.id;
      if (event.code === "Space") {
        tableRef.current?.setRowSelection((prev) => {
          const newSelection = {
            ...(typeof prev === "boolean" ? {} : { ...prev }),
          };
          newSelection[focusedOligoId] = !(
            newSelection[focusedOligoId] ?? false
          );
          return newSelection;
        });
        event.preventDefault();
        return;
      }
      let nextRow = null;
      if (event.key === "ArrowDown") {
        nextRow = focusedRow.nextElementSibling;
      } else if (event.key === "ArrowUp") {
        nextRow = focusedRow.previousElementSibling;
      }
      if (nextRow && nextRow.classList.contains("selectable")) {
        if ("blur" in focusedRow && typeof focusedRow.blur === "function") {
          focusedRow.blur();
        }
        if ("focus" in nextRow && typeof nextRow.focus === "function") {
          nextRow.focus();
        }
        highlightRow(nextRow);
        event.preventDefault(); // Prevent default scrolling behavior
      }

      if (event.code === "ArrowRight" || event.code === "ArrowLeft") {
        const expand = event.code === "ArrowRight";
        const hasReplicates = oligoRepeats.has(focusedOligoId);
        if (hasReplicates) {
          tableRef.current?.setExpanded((prev) => {
            const newExpanded = {
              ...(typeof prev === "boolean" ? {} : { ...prev }),
            };
            newExpanded[focusedOligoId] = expand;
            return newExpanded;
          });
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [oligoRepeats, oligos, tableRef]);
}
