import Excel from "exceljs";

import type { SequenceRow, WellHomologyInfo } from "./types";

import { downloadBufferExcel } from "../../utils/useDownload";

export async function downloadHomologyResults(
  filename: string,
  interSequenceHomology: {
    complementarity: number;
    length: number;
    lhsWell: WellHomologyInfo;
    rhsWell: WellHomologyInfo;
  }[],
  pooling: {
    pool: string;
    wells: SequenceRow[];
  }[],
  rows: SequenceRow[],
) {
  const workbook = new Excel.Workbook();
  const ngsPoolingWorksheet = workbook.addWorksheet("NGS Pooling");

  ngsPoolingWorksheet.columns = [
    { header: "Well", key: "well", width: 10 },
    { header: "Sequence name", key: "sequenceName", width: 40 },
    { header: "Sequence length (mers)", key: "sequenceLength", width: 20 },
    { header: "Destination pool (well)", key: "pool", width: 40 },
  ];

  const getPoolWellId = (row: SequenceRow) => {
    const pool =
      pooling.find((pool) => pool.wells.some((well) => well.well === row.well))
        ?.pool ?? "";
    const wellId = `${String.fromCharCode(((+pool - 1) % 8) + "A".charCodeAt(0))}${Math.floor((+pool - 1) / 8) + 1}`;
    return wellId;
  };

  for (const row of rows) {
    ngsPoolingWorksheet.addRow({
      pool: getPoolWellId(row),
      sequenceLength: row.sequence.length,
      sequenceName: row.name,
      well: row.well,
    });
  }

  const homologyWorksheet = workbook.addWorksheet("Homology");
  homologyWorksheet.columns = [
    { header: "Well", key: "well1", width: 10 },
    { header: "Sequence name", key: "sequenceName1", width: 20 },
    { header: "Sequence length (mers)", key: "sequenceLength1", width: 15 },
    { header: "Direction", key: "direction1", width: 20 },
    { header: "Start position (0-based)", key: "overlapStart1", width: 15 },
    { header: "Well", key: "well2", width: 10 },
    { header: "Sequence name", key: "sequenceName2", width: 20 },
    { header: "Sequence length (mers)", key: "sequenceLength2", width: 15 },
    { header: "Direction", key: "direction2", width: 20 },
    { header: "Start position (0-based)", key: "overlapStart2", width: 15 },
    { header: "Overlap (bp)", key: "overlap", width: 20 },
    { header: "Complementarity (%)", key: "complementarity", width: 20 },
  ];

  for (const homology of interSequenceHomology) {
    homologyWorksheet.addRow({
      complementarity: +(homology.complementarity * 100).toFixed(0),
      direction1: homology.lhsWell.direction,
      direction2: homology.rhsWell.direction,
      overlap: homology.length,
      overlapStart1: homology.lhsWell.position,
      overlapStart2: homology.rhsWell.position,
      sequenceLength1: rows.find((row) => row.well === homology.lhsWell.well)
        ?.sequence.length,
      sequenceLength2: rows.find((row) => row.well === homology.rhsWell.well)
        ?.sequence.length,
      sequenceName1: rows.find((row) => row.well === homology.lhsWell.well)
        ?.name,
      sequenceName2: rows.find((row) => row.well === homology.rhsWell.well)
        ?.name,
      well1: homology.lhsWell.well,
      well2: homology.rhsWell.well,
    });
  }

  const buffer = (await workbook.xlsx.writeBuffer()) as unknown as Buffer;

  const filenameNgsPrep = `${filename?.split(".xlsx")[0]}_ngs_prep.xlsx`;
  downloadBufferExcel(buffer, filenameNgsPrep);
}
