import { getOligosOverlapsPositions } from "@console/shared";

import { OligoBackgroundColor } from "./oligosComponents";

import type { GeneDesignConfigurationType } from "../../logic/configuration";
import type { GeneDesignReducerState } from "../../logic/reducer";
import type { GeneDesign } from "../../logic/types";
import {
  GeneDesignOligoInfo,
  EmptyInlineBlock,
  OligoOverlapInfo,
  PrimerFwText,
  PrimerLine,
  PrimerRvText,
  OverlapInfo,
  BlockMouseController,
} from "../common";

export const useOligosLayers = ({
  blockIndex,
  geneDesign,
  nuclWidth,
  configuration,
  sequence,
  errors,
}: {
  blockIndex: number;
  configuration: GeneDesignConfigurationType;
  errors?: GeneDesignReducerState["validationErrors"];
  geneDesign: GeneDesign;
  nuclWidth: number;
  sequence: string;
}) => {
  const block = geneDesign.blocks[blockIndex];
  const oligos = block.oligos;
  // oligo information
  const forwardOligosView = [] as (JSX.Element | null)[];
  const reverseOligosView = [] as (JSX.Element | null)[];
  // bg colors
  const backgroundColorView = [] as (JSX.Element | null)[];
  // oligo controls
  const blockControlsView = [] as (JSX.Element | null)[];
  // overlap oligos
  const overlapViews = [] as (JSX.Element | null)[];
  const overlapInfoViews = [] as (JSX.Element | null)[];

  // forward primer
  let forwardPrimerBg = null as JSX.Element | null;
  let forwardPrimerInfo = null as JSX.Element | null;

  if (block.primers?.forward) {
    const primer = block.primers.forward;
    const relativeStartPos = primer.start - block.start;
    const relativeEndPos = primer.end - block.start;

    const primerLength = relativeEndPos - relativeStartPos;
    const primerWidth = primerLength * nuclWidth;

    forwardPrimerBg = (
      <div
        className="absolute top-[3px]"
        key="first-fw-primer"
        style={{
          left: relativeStartPos * nuclWidth,
        }}
      >
        <PrimerLine
          bgColor={configuration.getFvPrimerColor(1)}
          type="forward"
          width={primerWidth}
        >
          <div
            className="absolute right-0 top-0 flex h-full w-[10px] cursor-pointer select-none items-center bg-black text-white opacity-0 group-hover:opacity-20"
            data-blockindex={blockIndex}
            data-blocktype="primer"
            data-currentpos={primer.end}
            data-location="end"
            data-primertype="forward"
          />
        </PrimerLine>
      </div>
    );

    forwardPrimerInfo = (
      <div
        className="group relative inline-block text-left"
        key="block-fw-primer-info"
        style={{
          marginLeft: relativeStartPos * nuclWidth,
          width: primerWidth,
        }}
      >
        <div className="sticky left-0 right-0 inline-block text-sm">
          <PrimerFwText configuration={configuration} label="gene fw primer" />
          <OverlapInfo
            configuration={configuration}
            end={relativeEndPos}
            sequence={sequence}
            start={relativeStartPos}
          />
        </div>
      </div>
    );
  }

  let reversePrimerBg = null as JSX.Element | null;
  let reversePrimerInfo = null as JSX.Element | null;
  if (block.primers?.reverse) {
    const primer = block.primers.reverse;
    const relativeStartPos = primer.start - block.start;
    const relativeEndPos = primer.end - block.start;

    const primerLength = relativeEndPos - relativeStartPos;
    const primerWidth = primerLength * nuclWidth;

    reversePrimerBg = (
      <div
        className="absolute bottom-[3px]"
        key="first-rw-primer"
        style={{
          left: relativeStartPos * nuclWidth,
        }}
      >
        <PrimerLine
          bgColor={configuration.getRvPrimerColor(1)}
          type="reverse"
          width={primerWidth}
        >
          <div
            className="absolute left-0 top-0 flex h-full w-[10px] cursor-pointer select-none items-center bg-black text-white opacity-0 group-hover:opacity-20"
            data-blockindex={blockIndex}
            data-blocktype="primer"
            data-currentpos={primer.start}
            data-location="start"
            data-primertype="reverse"
          />
        </PrimerLine>
      </div>
    );

    reversePrimerInfo = (
      <div
        className="group relative inline-block text-right"
        key="block-rv-primer-info"
        style={{
          marginLeft: relativeStartPos * nuclWidth,
          width: primerWidth,
        }}
      >
        <div className="sticky left-0 right-0 inline-block text-sm">
          <PrimerRvText configuration={configuration} label="gene rv primer" />
          <OverlapInfo
            configuration={configuration}
            end={relativeEndPos}
            sequence={sequence}
            start={relativeStartPos}
          />
        </div>
      </div>
    );
  }

  //  Ovrelaps
  const { overlaps, firstForwardStartPos, lastReversEndPos } =
    getOligosOverlapsPositions(block.oligos);
  {
    // add empty space before first overlap
    const offsetWidth = (overlaps[0] - block.start) * nuclWidth;
    overlapInfoViews.push(
      <EmptyInlineBlock
        key={overlaps[0] + "-offset-empty-overlap-" + block.start}
        width={offsetWidth}
      />,
    );
  }
  for (let i = 0; i < overlaps.length - 1; i++) {
    const absoluteStartPos = overlaps[i];
    const absoluteEndPosition = overlaps[i + 1];
    const relativeStartPos = absoluteStartPos - block.start;
    const relativeEndPos = absoluteEndPosition - block.start;

    const overlapLength = absoluteEndPosition - absoluteStartPos;
    const overlapWidth = overlapLength * nuclWidth;
    const overlapLeftOffset = relativeStartPos * nuclWidth;

    overlapInfoViews.push(
      <OligoOverlapInfo
        configuration={configuration}
        end={relativeEndPos}
        hightlight={
          !!errors?.[`blocks.${blockIndex}.oligos.overlap.${absoluteStartPos}`]
        }
        key={absoluteStartPos + "-overlap-info-" + block.start}
        sequence={sequence}
        start={relativeStartPos}
        width={overlapWidth}
      >
        {errors?.[`blocks.${blockIndex}.oligos.overlap.${absoluteStartPos}`]}
      </OligoOverlapInfo>,
    );

    overlapViews.push(
      <div
        className="absolute top-[-5%] h-[110%] rounded border border-dashed border-black"
        key={absoluteStartPos + "-overlap-view-" + block.start}
        style={{
          left: `${overlapLeftOffset}px`,
          width: `${overlapWidth}px`,
        }}
      ></div>,
    );
  }

  let counterOfForwards = 0;
  let counterOfReverses = 0;
  oligos.forEach((currentOligo, currentOligoIndex) => {
    const relativeStartPos = currentOligo.start - block.start;
    const relativeEndPos = currentOligo.end - block.start;

    const oligoWidth = (relativeEndPos - relativeStartPos) * nuclWidth;
    const oligoLeftOffset = relativeStartPos * nuclWidth;

    if (currentOligo.direction === "forward") {
      if (counterOfForwards === 0 && oligoLeftOffset >= 0) {
        // add empty space before oligo
        forwardOligosView.push(
          <EmptyInlineBlock
            key={currentOligoIndex + "-offset-empty-forward-" + block.start}
            width={oligoLeftOffset}
          />,
        );
      }
      forwardOligosView.push(
        <GeneDesignOligoInfo
          blockIndex={currentOligoIndex}
          configuration={configuration}
          hasError={
            !!errors?.[`blocks.${blockIndex}.oligos.${currentOligoIndex}`]
          }
          key={currentOligoIndex + "-oligo-forward-" + block.start}
          label={currentOligo.name}
          width={oligoWidth}
        >
          {errors?.[`blocks.${blockIndex}.oligos.${currentOligoIndex}`]}
        </GeneDesignOligoInfo>,
      );
      counterOfForwards++;
    }

    if (currentOligo.direction === "reverse") {
      if (counterOfReverses === 0 && oligoLeftOffset >= 0) {
        // add empty space before oligo
        reverseOligosView.push(
          <EmptyInlineBlock
            key={currentOligoIndex + "-offset-empty-reverse-" + block.start}
            width={oligoLeftOffset}
          />,
        );
      }
      reverseOligosView.push(
        <GeneDesignOligoInfo
          blockIndex={currentOligoIndex}
          configuration={configuration}
          hasError={
            !!errors?.[`blocks.${blockIndex}.oligos.${currentOligoIndex}`]
          }
          key={currentOligoIndex + "-oligo-reverse-" + block.start}
          label={currentOligo.name}
          width={oligoWidth}
        >
          {errors?.[`blocks.${blockIndex}.oligos.${currentOligoIndex}`]}
        </GeneDesignOligoInfo>,
      );

      counterOfReverses++;
    }

    // oligo constrols
    backgroundColorView.push(
      <OligoBackgroundColor
        bgColor={configuration.getColor(currentOligoIndex)}
        color={configuration.getColor(currentOligoIndex, 1)}
        key={currentOligoIndex + "-bg-" + block.start}
        left={oligoLeftOffset}
        top={currentOligo.direction === "reverse" ? "51%" : 0}
        width={oligoWidth}
      />,
    );

    blockControlsView.push(
      <BlockMouseController
        activeIndex={currentOligoIndex}
        blockIndex={blockIndex}
        className={`absolute h-[50%] rounded opacity-0 transition-opacity duration-200 hover:opacity-100 top-[${currentOligo.direction === "reverse" ? "50%" : 0}]`}
        endPosition={currentOligo.end}
        index={currentOligoIndex}
        key={currentOligoIndex + "-control-" + block.start}
        left={oligoLeftOffset}
        oligoIndex={currentOligoIndex}
        showLeftControl={
          !(
            currentOligo.start === firstForwardStartPos &&
            currentOligo.direction === "forward"
          )
        }
        showRightControl={
          !(
            currentOligo.end === lastReversEndPos &&
            currentOligo.direction === "reverse"
          )
        }
        startPosition={currentOligo.start}
        type="oligo"
        width={oligoWidth}
      />,
    );
  });

  return {
    backgroundColorView,
    blockControlsView,
    forwardOligosView,
    forwardPrimerBg,
    forwardPrimerInfo,
    overlapInfoViews,
    overlapViews,
    reverseOligosView,
    reversePrimerBg,
    reversePrimerInfo,
  };
};
