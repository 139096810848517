import { Info } from "lucide-react";
import { StringParam, useQueryParam } from "use-query-params";

import { Display } from "./plate/display";
import { DISPLAYS, DISPLAY_PROPERTIES, DisplayType } from "./plate/display";
import type { PlateKit } from "./plate/types";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";

export const useDisplaySelector = (
  hasResults: boolean,
  hasModifiers: boolean,
  kitProperties: PlateKit,
  isService: boolean,
) => {
  const [localDisplay, setDisplay] = useQueryParam("display", StringParam);
  const display = (localDisplay || Display.length) as Display;

  function handleSelectDisplay(newDisplay: string) {
    setDisplay(newDisplay);
  }

  const allDisplays = DISPLAYS.filter(
    (d) => hasResults || DISPLAY_PROPERTIES[d].displayType === DisplayType.base,
  ).filter(
    (d) =>
      (hasModifiers || d !== Display.modification) &&
      (isService || d !== Display.order),
  );

  const plateDisplays = allDisplays.filter(
    (d) =>
      DISPLAY_PROPERTIES[d].displayType === DisplayType.base ||
      (isService && d === Display.order),
  ) as Display[];
  const resultDisplays = allDisplays.filter(
    (d) => DISPLAY_PROPERTIES[d].displayType === DisplayType.result,
  ) as Display[];
  const qcDisplays = allDisplays.filter(
    (d) => isService && DISPLAY_PROPERTIES[d].displayType === DisplayType.qc,
  ) as Display[];

  const { Legend, unit } = DISPLAY_PROPERTIES[display];

  const node = (
    <div className="flex flex-row space-x-1">
      <Select onValueChange={handleSelectDisplay} value={display}>
        <SelectTrigger className="bg-card h-[32px] w-[150px]">
          <SelectValue placeholder="Theme" />
        </SelectTrigger>
        <SelectContent className="max-h-[50vh] overflow-y-scroll">
          {plateDisplays.map((d) => (
            <SelectItem className="cursor-pointer" key={d} value={d}>
              {DISPLAY_PROPERTIES[d].label}
            </SelectItem>
          ))}
          {hasResults && (
            <>
              <SelectSeparator />
              {resultDisplays.map((d) => (
                <SelectItem className="cursor-pointer" key={d} value={d}>
                  {DISPLAY_PROPERTIES[d].label}
                </SelectItem>
              ))}
              {qcDisplays.length > 0 && (
                <>
                  <SelectSeparator />
                  {qcDisplays.map((d) => (
                    <SelectItem className="cursor-pointer" key={d} value={d}>
                      {DISPLAY_PROPERTIES[d].label}
                    </SelectItem>
                  ))}
                </>
              )}
            </>
          )}
        </SelectContent>
        <Tooltip delayDuration={0}>
          <TooltipTrigger className="flex flex-row space-x-1" type="button">
            <Info size={16} />
          </TooltipTrigger>
          <TooltipContent>
            <Legend kitProperties={kitProperties} />
          </TooltipContent>
        </Tooltip>
      </Select>
    </div>
  );

  const legend = (
    <div className="relative">
      <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        {unit}
      </p>
    </div>
  );
  return { display, legend, node };
};
