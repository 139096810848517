import { SequenceShippingFormat } from "@console/shared";
import { AlertTriangle, Trash } from "lucide-react";
import type { FieldError } from "react-hook-form";
import { useFormContext, useFieldArray } from "react-hook-form";

import { ShippingFormatComponent } from "./shipping-format";

import { Button } from "../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../components/ui/tooltip";

export const ShippingFormat = ({
  handleChangeFormat,
  format,
}: {
  format: SequenceShippingFormat;
  handleChangeFormat: (format: SequenceShippingFormat) => void;
  index: number;
  useGene: boolean;
}) => {
  const changeFormat = () => {
    const newFormat =
      format === SequenceShippingFormat.Plate
        ? SequenceShippingFormat.Tube
        : SequenceShippingFormat.Plate;
    handleChangeFormat?.(newFormat);
  };

  return (
    <div className="flex flex-col items-center">
      {ShippingFormatComponent[format]}
      <Button onClick={changeFormat} size={"sm"} variant={"ghost"}>
        Switch
      </Button>
    </div>
  );
};

export const SequenceInfos = ({
  index,
  useGene,
}: {
  index: number;
  useGene: boolean;
}) => {
  const prefix = useGene ? "geneFragments" : "oligos";
  function getFieldErrorMessage(error?: FieldError): string | null {
    return error?.message ? String(error?.message) : null;
  }
  const { getFieldState, formState } = useFormContext();
  const { error: sequenceError } = getFieldState(
    `${prefix}.${index}.sequence`,
    formState,
  );
  const { error: nameError } = getFieldState(
    `${prefix}.${index}.name`,
    formState,
  );
  const { error: formatError } = getFieldState(
    `${prefix}.${index}.format`,
    formState,
  );
  const errors = [sequenceError, nameError, formatError]
    .map(getFieldErrorMessage)
    .filter((x): x is string => Boolean(x));

  if (!errors.length) {
    return null;
  }

  return (
    <Tooltip delayDuration={10}>
      <TooltipTrigger className="text-destructive text-sm font-medium">
        <AlertTriangle />
      </TooltipTrigger>
      <TooltipContent>
        <ul className="text-destructive list-disc px-2 text-sm font-medium">
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </TooltipContent>
    </Tooltip>
  );
};

export const RemoveSequenceFromForm = ({
  index,
  useGene,
}: {
  index: number;
  useGene: boolean;
}) => {
  const form = useFormContext();
  const { remove: removeOligo } = useFieldArray({
    control: form.control,
    name: "oligos",
  });
  const { remove: removeGeneFragment } = useFieldArray({
    control: form.control,
    name: "geneFragments",
  });

  return (
    <Button
      className="space-x-2"
      onClick={() => (useGene ? removeGeneFragment(index) : removeOligo(index))}
      type="button"
      variant={"outline"}
    >
      <Trash />
    </Button>
  );
};
