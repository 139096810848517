export enum Purity {
  Difficult = "Difficult",
  Extreme = "Extreme",
  Normal = "Normal",
  Unknown = "Unknown",
}

export enum OligoStatus {
  Opportunity = "Opportunity",
  Assigned = "Assigned",
  Failed = "Failed",
  Liberated = "Liberated",
  PendingQC = "Pending QC",
  Printing = "Printing",
  Queued = "Queued",
  Synthesized = "Synthesized",
  Canceled = "Canceled",
}

export enum Complexity {
  Normal = "Normal",
  Medium = "Medium",
  Complex = "Complex",
  Unknown = "Unknown",
}
