import {
  ModificationStatus,
  Purity,
  ModificationStatusToLabel,
} from "@console/shared";
import { ChevronsDown, CircleAlert, Dna, EyeOff, Minus } from "lucide-react";
import React from "react";

import {
  ModificationStatusToPosition,
  PurityFlagToIcon,
  PurityFlagToLabel,
} from "./constants";
import type { PlateKit } from "./types";
import { getColorsFromPurity } from "./useGetWellBackgroundColor";

import { Badge } from "../../../../../../components/ui/badge";
import { Separator } from "../../../../../../components/ui/separator";
import { cn } from "../../../../../../lib/utils";
import { usePlateContext } from "../../containers/PlateContainer";

const Gradient = ({
  fromLabel,
  toLabel,
  background,
}: {
  background?: string;
  fromLabel: string;
  toLabel: string;
}) => {
  return (
    <div className="p-2">
      <div className="relative h-6">
        <div className="absolute left-0 top-0 flex h-8 w-10 flex-col items-start">
          <p className="">{fromLabel}</p>
          <div className="h-3 w-0.5 bg-gray-900/30" />
        </div>
        <div className="absolute right-0 top-0 flex h-8 flex-col items-end">
          <p>{toLabel}+</p>
          <div className="h-3 w-0.5 bg-gray-900/30" />
        </div>
      </div>
      <div
        className={"h-6 rounded-lg"}
        style={{
          background,
        }}
      />
    </div>
  );
};

export const SequenceLegend = () => {
  return (
    <div className="space-y-2 p-2">
      <p>The number in the well represents the length of the oligo.</p>
      <Gradient
        background="var(--report-sequence-gradient)"
        fromLabel="15"
        toLabel="500"
      />
      <div className="flex flex-row items-center space-x-2">
        <p className="text-purple-600">
          <Dna />
        </p>
        <p>Contains a leading sequence</p>
      </div>
      <Separator />
      <UnusualQuantification />
    </div>
  );
};

export const ConcentrationLegend = ({
  kitProperties,
  isMeasured = false,
}: {
  isMeasured?: boolean;
  kitProperties: PlateKit;
}) => {
  const { concentration } = kitProperties;
  return (
    <div className="flex min-w-[250px] flex-col space-y-2 p-2">
      <div className="text-center">
        <p className=" font-bold">Concentration (µM)</p>
      </div>
      <div className="flex flex-col">
        <Gradient
          background="var(--report-concentration-gradient)"
          fromLabel={concentration.min.toString()}
          toLabel={concentration.max.toString()}
        />
      </div>
      <Separator />
      {isMeasured && (
        <div className="flex flex-row items-center space-x-2">
          <FailedNormalizationIcon height={20} width={20} />
          <p>{"Failed normalization: > 20% off target concentration"}</p>
        </div>
      )}
      {!isMeasured && (
        <>
          <div className="flex flex-row items-center space-x-2">
            <Minus />
            <p>No target concentration</p>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <ChevronsDown />
            <p>Lowest concentration</p>
          </div>
        </>
      )}
      <UnusualQuantification />
    </div>
  );
};

export const PurityLegend = () => {
  return (
    <div className="min-w-[250px] space-y-2 p-2">
      <div className="grid grid-cols-5 gap-2">
        {[Purity.Normal, Purity.Difficult, Purity.Extreme, Purity.Unknown].map(
          (purity) => {
            const { background, text } = getColorsFromPurity(purity);
            const Icon = PurityFlagToIcon[purity];
            return (
              <React.Fragment key={purity}>
                <div
                  className={cn(
                    "col-span-1 flex aspect-square h-7 w-7 items-center justify-center rounded-full border",
                    `${background} ${text}`,
                  )}
                >
                  <Icon size={16} />
                </div>
                <p className="col-span-4 max-w-[400px]">
                  {PurityFlagToLabel[purity]}
                </p>
              </React.Fragment>
            );
          },
        )}
      </div>
      <Separator />
      <UnusualQuantification />
    </div>
  );
};

export const VolumeLegend = ({
  kitProperties,
}: {
  kitProperties: PlateKit;
}) => {
  const { volume } = kitProperties;
  return (
    <div className="min-w-[250px] space-y-2 p-2">
      <div className="text-center">
        <p className=" font-bold">Well Volume (µL)</p>
      </div>
      <Gradient
        background="var(--report-volume-gradient)"
        fromLabel={`${volume.min}`}
        toLabel={`${volume.max}`}
      />
      <Separator />
      <UnusualQuantification />
    </div>
  );
};

export const YieldLegend = ({ kitProperties }: { kitProperties: PlateKit }) => {
  const { yields } = kitProperties;
  return (
    <div className="min-w-[250px] space-y-2 p-2">
      <div className="text-center">
        <p className="font-bold">Yield (pmol)</p>
      </div>
      <Gradient
        background="var(--report-yield-gradient)"
        fromLabel={`${yields.medium.min}`}
        toLabel={`${yields.high.min}`}
      />
      <Separator />
      <UnusualQuantification />
    </div>
  );
};

export const OrderLegend = () => {
  const { orderColors } = usePlateContext();
  return (
    <div className="space-x-2 space-y-2">
      <div className="text-center">
        <p className="font-bold">Order Netsuite IDs</p>
      </div>
      {Array.from(orderColors.entries()).map(([orderId, bgColor]) => {
        return (
          <Badge className={cn(bgColor, "text-black")} key={orderId}>
            {orderId}
          </Badge>
        );
      })}
    </div>
  );
};

export const ModificationLegend = () => {
  return (
    <div className="min-w-[250px] space-y-2 p-2">
      {[
        ModificationStatus.Successful,
        ModificationStatus.Acceptable,
        ModificationStatus.Low,
        ModificationStatus.NonQuantifiable,
        ModificationStatus.None,
      ].map((modificationStatus) => (
        <div
          className="flex flex-row items-center space-x-2"
          key={modificationStatus}
        >
          <div
            className="bg-well flex aspect-square h-7 w-7 items-center justify-center rounded-full border"
            style={{
              backgroundImage: "var(--report-modification-gradient)",
              backgroundPosition: `${ModificationStatusToPosition[modificationStatus]}%`,
            }}
          >
            {modificationStatus === ModificationStatus.NonQuantifiable && (
              <EyeOff size={16} />
            )}
          </div>
          <p>{ModificationStatusToLabel[modificationStatus]}</p>
        </div>
      ))}
      <Separator />
      <UnusualQuantification />
    </div>
  );
};

export const MeasuredConcentrationLegend = ({
  kitProperties,
}: {
  kitProperties: PlateKit;
}) => {
  return (
    <ConcentrationLegend isMeasured={true} kitProperties={kitProperties} />
  );
};

export const ABILegend = () => {
  return (
    <div className="min-w-[250px] space-y-2 p-2">
      <div className="text-center">
        <p className="font-bold">ABI</p>
      </div>
    </div>
  );
};

export const OP2Legend = () => {
  return (
    <div className="min-w-[250px] space-y-2 p-2">
      <div className="text-center">
        <p className="font-bold">OP2</p>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <div className="bg-well flex aspect-square h-7 w-7 items-center justify-center rounded-full border bg-green-500" />
        <p>Pass</p>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <div className="bg-well flex aspect-square h-7 w-7 items-center justify-center rounded-full border bg-red-500" />
        <p>Fail</p>
      </div>
    </div>
  );
};

export const UnusualQuantificationIcon = () => {
  return (
    <CircleAlert className="text-white" fill="#f97316" height={20} width={20} />
  );
};

export const UnusualQuantification = () => {
  return (
    <div className="flex flex-row items-center space-x-2">
      <UnusualQuantificationIcon />
      <p>Unusual quantification detected</p>
    </div>
  );
};

export const FailedNormalizationIcon = ({
  width = 16,
  height = 16,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg height={height} viewBox="0 0 24 24" width={width}>
      <path d="M21.19 21.19 2.81 2.81 1.39 4.22l4.2 4.2c-1 1.31-1.6 2.94-1.6 4.7C4 17.48 7.58 21 12 21c1.75 0 3.36-.56 4.67-1.5l3.1 3.1 1.42-1.41zM12 19c-3.31 0-6-2.63-6-5.87 0-1.19.36-2.32 1.02-3.28L12 14.83V19zM8.38 5.56 12 2l5.65 5.56C19.1 8.99 20 10.96 20 13.13c0 1.18-.27 2.29-.74 3.3L12 9.17V4.81L9.8 6.97 8.38 5.56z"></path>
    </svg>
  );
};
