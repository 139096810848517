import type {
  ScreeningOrderResponse,
  ScreeningSequenceType,
} from "@console/shared";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@frontend/components/ui/select";
import { Separator } from "@frontend/components/ui/separator";
import { LoadingSpinner } from "@frontend/components/ui/spinner";
import { Card } from "@radix-ui/themes";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { Borders, Style } from "exceljs";
import Excel from "exceljs";
import { Download } from "lucide-react";
import { useMemo, useState } from "react";
import type { Row } from "read-excel-file";
import readXlsxFile from "read-excel-file";
import { v4 } from "uuid";

import HomologyInfo from "./components/homologyInfo";
import type { SequenceRow } from "./types";

import { Button } from "../../components/ui/button";
import { CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { SimpleTable } from "../../components/ui/simple-table";
import { useToast } from "../../components/ui/use-toast";
import { trpc } from "../../config/trpc";
import { cn } from "../../lib/utils";
import { CheckIfTrueElseCross } from "../admin/organizations/components/ui";
import { PurityFlagToName } from "../organization/pages/build/components/plate/constants";
import { SmallColoredDot } from "../organization/pages/build/components/plate/sidebarSingleWell";
import { getColorsFromPurity } from "../organization/pages/build/components/plate/useGetWellBackgroundColor";

export type ScreenResponse = ScreeningOrderResponse;

const columnHelper = createColumnHelper<
  ScreenResponse[number] & { name: string; sequence: string }
>();

export default function Screening() {
  const [sequenceType, setSequenceType] =
    useState<ScreeningSequenceType | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [is384, setIs384] = useState(false);
  const [fileRows, setFileRows] = useState<SequenceRow[]>([]);
  const [response, setResponse] = useState<ScreenResponse | null>(null);
  const [requestId] = useState<string>(() => v4());
  const [isBackgroundJobRunning, setIsBackgroundJobRunning] = useState(false);

  const screeningColumns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Well",
      }),
      columnHelper.accessor("name", {
        header: "Sequence name",
      }),
      columnHelper.accessor("sequence", {
        cell: (info) => info.getValue().length,
        header: "Sequence length",
      }),
      columnHelper.accessor("valid", {
        cell: (info) => <CheckIfTrueElseCross value={info.getValue()} />,
        header: "Biosecurity",
      }),
      columnHelper.accessor("reason", {
        cell: (info) => info.row.original.reason ?? "-",
        header: "Biosecurity reason",
      }),
      ...(sequenceType === "oligos"
        ? [
            columnHelper.accessor("purity.flag", {
              cell: (info) => {
                if (!("purity" in info.row.original)) {
                  return;
                }
                const flag = info.row.original.purity.flag;
                const { background } = getColorsFromPurity(flag);
                return (
                  <p className="col-span-1 flex items-center space-x-1">
                    <SmallColoredDot className={`${background}`} />
                    <span>{PurityFlagToName[flag]}</span>
                  </p>
                );
              },
              header: "Purity flag",
            }),
            columnHelper.accessor("purity.value", {
              cell: (info) => {
                if (!("purity" in info.row.original)) {
                  return;
                }
                return (info.row.original.purity.value * 100).toFixed(2) + "%";
              },
              header: "Purity predicted",
            }),
            columnHelper.accessor("externalComplexity.flag", {
              header: "External complexity flag",
            }),
            columnHelper.accessor("externalComplexity.score", {
              cell: (info) => {
                if (!("externalComplexity" in info.row.original)) {
                  return;
                }
                return info.row.original.externalComplexity.score.toPrecision(
                  2,
                );
              },
              header: "External complexity score",
            }),
          ]
        : [
            columnHelper.accessor("geneComplexity.internal", {
              cell: (info) => {
                if (!("geneComplexity" in info.row.original)) {
                  return;
                }
                return info.row.original.geneComplexity.internal.toFixed(0);
              },
              header: "Internal complexity score",
            }),
          ]),
    ],
    [sequenceType],
  );

  const handleDownloadResults = async () => {
    if (!file || !response) {
      return;
    }
    const workbook = new Excel.Workbook();
    const fileBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(fileBuffer);

    const sheetWithOligos = workbook.getWorksheet(is384 ? 3 : 2);
    if (!sheetWithOligos) {
      return;
    }
    const rows = sheetWithOligos.getRows(3, (is384 ? 384 : 96) + 2);
    const wellIndexToRowIndex = new Map<string, number>();
    rows?.forEach((row, index) => {
      const wellIndex = row.getCell("B").value;
      wellIndexToRowIndex.set(wellIndex as string, index + 3);
    });

    function fillHeader(sheet: Excel.Worksheet) {
      const sheetRow = sheet.getRow(2);
      sheetRow.getCell("J").value = "Biosecurity check";
      sheetRow.getCell("K").value = "Reason ?";
      if (sequenceType === "oligos") {
        sheetRow.getCell("L").value = "Purity Estimate (%)";
        sheetRow.getCell("M").value = "Difficulty score";
        sheetRow.getCell("N").value = "External Complexity score";
        sheetRow.getCell("O").value = "External Complexity flag";
      } else {
        sheetRow.getCell("L").value = "Internal Complexity score";
      }
      sheetRow.commit();
    }
    function fillRows(sheet: Excel.Worksheet, response: ScreenResponse) {
      for (const row of response) {
        const rowIndex = wellIndexToRowIndex.get(row.id);
        if (!rowIndex) {
          continue;
        }
        const sheetRow = sheet.getRow(rowIndex);
        sheetRow.getCell("J").value = row.valid ? "Valid" : "Invalid";
        sheetRow.getCell("K").value = row.reason ?? "";
        if ("purity" in row) {
          sheetRow.getCell("L").value = (row.purity.value * 100).toFixed(1);
          sheetRow.getCell("M").value = PurityFlagToName[row.purity.flag];
          sheetRow.getCell("N").value =
            row.externalComplexity.score.toPrecision(2);
          sheetRow.getCell("O").value = row.externalComplexity.flag;
        } else if ("geneComplexity" in row) {
          sheetRow.getCell("L").value = row.geneComplexity.internal.toFixed(0);
        }
        sheetRow.commit();
      }
    }

    function setStyles(sheet: Excel.Worksheet, response: ScreenResponse) {
      const headerStyle = sheet.getRow(2).getCell("G").style;
      const bodyStyle = sheet.getRow(3).getCell("G").style;
      const singleBorderStyleHeader = headerStyle?.border?.bottom;
      if (!singleBorderStyleHeader || !singleBorderStyleHeader) {
        throw new Error("No border style found");
      }
      const headerLeftBorder: Partial<Borders> = {
        bottom: singleBorderStyleHeader,
        left: singleBorderStyleHeader,
        top: singleBorderStyleHeader,
      };
      const headerRightBorder: Partial<Borders> = {
        bottom: singleBorderStyleHeader,
        right: singleBorderStyleHeader,
        top: singleBorderStyleHeader,
      };
      const headerMiddleBorder: Partial<Borders> = {
        bottom: singleBorderStyleHeader,
        top: singleBorderStyleHeader,
      };
      const headerLeftStyle: Partial<Style> = {
        ...headerStyle,
        border: headerLeftBorder,
      };
      const headerMiddleStyle: Partial<Style> = {
        ...headerStyle,
        border: headerMiddleBorder,
      };
      const headerRightStyle: Partial<Style> = {
        ...headerStyle,
        border: headerRightBorder,
      };
      const bodyFont = {
        ...bodyStyle.font,
        bold: false,
      };
      const bodyLeftStyle: Partial<Style> = {
        ...bodyStyle,
        border: {
          left: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bodyMiddleStyle: Partial<Style> = {
        ...bodyStyle,
        border: {},
        font: bodyFont,
      };
      const bodyRightStyle: Partial<Style> = {
        ...bodyStyle,
        border: {
          right: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bottomStyleMiddle: Partial<Style> = {
        ...bodyStyle,
        border: {
          bottom: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bottomStyleLeft: Partial<Style> = {
        ...bodyStyle,
        border: {
          bottom: singleBorderStyleHeader,
          left: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bottomStyleRight: Partial<Style> = {
        ...bodyStyle,
        border: {
          bottom: singleBorderStyleHeader,
          right: singleBorderStyleHeader,
        },
        font: bodyFont,
      };

      sheet.getColumn("L").numFmt = "0.00%";
      const sheetRow = sheet.getRow(2);
      sheetRow.getCell("J").style = headerLeftStyle;
      sheetRow.getCell("K").style = headerMiddleStyle;
      if (sequenceType === "oligos") {
        sheetRow.getCell("L").style = headerMiddleStyle;
        sheetRow.getCell("M").style = headerMiddleStyle;
        sheetRow.getCell("N").style = headerMiddleStyle;
        sheetRow.getCell("O").style = headerRightStyle;
      } else {
        sheetRow.getCell("L").style = headerRightStyle;
      }
      sheetRow.commit();
      for (const row of response) {
        const rowIndex = wellIndexToRowIndex.get(row.id);
        if (!rowIndex) {
          continue;
        }
        const sheetRow = sheet.getRow(rowIndex);
        const isEnd = rowIndex === (is384 ? 384 + 2 : 96 + 2);
        const styleMiddle = isEnd ? bottomStyleMiddle : bodyMiddleStyle;
        const styleLeft = isEnd ? bottomStyleLeft : bodyLeftStyle;
        const styleRight = isEnd ? bottomStyleRight : bodyRightStyle;
        sheetRow.getCell("J").style = styleLeft;
        sheetRow.getCell("K").style = styleMiddle;
        if (sequenceType === "oligos") {
          sheetRow.getCell("L").style = styleMiddle;
          sheetRow.getCell("M").style = styleMiddle;
          sheetRow.getCell("N").style = styleMiddle;
          sheetRow.getCell("O").style = styleRight;
        } else {
          sheetRow.getCell("L").style = styleRight;
        }
        sheetRow.commit();
      }
    }

    fillHeader(sheetWithOligos);
    fillRows(sheetWithOligos, response);
    setStyles(sheetWithOligos, response);

    sheetWithOligos.columns.forEach((column) => {
      if (!["J", "K", "L", "M", "N", "O"].includes(column?.letter ?? "")) {
        return;
      }
      const lengths = column?.values?.map((v) => v?.toString().length);
      const maxLength = Math.max(
        ...(lengths ?? []).filter((v) => typeof v === "number"),
      );
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${file.name.split(".xlsx")[0]}_screened.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const { toast } = useToast();
  const { mutate: screenMutation, isPending } =
    trpc.screening.screen.useMutation({
      onError(error) {
        toast({
          description: `Invalid sequence. Message: ${error.message}`,
          title: "Screening",
          variant: "destructive",
        });
      },
    });

  trpc.screening.results.useSubscription(requestId, {
    onData(data) {
      setResponse(data);
      setIsBackgroundJobRunning(false);
    },
    onError(error) {
      toast({
        description: `Error: ${error.message}`,
        title: "Screening",
        variant: "destructive",
      });
    },
  });

  const handleDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    setFile(file);
    setResponse(null);
  };

  const handleScreen = async () => {
    if (!file) {
      return;
    }
    const getRowsForPayload = (content: Row[]) =>
      content
        .slice(2, content.length)
        .filter((row) => {
          return row[1] && row[2] && row[3] && row[1] !== "Well";
        })
        .map((row) => {
          return {
            oligoName: row[2] as string,
            sequence: row[3] as string,
            well: row[1] as string,
          };
        })
        .map((row) => ({
          name: row.oligoName,
          sequence: row.sequence,
          well: row.well,
        }));
    const getRowsFromSheet = async (sheetIndex: number) => {
      const content = await readXlsxFile(file, { sheet: sheetIndex });
      return getRowsForPayload(content);
    };
    const getRows = async (): Promise<
      [{ name: string; sequence: string; well: string }[], boolean]
    > => {
      const rows96 = await getRowsFromSheet(2);
      const rows384 = await getRowsFromSheet(3);
      if (rows96.length > 0) {
        return [rows96, false];
      }
      return [rows384, true];
    };
    const [rows, is384] = await getRows();
    setIs384(is384);
    setFileRows(rows);
    if (sequenceType === null) {
      return;
    }
    screenMutation({ requestId, sequences: rows, type: sequenceType });
    setIsBackgroundJobRunning(true);
  };

  const data = useMemo(
    () =>
      response?.map((item) => {
        const row = fileRows.find((row) => row.well === item.id);
        const { name, sequence } = row ?? { name: "", sequence: "" };
        return { name, sequence, ...item };
      }) ?? [],
    [response, fileRows],
  );

  const screeningTable = useReactTable({
    columns: screeningColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });
  const isOk = !response || response.every((row) => row.valid);

  return (
    <div
      className={cn(
        "flex h-screen w-full flex-col items-center space-y-8 overflow-scroll px-24 py-8",
        !response && "justify-center",
        isBackgroundJobRunning && "pointer-events-none cursor-wait opacity-50",
      )}
    >
      <Card>
        <CardHeader>
          <CardTitle>Screening</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col space-y-4">
          <Input accept=".xlsx" onChange={handleDrop} type="file" />
          <Select
            onValueChange={(value) => {
              setSequenceType(value as ScreeningSequenceType);
              setResponse(null);
            }}
          >
            <SelectTrigger className="">
              <SelectValue placeholder="Sequence type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="oligos">Oligos</SelectItem>
              <SelectItem value="genes">Genes</SelectItem>
            </SelectContent>
          </Select>
          <Button
            disabled={
              sequenceType === null ||
              isPending ||
              !file ||
              isBackgroundJobRunning
            }
            onClick={handleScreen}
          >
            Screen
            {isBackgroundJobRunning && <LoadingSpinner className="ml-2" />}
          </Button>
          <div className="font-bold">
            {response &&
              (isOk ? (
                <p>All sequences are valid</p>
              ) : (
                <p>Some sequences are invalid !</p>
              ))}
          </div>
        </CardContent>
      </Card>
      {response && (
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center justify-between space-x-3">
            <h2 className="text-xl font-bold">Biosecurity & Purity</h2>
            <Button
              className="flex flex-row items-center space-x-1"
              onClick={handleDownloadResults}
            >
              <span>Results</span>
              <Download />
            </Button>
          </div>
          <SimpleTable table={screeningTable} />
          {sequenceType === "oligos" && <Separator />}
          {sequenceType === "oligos" && (
            <HomologyInfo filename={file?.name} rows={fileRows} />
          )}
        </div>
      )}
    </div>
  );
}
