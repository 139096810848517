import { ArrowRightToLine, Copy } from "lucide-react";
import { useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import OligoRow from "./components/oligo-row";
import OligoActions from "./components/oligos-actions";

import { Button } from "../../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import type { TableRow } from "../../../../../components/ui/table";
import NewPlateMaker from "../components/new-plate-maker";
import { NewPlateAction } from "../components/new-production-plate.reducer";
import { NewPlateProvider } from "../containers/NewPlateContainer";
import { useGetOligoBacklogColumns } from "../hooks/useGetOligoBacklogColumns";
import useNewPlateContext from "../hooks/useNewPlateContext";
import useOligosKeyboardShortcuts from "../hooks/useOligosKeyboardShortcuts";
import useReplicateOligos from "../hooks/useReplicateOligos";

export default function Oligos() {
  return (
    <NewPlateProvider>
      <OligosInProvider />
    </NewPlateProvider>
  );
}
function OligosInProvider() {
  const {
    allSelectedOligosAreUnassignedAndQueued,
    computeAllSelectedOligosAreUnassignedAndQueued,
    dispatchPlateChange,
    displayReplicatesAsSubRows,
    getSelectedOligos,
    isCreatingPlate,
    isLiberating,
    newPlate,
    oligoRepeats,
    oligos,
    setIsCreatingPlate,
    tableRef,
  } = useNewPlateContext();

  useOligosKeyboardShortcuts();

  const cancel = useCallback(() => {
    dispatchPlateChange({ type: NewPlateAction.Cancel });
    setIsCreatingPlate(false);
  }, [dispatchPlateChange, setIsCreatingPlate]);

  const putOligosOnPlate = () => {
    const selectedOligos = getSelectedOligos();
    dispatchPlateChange({
      payload: selectedOligos,
      type: NewPlateAction.AddOligos,
    });
    tableRef?.current?.setRowSelection((old) => {
      const newSelection = { ...old };
      selectedOligos.forEach((id) => {
        newSelection[id] = false;
      });
      return newSelection;
    });
  };
  const oligosColumns = useGetOligoBacklogColumns();

  const oligoWithoutReplicates = useMemo(
    () =>
      (oligos ?? [])?.filter(
        (o) => !displayReplicatesAsSubRows || !o.isReplicate,
      ),
    [displayReplicatesAsSubRows, oligos],
  );
  const replicateOligos = useReplicateOligos();
  const duplicateOligos = () => {
    const selectedOligos = getSelectedOligos();
    replicateOligos(selectedOligos);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="grid grid-cols-5 gap-4">
        <div className={isCreatingPlate ? "col-span-3" : "col-span-5"}>
          <Card>
            <CardHeader className="flex flex-row items-start justify-between">
              <CardTitle>Oligos</CardTitle>
              <div className="flex justify-end">
                <OligoActions />
              </div>
            </CardHeader>
            <CardContent className="space-y-2">
              <DataTable
                RowComponent={OligoRow as typeof TableRow}
                columns={oligosColumns}
                data={oligoWithoutReplicates}
                enableRowSelection
                getRowCanExpand={(row) =>
                  !row.original.isReplicate &&
                  row.original.replicates > 1 &&
                  displayReplicatesAsSubRows
                }
                getRowId={(row) => row.id}
                getSubRows={
                  displayReplicatesAsSubRows
                    ? (row) => oligoRepeats.get(row.id)
                    : undefined
                }
                meta={{
                  defaultPageSize: 96,
                  paginationSizes: [96, 384],
                }}
                onSelectionChange={(selected) => {
                  computeAllSelectedOligosAreUnassignedAndQueued(
                    selected.map((v) => v.id),
                  );
                }}
                tableContainerClassName="[&_td]:p-1"
                tableRef={tableRef}
                useBorders={false}
              />
              <div className="flex flex-row space-x-2">
                {!isLiberating && (
                  <Button
                    className="flex flex-row space-x-1"
                    onClick={duplicateOligos}
                    variant={"outline"}
                  >
                    <span>Duplicate selected</span>
                    <Copy />
                  </Button>
                )}
                {newPlate && (
                  <Button
                    className="flex flex-row space-x-1"
                    disabled={!allSelectedOligosAreUnassignedAndQueued}
                    onClick={putOligosOnPlate}
                    variant={"outline"}
                  >
                    <span>Put selected oligos on plate</span>
                    <ArrowRightToLine />
                  </Button>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
        {newPlate && (
          <div className="relative col-span-2">
            <NewPlateMaker cancel={cancel} newPlate={newPlate} />
          </div>
        )}
      </div>
    </DndProvider>
  );
}
