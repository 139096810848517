import { useMemo } from "react";

const ReportColors = [
  "bg-report-order-1",
  "bg-report-order-2",
  "bg-report-order-3",
  "bg-report-order-4",
  "bg-report-order-5",
  "bg-report-order-6",
  "bg-report-order-7",
  "bg-report-order-8",
  "bg-report-order-9",
  "bg-report-order-10",
];

export default function useGetMapOfOrderColors(orderSOIds: string[]) {
  return useMemo(() => {
    const orderIds = Array.from(new Set(orderSOIds));
    const colors = new Map<string, string>();
    orderIds.forEach((id, index) => {
      if (!id) {
        return;
      }
      colors.set(id, ReportColors[index % 10]);
    });
    return colors;
  }, [orderSOIds]);
}
