import { PlateStatus } from "@console/shared";

import { Badge } from "../../../../../components/ui/badge";

export const PlateStatusLabel: Record<PlateStatus, string> = {
  [PlateStatus.AwaitingQC]: "Awaiting QC",
  [PlateStatus.Canceled]: "Canceled",
  [PlateStatus.Loaded]: "Loaded on instrument",
  [PlateStatus.Printing]: "Printing",
  [PlateStatus.QueuedOnInstrument]: "Queued on instrument",
  [PlateStatus.Synthesized]: "Synthesis finished",
  [PlateStatus.ToBeAssigned]: "To be assigned an instrument",
  [PlateStatus.Failed]: "Failed",
  [PlateStatus.InQC]: "In QC",
};

export const PlateStatusComponent: Record<PlateStatus, React.ReactNode> = {
  [PlateStatus.InQC]: (
    <Badge className="bg-emerald-500">
      {PlateStatusLabel[PlateStatus.InQC]}
    </Badge>
  ),
  [PlateStatus.Failed]: (
    <Badge className="bg-orange-500">
      {PlateStatusLabel[PlateStatus.Failed]}
    </Badge>
  ),
  [PlateStatus.AwaitingQC]: (
    <Badge className="bg-yellow-500">
      {PlateStatusLabel[PlateStatus.AwaitingQC]}
    </Badge>
  ),
  [PlateStatus.Canceled]: (
    <Badge className="bg-slate-500">
      {PlateStatusLabel[PlateStatus.Canceled]}
    </Badge>
  ),
  [PlateStatus.Loaded]: (
    <Badge className="bg-blue-500">
      {PlateStatusLabel[PlateStatus.Loaded]}
    </Badge>
  ),
  [PlateStatus.Printing]: (
    <Badge className="bg-purple-500">
      {PlateStatusLabel[PlateStatus.Printing]}
    </Badge>
  ),
  [PlateStatus.QueuedOnInstrument]: (
    <Badge className="bg-sky-500">
      {PlateStatusLabel[PlateStatus.QueuedOnInstrument]}
    </Badge>
  ),
  [PlateStatus.Synthesized]: (
    <Badge className="bg-green-500">
      {PlateStatusLabel[PlateStatus.Synthesized]}
    </Badge>
  ),
  [PlateStatus.ToBeAssigned]: (
    <Badge className="bg-pink-500">
      {PlateStatusLabel[PlateStatus.ToBeAssigned]}
    </Badge>
  ),
};
