import { CircleX } from "lucide-react";
import { memo, type FC, type PropsWithChildren } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../../../components/ui/tooltip";
import { cn } from "../../../../../../../lib/utils";
import type { GeneDesignConfigurationType } from "../logic/configuration";
import { calculateMeltingTemperatureAndGC } from "../logic/utils";

type GeneConfig = {
  configuration: GeneDesignConfigurationType;
};

export const BlockName: FC<
  { color: string; hasError?: boolean; label: string } & PropsWithChildren
> = ({ color, label, children, hasError = false }) => {
  return (
    <TooltipProvider>
      <Tooltip open={hasError ? undefined : false}>
        <TooltipTrigger asChild>
          <div
            className="flex items-center gap-1 break-all text-center text-xs font-semibold"
            style={{
              color,
            }}
          >
            {hasError && <CircleX className="text-red-500" size={12} />}
            {label}
          </div>
        </TooltipTrigger>
        <TooltipContent>{children}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

type BpTextProps = {
  bp: number;
  hightlight?: boolean;
} & GeneConfig;
const BpText: FC<BpTextProps> = ({ bp, configuration, hightlight = false }) => {
  return (
    <div
      className={`text-xs font-semibold leading-none ${hightlight ? "text-red-600" : ""}`}
      style={{
        color: hightlight ? undefined : configuration.getOverlapColor(1),
      }}
    >
      {bp} <span className="text-[8px]">bp</span>
    </div>
  );
};

const TemperatureText: FC<{ gc: number; tm: number }> = ({ tm, gc }) => {
  return (
    <div className="text-[10px] text-slate-500">
      {tm}°C - {gc}%GC
    </div>
  );
};

export function PrimerLabel({
  label,
  color,
}: {
  color: string;
  label: string;
}) {
  return (
    <div className="text-xs font-semibold" style={{ color }}>
      {label}
    </div>
  );
}

type PrimerFwTextProps = {
  label: string;
} & GeneConfig;
export const PrimerFwText: FC<PrimerFwTextProps> = ({
  label,
  configuration,
}) => {
  return (
    <PrimerLabel color={configuration.getFvPrimerColor(1)} label={label} />
  );
};

type PrimerRvTextProps = {
  label: string;
} & GeneConfig;
export const PrimerRvText: FC<PrimerRvTextProps> = ({
  label,
  configuration,
}) => {
  return (
    <PrimerLabel color={configuration.getRvPrimerColor(1)} label={label} />
  );
};

type OverlapInfoProps = {
  className?: string;
  end: number;
  hightlight?: boolean;
  sequence: string;
  start: number;
} & GeneConfig &
  PropsWithChildren;
export const OverlapInfo: FC<OverlapInfoProps> = ({
  end,
  sequence,
  start,
  configuration,
  className,
  children,
  hightlight = false,
}) => {
  return (
    <TooltipProvider>
      <Tooltip open={children ? undefined : false}>
        <TooltipTrigger asChild>
          <div className={className}>
            <BpText
              bp={end - start}
              configuration={configuration}
              hightlight={hightlight}
            />
            <TemperatureText
              {...calculateMeltingTemperatureAndGC(sequence, start, end)}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent>{children}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

type GeneDesignSequenceViewProps = {
  sequence: string;
  type?: "block" | "oligo";
};
export const GeneDesignSequenceView = memo(function Sequence({
  sequence,
  type = "block",
}: GeneDesignSequenceViewProps) {
  const pairsNucleotides = {
    A: "T",
    C: "G",
    G: "C",
    T: "A",
  } as { [key: string]: string };

  return (
    <div className="flex w-full">
      {Array.from(sequence).map((nucleotide, i) => (
        <div
          className="inline-block grow basis-0 select-none text-center text-xs leading-none"
          key={`nucl-${nucleotide}-${i}`}
        >
          <div className="pb-1 pt-3">{nucleotide}</div>
          <div className={`pb-3 ${type === "block" ? "opacity-30" : ""}`}>
            {pairsNucleotides[nucleotide]}
          </div>
        </div>
      ))}
    </div>
  );
});

export const EmptyInlineBlock: FC<{ width: number }> = ({ width }) => {
  return (
    <div
      className="inline-block min-w-0 shrink-0 text-center"
      style={{
        width: `${width}px`,
      }}
    ></div>
  );
};

type GeneDesignOligoInfoProps = {
  blockIndex?: number;
  hasError?: boolean;
  label: string;
  width: number;
} & GeneConfig &
  PropsWithChildren;
export const GeneDesignOligoInfo: FC<GeneDesignOligoInfoProps> = ({
  label,
  width,
  blockIndex = 0,
  children,
  configuration,
  hasError,
}) => {
  return (
    <div
      className="inline-block min-w-0 shrink-0 overflow-hidden text-center"
      style={{
        width: `${width}px`,
      }}
    >
      <div className="sticky left-0 right-0 inline-block">
        <BlockName
          color={configuration.getColor(blockIndex, 1)}
          hasError={hasError}
          label={label}
        >
          {children}
        </BlockName>
      </div>
    </div>
  );
};

type OligoOverlapInfoProps = {
  width: number;
} & PropsWithChildren &
  GeneConfig &
  OverlapInfoProps;
export const OligoOverlapInfo: FC<OligoOverlapInfoProps> = ({
  width,
  ...overlapRest
}) => {
  return (
    <div
      className="inline-block min-w-0 shrink-0 text-center"
      style={{
        width: `${width}px`,
      }}
    >
      <div className="sticky left-0 right-0 inline-block">
        <OverlapInfo {...overlapRest} />
      </div>
    </div>
  );
};

type PrimerLineProps = {
  bgColor: string;
  className?: string;
  type: "forward" | "reverse";
  width: number;
};
export const PrimerLine: FC<PrimerLineProps & PropsWithChildren> = ({
  width,
  className,
  bgColor,
  children,
  type,
}) => {
  return (
    <div
      className={cn(
        "transition-scale group relative z-10 h-[4px] origin-top rounded bg-black duration-75 hover:scale-y-[3] hover:opacity-80",
        type === "reverse" ? "origin-bottom" : "origin-top",
        className,
      )}
      style={{
        backgroundColor: bgColor,
        width,
      }}
    >
      {children}
    </div>
  );
};

type PrimerBlockProps = {
  className?: string;
  label: string;
} & GeneConfig;
export const PrimerForwardBlock: FC<PrimerBlockProps> = ({
  label,
  className,
  configuration,
}) => {
  return (
    <div
      className={cn("self-start text-left text-xs font-semibold", className)}
      style={{ color: configuration.getFvPrimerColor(1) }}
    >
      <PrimerFwText configuration={configuration} label={label} />
      <div
        className="mt-2 inline-block rounded border-2 px-3"
        style={{
          backgroundColor: configuration.getFvPrimerColor(),
          border: `1px solid ${configuration.getFvPrimerColor(1)}`,
        }}
      >
        {">"}
      </div>
    </div>
  );
};

export const PrimerReversBlock: FC<PrimerBlockProps> = ({
  label,
  className,
  configuration,
}) => {
  return (
    <div
      className={cn("self-end text-right text-xs font-semibold", className)}
      style={{ color: configuration.getRvPrimerColor(1) }}
    >
      <div
        className="mt-2 inline-block rounded border-2 px-3"
        style={{
          backgroundColor: configuration.getRvPrimerColor(),
          border: `1px solid ${configuration.getRvPrimerColor(1)}`,
        }}
      >
        {"<"}
      </div>
      <PrimerRvText configuration={configuration} label={label} />
    </div>
  );
};

function MouseController({
  blockIndex,
  location,
  oligoIndex,
  currentPosition,
  type,
}: {
  blockIndex: number;
  currentPosition: number;
  location: "start" | "end";
  oligoIndex?: number;
  type: "block" | "oligo" | "primer";
}) {
  return (
    <div
      className="top-0 h-full w-5 cursor-move rounded bg-black opacity-20"
      data-blockindex={blockIndex}
      data-blocktype={type}
      data-currentpos={currentPosition}
      data-location={location}
      data-oligoindex={oligoIndex}
    />
  );
}

export function BlockMouseController({
  blockIndex,
  oligoIndex,
  activeIndex,
  index,
  left,
  width,
  setActiveIndex,
  showLeftControl,
  showRightControl,
  className,
  endPosition,
  startPosition,
  type,
}: {
  activeIndex?: number;
  blockIndex: number;
  className?: string;
  endPosition: number;
  index: number;
  left: number;
  oligoIndex?: number;
  setActiveIndex?: (index: number) => void;
  showLeftControl?: boolean;
  showRightControl?: boolean;
  startPosition: number;
  type: "block" | "oligo" | "primer";
  width: number;
}) {
  return (
    <div
      className={className}
      style={{
        left: left,
        width: width,
        zIndex: activeIndex === index ? 2 : 1,
      }}
    >
      <div
        className="absolute left-0 top-0 flex h-full w-full cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActiveIndex?.(activeIndex === index ? -1 : index);
        }}
      />
      {activeIndex === index && (
        <>
          {showLeftControl && (
            <div className="absolute bottom-0 left-0 top-0">
              <MouseController
                blockIndex={blockIndex}
                currentPosition={startPosition}
                location={"start"}
                oligoIndex={oligoIndex}
                type={type}
              />
            </div>
          )}
          {showRightControl && (
            <div className="absolute bottom-0 right-0 top-0">
              <MouseController
                blockIndex={blockIndex}
                currentPosition={endPosition}
                location={"end"}
                oligoIndex={oligoIndex}
                type={type}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export function ErrorsTooltip({ errors }: { errors?: Record<string, string> }) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center gap-1 break-all text-center text-xs font-semibold">
            <CircleX className="text-red-500" size={24} />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          {Object.keys(errors ?? {}).map((key) => {
            const error = errors?.[key];
            return (
              <div key={key}>
                <span className="text-blue-500">[{key}]</span>:{"\t"}
                <span className="text-red-500">{error}</span>
              </div>
            );
          })}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
