import type { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import useNewPlateContext from "./useNewPlateContext";

import { ExpandColumn } from "../../../../../components/logic/expand-column";
import { SelectColumn } from "../../../../../components/logic/select-column";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../../components/ui/data-table/filters";
import { OrganizationRoutes } from "../../../organization-routes";
import { OligoStatusComponent } from "../components/oligo-status";
import {
  DuplicateOligoButton,
  RemoveOligoButton,
} from "../oligos/components/oligo-column-components";
import type { BacklogOligo } from "../oligos/components/oligo-row";
import { FailCell, LiberationCell } from "../oligos/components/oligos-actions";

const plateCreationColumns: ColumnDef<BacklogOligo>[] = [
  {
    accessorKey: "assigment",
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "assigment",
    meta: {
      title: "Assigment",
    },
  },
];

const plateLinkColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "plate.name",
  cell: ({ row }) =>
    row.original.plate ? (
      <Link
        className="text-black underline"
        to={OrganizationRoutes.SERVICE_PLATE.replace(
          ":plateId",
          row.original.plate.id,
        )}
      >
        {row.original.plate.name}
      </Link>
    ) : null,
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "plateId",
  meta: {
    title: "Plate",
  },
};

const orderLinkColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "orderSOId",
  cell: ({ row }) => (
    <Link
      className="text-black underline"
      to={OrganizationRoutes.SERVICE_ORDER.replace(
        ":orderId",
        row.original.orderId,
      )}
    >
      {row.original.orderSOId}
    </Link>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "orderSOId",
  meta: {
    title: "Order #",
  },
};

const nameColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "name",
  cell: ({ row }) => (
    <p className="relative space-x-1">
      <span>{row.original.name}</span>
      {row.original.replicates > 1 && (
        <span className="absolute right-0 top-0 rounded-full border bg-gray-300 text-xs">
          {row.original.replicates}
        </span>
      )}
    </p>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "name",
  meta: {
    title: "Name",
  },
};

const statusColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "status",
  cell: ({ row }) => OligoStatusComponent[row.original.status],
  filterFn: arrIncludesSomeWithEmptyFn,
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "status",
  meta: {
    title: "Status",
    uniqueFilter: true,
  },
};

const sizeColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "size",
  cell: ({ row }) => row.original.size,
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "size",
  meta: {
    title: "Size (nts)",
  },
};

const actionsColumn: ColumnDef<BacklogOligo> = {
  cell: ({ row }) => (
    <div className="flex flex-row space-x-2">
      <DuplicateOligoButton id={row.original.id} />
      <RemoveOligoButton oligo={row.original} />
    </div>
  ),
  header: "Actions",
  id: "actions",
};

const concentrationColumn: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.concentration ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.concentration
          ? row.original.qcResults?.concentration.toFixed(1)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "concentration",
  meta: {
    title: "Concentration (µM)",
  },
};

const finalYieldColumn: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.finalYield ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.finalYield
          ? row.original.qcResults?.finalYield.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "yield",
  meta: {
    title: "Final yield (pmol)",
  },
};

const runningMassColumn: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.runningMass ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.runningMass
          ? row.original.qcResults?.runningMass.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "running_mass",
  meta: {
    title: "Running mass (µM)",
  },
};

const runningVolumeColumn: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.runningVolume ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.runningVolume
          ? row.original.qcResults?.runningVolume.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "running_volume",
  meta: {
    title: "Running volume (µL)",
  },
};

const abiColumn: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.abiPurity ?? undefined,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.abiPurity
          ? row.original.qcResults?.abiPurity.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "abiPurity",
  meta: {
    title: "ABI Purity (%)",
  },
};

const op2Column: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.op2Purity ?? undefined,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.op2Purity
          ? row.original.qcResults?.op2Purity.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "op2Purity",
  meta: {
    title: "OP2 Purity (%)",
  },
};

const liberationColumn: ColumnDef<BacklogOligo> = {
  cell: ({ row }) => <LiberationCell row={row} />,
  header: "Liberate",
  id: "liberation",
};

const failColumn: ColumnDef<BacklogOligo> = {
  cell: ({ row }) => <FailCell row={row} />,
  header: "Fail",
  id: "fail",
};

export const useGetOligoBacklogColumns = (): ColumnDef<BacklogOligo>[] => {
  const { displayQCColumns, isLiberating, isCreatingPlate } =
    useNewPlateContext();

  const coreColumns = displayQCColumns
    ? [
        orderLinkColumn,
        nameColumn,
        plateLinkColumn,
        statusColumn,
        runningMassColumn,
        runningVolumeColumn,
        concentrationColumn,
        finalYieldColumn,
        abiColumn,
        op2Column,
      ]
    : [
        orderLinkColumn,
        nameColumn,
        ...(isCreatingPlate ? plateCreationColumns : [plateLinkColumn]),
        statusColumn,
        sizeColumn,
        actionsColumn,
      ];
  if (isLiberating) {
    coreColumns.push(...[liberationColumn, failColumn]);
  }

  return [SelectColumn(() => null), ...coreColumns, ExpandColumn()];
};
