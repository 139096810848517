import type { AppRouter } from "@console/backend/appRouter";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Link } from "react-router-dom";

import { OligoStatusComponent } from "./oligo-status";

import { ExpandColumn } from "../../../../../components/logic/expand-column";
import { Badge } from "../../../../../components/ui/badge";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../../components/ui/data-table/filters";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { OrganizationRoutes } from "../../../organization-routes";
import { SmallColoredDot } from "../../build/components/plate/sidebarSingleWell";
import { SequenceInputForCustomersDisabled } from "../../construct/components/sequence-input";
import { EditableCell } from "../../construct/components/useGetOligoColumns";

export type OrderDetailsTRPC = inferProcedureOutput<AppRouter["order"]["read"]>;
export type OrderItem = OrderDetailsTRPC["items"][number];

// eslint-disable-next-line react-refresh/only-export-components
export const oligoColumns: ColumnDef<OrderItem>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => row.original.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "status",
    cell: ({ row }) => OligoStatusComponent[row.original.itemStatus],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "status",
    meta: {
      title: "Status",
      uniqueFilter: true,
    },
  },
  {
    cell: ({ row }) => row.original.sequence.length,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "size",
    meta: {
      title: "Size (nts)",
    },
  },
  {
    accessorKey: "sequence",
    cell: (info) => (
      <div>
        <EditableCell
          InputComponent={SequenceInputForCustomersDisabled}
          // @ts-expect-error Hack
          cell={info}
          initialValue={info.row.original.sequence}
          locked
        />
      </div>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "sequence",
    meta: {
      title: "Sequence",
    },
    size: 600,
  },
  {
    accessorFn: (row) => !!row.biosecurityError,
    cell: ({ row }) => (
      <Badge className="bg-slate-50" variant="outline">
        {row.original.biosecurityError ? (
          <Tooltip>
            <TooltipTrigger>
              <p className="flex items-center">
                <SmallColoredDot className="bg-orange-500" />
                <span>Warning</span>
              </p>
            </TooltipTrigger>
            <TooltipContent>{row.original.biosecurityError}</TooltipContent>
          </Tooltip>
        ) : (
          <>
            <SmallColoredDot className="bg-green-500" />
            OK
          </>
        )}
      </Badge>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "biosecurity",
    meta: {
      booleanFilter: true,
      title: "Biosecurity check",
    },
  },
  ExpandColumn(),
];

export function ItemSubRow({ row }: { row: OrderItem }) {
  return (
    <ul className="list-disc space-y-1">
      {row.oligos.map((oligo) => (
        <li className="grid grid-cols-4 gap-1" key={oligo.id}>
          <span>{oligo.name}</span>
          <span>{OligoStatusComponent[oligo.status]}</span>
          {oligo.placement && (
            <>
              <Link
                className="text-blue-500 underline"
                to={OrganizationRoutes.SERVICE_PLATE.replace(
                  ":plateId",
                  oligo.placement.plateId,
                )}
              >
                {oligo.placement.plate} - {oligo.placement.well}
              </Link>
              {oligo.placement.yield && (
                <span>{Math.floor(oligo.placement.yield)} pMol</span>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );
}
