import { OligoStatus } from "@console/shared";
import type { Table as TanStackTableType } from "@tanstack/react-table";
import type React from "react";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { trpc } from "../../../../../config/trpc";
import {
  NewPlateAction,
  newPlateReducer,
  type NewPlate,
  type NewPlateReducerActions,
} from "../components/new-production-plate.reducer";
import useGetMapOfOrderColors from "../hooks/useGetMapOfOrderColors";
import type { BacklogOligo } from "../oligos/components/oligo-row";

export type LiberationState = Record<
  string,
  { liberation: string | boolean; qcPrefilledValue: boolean }
>;

export type NewPlateContextType = {
  allSelectedOligosAreUnassignedAndQueued: boolean;
  computeAllSelectedOligosAreUnassignedAndQueued: (
    selectedOligoIds: string[],
  ) => void;
  dispatchPlateChange: React.Dispatch<NewPlateReducerActions>;
  displayQCColumns: boolean;
  displayReplicatesAsSubRows: boolean;
  getOligoBgColor: (oligoId: string) => string | undefined;
  getOligoById: (oligoId: string) => BacklogOligo | undefined;
  getSelectedOligos: () => string[];
  initPlate: () => void;
  isCreatingPlate: boolean;
  isLiberating: boolean;
  liberationState: LiberationState;
  newPlate: NewPlate;
  oligoRepeats: Map<string, BacklogOligo[]>;
  oligos: BacklogOligo[];
  oligosById: Map<string, BacklogOligo>;
  orderColors: Map<string, string>;
  setDisplayQCColumns: (shouldDisplayQC: boolean) => void;
  setDisplayReplicatesAsSubRows: (shouldDisplayQC: boolean) => void;
  setIsCreatingPlate: (value: boolean) => void;
  setIsLiberating: (isLiberating: boolean) => void;
  setLiberationState: React.Dispatch<React.SetStateAction<LiberationState>>;
  tableRef: React.MutableRefObject<TanStackTableType<BacklogOligo> | null>;
};

export const NewPlateContext = createContext<NewPlateContextType>({
  allSelectedOligosAreUnassignedAndQueued: false,
  computeAllSelectedOligosAreUnassignedAndQueued: () => {},
  dispatchPlateChange: () => {},
  displayQCColumns: false,
  displayReplicatesAsSubRows: false,
  getOligoBgColor: () => undefined,
  getOligoById: () => undefined,
  getSelectedOligos: () => [],
  initPlate: () => {},
  isCreatingPlate: false,
  isLiberating: false,
  liberationState: {},
  newPlate: null,
  oligoRepeats: new Map(),
  oligos: [],
  oligosById: new Map(),
  orderColors: new Map(),
  setDisplayQCColumns: () => {},
  setDisplayReplicatesAsSubRows: () => {},
  setIsCreatingPlate: () => {},
  setIsLiberating: () => {},
  setLiberationState: () => {},
  tableRef: { current: null },
});

export const NewPlateProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const tableRef = useRef<TanStackTableType<BacklogOligo>>(null);
  const [newPlate, dispatchPlateChange] = useReducer(newPlateReducer, null);
  const [
    allSelectedOligosAreUnassignedAndQueued,
    setAllSelectedOligosAreUnassignedAndQueued,
  ] = useState(false);
  const [displayReplicatesAsSubRows, setDisplayReplicatesAsSubRows] =
    useQueryParam("subrow", BooleanParam);
  const [displayQCColumns, setDisplayQCColumns] = useQueryParam(
    "qc",
    BooleanParam,
  );
  const [isCreatingPlate, setIsCreatingPlate] = useQueryParam(
    "newplate",
    BooleanParam,
  );
  const [liberationState, setLiberationState] = useState<LiberationState>({});
  const [isLiberating, setIsLiberating] = useState(false);

  const initPlate = useCallback(() => {
    dispatchPlateChange({ type: NewPlateAction.Init });
    setIsCreatingPlate(true);
  }, [dispatchPlateChange, setIsCreatingPlate]);

  useEffect(() => {
    if (isCreatingPlate && !newPlate) {
      initPlate();
    }
    if (!isCreatingPlate && newPlate) {
      dispatchPlateChange({ type: NewPlateAction.Cancel });
    }
  }, [dispatchPlateChange, initPlate, isCreatingPlate, newPlate]);

  const { data: fetchedOligos } = trpc.order.oligos.useQuery(undefined, {
    initialData: [],
  });

  const oligoToWells = useMemo(
    () => new Map(newPlate?.wells?.map((w) => [w.oligoId, w.wellIndex])),
    [newPlate?.wells],
  );
  const getSelectedOligos = () => {
    const rows = tableRef.current?.getGroupedSelectedRowModel();
    return rows?.flatRows?.map((row) => row.original.id) ?? [];
  };

  const oligos: BacklogOligo[] = useMemo(() => {
    return (
      fetchedOligos.map(
        (oligo) => ({
          ...oligo,
          assigment: oligoToWells.get(oligo.id),
        }),
        [],
      ) ?? []
    );
  }, [fetchedOligos, oligoToWells]);

  const oligoRepeats = useMemo(() => {
    const oligoRepeats = new Map<string, BacklogOligo[]>();
    oligos.forEach((oligo) => {
      if (oligo.isReplicate) {
        return;
      }
      oligoRepeats.set(
        oligo.id,
        oligos.filter((o) => o.itemId === oligo.itemId && o.isReplicate),
      );
    });
    return oligoRepeats;
  }, [oligos]);

  const orderSOIds = useMemo(
    () => oligos.map((o) => o.orderSOId).filter((x): x is string => Boolean(x)),
    [oligos],
  );
  const orderColors = useGetMapOfOrderColors(orderSOIds);

  const oligosById = useMemo(
    () => new Map(oligos.map((o) => [o.id, o])),
    [oligos],
  );

  const getOligoBgColor = useCallback(
    (oligoId: string) => {
      const oligo = oligosById.get(oligoId);
      if (!oligo || !oligo?.orderSOId) {
        return undefined;
      }
      return orderColors.get(oligo.orderSOId);
    },
    [oligosById, orderColors],
  );

  const getOligoById = useCallback(
    (oligoId: string) => oligosById.get(oligoId),
    [oligosById],
  );

  const computeAllSelectedOligosAreUnassignedAndQueued = useCallback(
    (selectedOligoIds: string[]) => {
      setAllSelectedOligosAreUnassignedAndQueued(
        selectedOligoIds.every((oligoId) => {
          const oligo = oligos.find((oligo) => oligo.id === oligoId);
          return oligo?.status === OligoStatus.Queued && !oligo?.assigment;
        }) && selectedOligoIds.length > 0,
      );
    },
    [oligos],
  );

  const value = useMemo(() => {
    return {
      allSelectedOligosAreUnassignedAndQueued,
      computeAllSelectedOligosAreUnassignedAndQueued,
      dispatchPlateChange,
      displayQCColumns: Boolean(displayQCColumns),
      displayReplicatesAsSubRows: Boolean(displayReplicatesAsSubRows),
      getOligoBgColor,
      getOligoById,
      getSelectedOligos,
      initPlate,
      isCreatingPlate: Boolean(isCreatingPlate),
      isLiberating,
      liberationState,
      newPlate,
      oligoRepeats,
      oligos: oligos,
      oligosById,
      orderColors,
      setDisplayQCColumns,
      setDisplayReplicatesAsSubRows,
      setIsCreatingPlate,
      setIsLiberating,
      setLiberationState,
      tableRef,
    };
  }, [
    allSelectedOligosAreUnassignedAndQueued,
    computeAllSelectedOligosAreUnassignedAndQueued,
    displayQCColumns,
    getOligoBgColor,
    getOligoById,
    initPlate,
    isCreatingPlate,
    isLiberating,
    liberationState,
    newPlate,
    oligoRepeats,
    oligos,
    oligosById,
    orderColors,
    setDisplayQCColumns,
    setIsCreatingPlate,
    displayReplicatesAsSubRows,
    setDisplayReplicatesAsSubRows,
  ]);

  return (
    <NewPlateContext.Provider value={value}>
      {children}
    </NewPlateContext.Provider>
  );
};
