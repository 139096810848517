import { type FC } from "react";

import type { GeneDesignConfigurationType } from "../../logic/configuration";
import type { GeneDesignReducerState } from "../../logic/reducer";
import { type GeneDesign } from "../../logic/types";
import { BlockName, OverlapInfo } from "../common";

export const BlocksTopInformation: FC<{
  configuration: GeneDesignConfigurationType;
  data: GeneDesign;
  errors?: GeneDesignReducerState["validationErrors"];
  sequence: string;
}> = ({ data, configuration, sequence, errors }) => {
  const { nuclWidth } = configuration;
  const blocksInfo = [] as (JSX.Element | null)[];

  data.blocks.forEach((block, blockIndex) => {
    const startPosition = data.blocks[blockIndex - 1]?.end ?? block.start;
    const endPosition = data.blocks[blockIndex + 1]?.start ?? block.end;
    const blockWidth = (endPosition - startPosition) * nuclWidth;

    const overlapLength = startPosition - block.start;
    const overlapWidth = overlapLength * nuclWidth;

    const hightlight = !!errors?.[`blocks.${blockIndex - 1}.overlap`];
    if (overlapWidth > 0) {
      blocksInfo.push(
        <div
          className="relative top-0 inline-block text-center text-xs"
          key={blockIndex + "-connect-block"}
          style={{
            width: overlapWidth,
          }}
        >
          <div className="sticky left-0 right-0 inline-block pb-1">
            <OverlapInfo
              configuration={configuration}
              end={startPosition}
              hightlight={hightlight}
              sequence={sequence}
              start={block.start}
            >
              {errors?.[`blocks.${blockIndex - 1}.overlap`]}
            </OverlapInfo>
          </div>
        </div>,
      );
    }

    const blockErrors = new Set(
      Object.keys(errors ?? {})
        .map((key) => {
          const error = errors?.[key];
          if (key.startsWith(`blocks.${blockIndex}`)) {
            return error;
          }

          return null;
        })
        .filter((error) => error),
    );

    blocksInfo.push(
      <div
        className="relative top-0 inline-block text-center text-xs"
        key={blockIndex + "-block"}
        style={{
          width: blockWidth,
        }}
      >
        <div className="sticky left-0 right-0 inline-block px-3">
          <BlockName
            color={configuration.getColor(blockIndex, 1)}
            hasError={blockErrors.size > 0}
            label={block.name ?? `block ${blockIndex + 1}`}
          >
            {Array.from(blockErrors).map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </BlockName>
        </div>
      </div>,
    );
  });

  return <>{blocksInfo}</>;
};
