import { Navigate, Outlet, useLocation } from "react-router-dom";

import { OrganizationRoutes } from "../../organization-routes";

export default function Service() {
  const location = useLocation();
  if (location.pathname.endsWith("/service")) {
    return <Navigate to={OrganizationRoutes.SERVICE_ORDERS} />;
  }

  return (
    <div className="flex flex-col space-y-3">
      <Outlet />
    </div>
  );
}
