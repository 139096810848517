import { createContext, useCallback, useContext, useMemo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import useGetMapOfOrderColors from "../../order/hooks/useGetMapOfOrderColors";
import type { PlateFromTRPC } from "../components/plate/types";

export type PlateContextType = {
  getWellOrderBgColor: (wellId: string) => string | undefined;
  isSidebarQC: boolean;
  orderColors: Map<string, string>;
  plate: PlateFromTRPC;
  setIsSidebarQC: (value: boolean) => void;
};

export const PlateContext = createContext<PlateContextType | undefined>(
  undefined,
);

export const PlateProvider = ({
  plate,
  children,
}: React.PropsWithChildren<{
  plate: PlateFromTRPC;
}>) => {
  const [isSidebarQC, setIsSidebarQC] = useQueryParam("qc", BooleanParam);
  const orderSOIds = useMemo(
    () =>
      plate.wells
        .map((w) => w.order?.netsuiteSOId)
        .filter((x): x is string => Boolean(x)),
    [plate],
  );
  const orderColors = useGetMapOfOrderColors(orderSOIds);

  const getWellOrderBgColor = useCallback(
    (wellId: string) => {
      const well = plate.wells.find((w) => w.id === wellId);
      if (!well || !well?.order) {
        return undefined;
      }
      return orderColors.get(well.order.netsuiteSOId);
    },
    [orderColors, plate.wells],
  );

  const value = useMemo(() => {
    return {
      getWellOrderBgColor,
      isSidebarQC: Boolean(isSidebarQC),
      orderColors,
      plate,
      setIsSidebarQC,
    };
  }, [getWellOrderBgColor, isSidebarQC, orderColors, plate, setIsSidebarQC]);

  return (
    <PlateContext.Provider value={value}>{children}</PlateContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const usePlateContext = () => {
  const context = useContext(PlateContext);
  if (!context) {
    throw new Error("usePlateContext must be used within a PlateProvider");
  }
  return context;
};
