// eslint-disable-next-line react-refresh/only-export-components
export function addGtmHeadScript() {
  const script = document.createElement("script");
  script.src = "/gtmHead.js";
  document.head.appendChild(script);
}

export function BodyScript() {
  return (
    <noscript>
      <iframe
        height="0"
        src="https://www.googletagmanager.com/ns.html?id=GTM-NZ2KSPW"
        style={{ display: "none", visibility: "hidden" }}
        width="0"
      />
    </noscript>
  );
}
