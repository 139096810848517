import { Download } from "lucide-react";

import { Button } from "./button";

import { useDownloadFile } from "../../utils/useDownload";

export default function DownloadFile({
  url,
  defaultName = "file",
}: {
  defaultName?: string;
  url?: string;
}) {
  const fileDownloader = useDownloadFile();
  if (!url) {
    return null;
  }

  return (
    <Button onClick={() => fileDownloader(url, defaultName)} variant={"ghost"}>
      <Download />
    </Button>
  );
}
