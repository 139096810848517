import { defaultLowerTM, defaultUpperTM } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import type { CutGeneAssemblyParametersv1 } from "./useCreateGeneDesign";
import {
  type CutGeneAssemblyParameters,
  cutGeneParametersSchema,
  DefaultAssemblyParametersv1,
  DefaultAssemblyParametersv2,
  PriorityMode,
  useCreateGeneDesign,
} from "./useCreateGeneDesign";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../components/ui/accordion";
import { Button } from "../../../../../components/ui/button";
import { Checkbox } from "../../../../../components/ui/checkbox";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../../components/ui/radio";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { RangeSlider } from "../../../../../components/ui/slider";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

const PriorityModeLabel = {
  [PriorityMode.TM]: "Melting temperature",
  [PriorityMode.LENGTH]: "Length",
};

const DesignVersionLabel = {
  v1: "First oligo design integration",
  v2: "Latest oligo design integration to Console (22/01/2025)",
};

type GeneDesignFormProps = {
  assemblyParameters?: CutGeneAssemblyParameters;
  designName: string;
  isDisabled?: boolean;
  isPending?: boolean;
  onSubmit: (parameters: CutGeneAssemblyParameters) => void;
  submitButtonLabel?: string;
};
export const GeneDesignForm = ({
  designName,
  onSubmit,
  submitButtonLabel = "Design",
  assemblyParameters,
  isPending,
  isDisabled,
}: GeneDesignFormProps) => {
  const form = useForm<CutGeneAssemblyParameters>({
    defaultValues: {
      ...DefaultAssemblyParametersv1,
      name: designName,
      version: "v2",
    },
    resolver: zodResolver(cutGeneParametersSchema),
    values: assemblyParameters,
  });

  const currentUpperTM = form.watch("upper_tm_threshold");
  const currentLowerTM = form.watch("lower_tm_threshold");
  const formVersion = form.watch("version");
  const isVersion1 = formVersion === "v1";
  const isPrimerFirst = isVersion1
    ? form.watch("advanced.primer_first")
    : form.watch("primer_first");
  const isErrorCorrection = isVersion1
    ? form.watch("advanced.use_error_correction")
    : form.watch("use_error_correction");

  return (
    <Form {...form}>
      <form
        className="flex flex-col space-y-4 px-1"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="version"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Version</FormLabel>
              <Select
                defaultValue={field.value}
                onValueChange={(v) => {
                  form.reset({
                    ...(v === "v1"
                      ? DefaultAssemblyParametersv1
                      : DefaultAssemblyParametersv2),
                    name: designName,
                  });
                }}
                {...field}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {(["v1", "v2"] as const).map((value) => (
                    <SelectItem key={value} value={value}>
                      {DesignVersionLabel[value]}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Name of the design</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder={DefaultAssemblyParametersv1.name.toString()}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="subblock_size"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Total size of each block (bp)</FormLabel>
              <FormControl>
                <Input
                  onChange={(e) =>
                    field.onChange(parseInt(e.currentTarget.value))
                  }
                  placeholder={DefaultAssemblyParametersv1.subblock_size.toString()}
                  type="number"
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="one_block_design"
          render={({ field }) => {
            const checked = field.value;
            return (
              <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={checked}
                    onCheckedChange={(checked) => {
                      field.onChange(checked);
                    }}
                  />
                </FormControl>
                <FormLabel>Design one block</FormLabel>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="overlap_subblocks"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Desired overlap for block sequence (bp)</FormLabel>
              <FormControl>
                <Input
                  onChange={(e) =>
                    field.onChange(parseInt(e.currentTarget.value))
                  }
                  placeholder={DefaultAssemblyParametersv1.overlap_subblocks.toString()}
                  type="number"
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="oligo_size"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Desired size of oligonucleotides (bp)</FormLabel>
              <FormControl>
                <Input
                  onChange={(e) =>
                    field.onChange(parseInt(e.currentTarget.value))
                  }
                  placeholder={DefaultAssemblyParametersv1.oligo_size.toString()}
                  type="number"
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormItem className="flex flex-col">
          <FormLabel>Desired melting temperature range (°C)</FormLabel>
          <div className="flex flex-row space-x-4">
            <p className="flex space-x-1">
              <span>Min</span>
              <span>{currentLowerTM}°C</span>
            </p>
            <p className="flex space-x-1">
              <span>Max</span>
              <span>{currentUpperTM}°C</span>
            </p>
          </div>
          <FormControl>
            <RangeSlider
              defaultValue={[defaultLowerTM, defaultUpperTM]}
              max={80}
              min={20}
              minStepsBetweenThumbs={10}
              onValueChange={(values) => {
                form.setValue("lower_tm_threshold", values[0]);
                form.setValue("upper_tm_threshold", values[1]);
              }}
              step={1}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
        {isVersion1 && (
          <FormField
            control={form.control}
            name="priority"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Desired priority mode</FormLabel>
                <FormControl>
                  <RadioGroup
                    className="flex flex-row gap-2"
                    onValueChange={(v) => {
                      field.onChange(v);
                    }}
                    value={field.value}
                  >
                    <div
                      className="flex items-center space-x-1"
                      key={PriorityMode.TM}
                    >
                      <RadioGroupItem id={"tm-radio"} value={PriorityMode.TM} />
                      <Label htmlFor={"tm-radio"}>
                        {PriorityModeLabel[PriorityMode.TM]}
                      </Label>
                    </div>
                    <div
                      className="flex items-center space-x-1"
                      key={PriorityMode.LENGTH}
                    >
                      <RadioGroupItem
                        id={"tm-radio"}
                        value={PriorityMode.LENGTH}
                      />
                      <Label htmlFor={"tm-radio"}>
                        {PriorityModeLabel[PriorityMode.LENGTH]}
                      </Label>
                    </div>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {isVersion1 && (
          <Accordion collapsible type="single">
            <AccordionItem value="item-1">
              <AccordionTrigger>Show advanced options</AccordionTrigger>
              <AccordionContent className="flex flex-col space-y-4">
                <FormField
                  control={form.control}
                  name="advanced.n_2_design"
                  render={({ field }) => {
                    const checked = field.value;
                    return (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={checked}
                            onCheckedChange={(checked) => {
                              field.onChange(checked);
                            }}
                          />
                        </FormControl>
                        <FormLabel>
                          Do n-2 design for Error Correction step.
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="advanced.add_stubs"
                  render={({ field }) => {
                    const checked = field.value !== null;
                    return (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={checked}
                            onCheckedChange={(checked) => {
                              field.onChange(checked ? "auto" : null);
                            }}
                          />
                        </FormControl>
                        <FormLabel>
                          Use stubs at edge for the primer design.
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="advanced.use_error_correction"
                  render={({ field }) => {
                    const checked = field.value;
                    return (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={checked}
                            onCheckedChange={(checked) => {
                              field.onChange(checked);
                            }}
                          />
                        </FormControl>
                        <FormLabel>
                          Design primers for the Error Correction step.
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="advanced.nb_primers"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>
                        Number of Step 2 primers set you want to use.
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={DefaultAssemblyParametersv1.advanced.nb_primers.toString()}
                          {...field}
                          type="number"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="advanced.primer_first"
                  render={({ field }) => {
                    const checked = field.value;
                    return (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={checked}
                            onCheckedChange={(checked) => {
                              field.onChange(checked);
                              const smart_design_value: CutGeneAssemblyParametersv1["smart_design_parameters"] =
                                checked
                                  ? {
                                      max_block_overlap_size: 60,
                                      max_block_size: 300,
                                      min_block_overlap_size: 40,
                                      min_block_size: 200,
                                    }
                                  : undefined;
                              form.setValue(
                                "smart_design_parameters",
                                smart_design_value,
                              );
                            }}
                          />
                        </FormControl>
                        <FormLabel>
                          Design primers before designing blocks
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                {isPrimerFirst && (
                  <div className="flex flex-col space-y-1 px-1">
                    <div className="grid grid-cols-3 items-center gap-2">
                      <Label>Block size range (bp)</Label>
                      <FormField
                        control={form.control}
                        name="smart_design_parameters.min_block_size"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Input
                                onChange={(e) =>
                                  field.onChange(
                                    parseInt(e.currentTarget.value),
                                  )
                                }
                                placeholder={"200"}
                                type="number"
                                value={field.value}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="smart_design_parameters.max_block_size"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Input
                                onChange={(e) =>
                                  field.onChange(
                                    parseInt(e.currentTarget.value),
                                  )
                                }
                                placeholder={"300"}
                                type="number"
                                value={field.value}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="grid grid-cols-3 items-center gap-2">
                      <Label>Block overlap size range (bp)</Label>
                      <FormField
                        control={form.control}
                        name="smart_design_parameters.min_block_overlap_size"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Input
                                onChange={(e) =>
                                  field.onChange(
                                    parseInt(e.currentTarget.value),
                                  )
                                }
                                placeholder={"40"}
                                type="number"
                                value={field.value}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="smart_design_parameters.max_block_overlap_size"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Input
                                onChange={(e) =>
                                  field.onChange(
                                    parseInt(e.currentTarget.value),
                                  )
                                }
                                placeholder={"60"}
                                type="number"
                                value={field.value}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                )}
                <FormField
                  control={form.control}
                  name="advanced.smart_block_design"
                  render={({ field }) => {
                    const checked = field.value;
                    return (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={checked}
                            disabled
                            onCheckedChange={(checked) => {
                              field.onChange(checked);
                            }}
                          />
                        </FormControl>
                        <FormLabel>
                          Design smart block by using complexity score to avoid
                          difficult regions
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="advanced.smart_oligo_design"
                  render={({ field }) => {
                    const checked = field.value;
                    return (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={checked}
                            disabled
                            onCheckedChange={(checked) => {
                              field.onChange(checked);
                            }}
                          />
                        </FormControl>
                        <FormLabel>
                          Design smart oligos by using complexity score to avoid
                          difficult patterns
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
        {!isVersion1 && (
          <>
            <FormField
              control={form.control}
              name="delta_tm"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col">
                    <FormLabel>
                      Delta melting temperature between oligo overlaps
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder={DefaultAssemblyParametersv2.delta_tm.toString()}
                        {...field}
                        type="number"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="delta_tm_primers"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col">
                    <FormLabel>
                      Maximum delta melting temperature between primers
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder={DefaultAssemblyParametersv2.delta_tm_primers.toString()}
                        {...field}
                        type="number"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="add_stubs"
              render={({ field }) => {
                const checked = field.value !== null;
                return (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={checked}
                        onCheckedChange={(checked) => {
                          field.onChange(checked ? "auto" : null);
                        }}
                      />
                    </FormControl>
                    <FormLabel>
                      Use stubs at edge for the primer design.
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="use_error_correction"
              render={({ field }) => {
                const checked = field.value;
                return (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={checked}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                        }}
                      />
                    </FormControl>
                    <FormLabel>
                      Design primers for the Error Correction step.
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            {isErrorCorrection && (
              <div className="flex flex-col space-y-1 px-1">
                <Label className="font-bold">Error correction primers</Label>
                <div className="grid grid-cols-6 items-center gap-2">
                  <Label>Min size</Label>
                  <FormField
                    control={form.control}
                    name="error_correction_primers.min_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={DefaultAssemblyParametersv2.error_correction_primers?.min_size.toString()}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Label>Max size</Label>
                  <FormField
                    control={form.control}
                    name="error_correction_primers.max_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={DefaultAssemblyParametersv2.error_correction_primers?.max_size.toString()}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Label>Optimal size</Label>
                  <FormField
                    control={form.control}
                    name="error_correction_primers.optimal_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={DefaultAssemblyParametersv2.error_correction_primers?.optimal_size.toString()}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Label>Min tm</Label>
                  <FormField
                    control={form.control}
                    name="error_correction_primers.min_tm"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={DefaultAssemblyParametersv2.error_correction_primers?.min_tm.toString()}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Label>Max tm</Label>
                  <FormField
                    control={form.control}
                    name="error_correction_primers.max_tm"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={DefaultAssemblyParametersv2.error_correction_primers?.max_tm.toString()}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Label>Optimal tm</Label>
                  <FormField
                    control={form.control}
                    name="error_correction_primers.optimal_tm"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={DefaultAssemblyParametersv2.error_correction_primers?.optimal_tm.toString()}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col space-y-1 px-1">
              <Label className="font-bold">Assembly primers</Label>
              <div className="grid grid-cols-6 items-center gap-2">
                <Label>Min size</Label>
                <FormField
                  control={form.control}
                  name="assembly_primers.min_size"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Input
                          onChange={(e) =>
                            field.onChange(parseInt(e.currentTarget.value))
                          }
                          placeholder={DefaultAssemblyParametersv2.assembly_primers?.min_size.toString()}
                          type="number"
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Label>Max size</Label>
                <FormField
                  control={form.control}
                  name="assembly_primers.max_size"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Input
                          onChange={(e) =>
                            field.onChange(parseInt(e.currentTarget.value))
                          }
                          placeholder={DefaultAssemblyParametersv2.assembly_primers?.max_size.toString()}
                          type="number"
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Label>Optimal size</Label>
                <FormField
                  control={form.control}
                  name="assembly_primers.optimal_size"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Input
                          onChange={(e) =>
                            field.onChange(parseInt(e.currentTarget.value))
                          }
                          placeholder={DefaultAssemblyParametersv2.assembly_primers?.optimal_size.toString()}
                          type="number"
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Label>Min tm</Label>
                <FormField
                  control={form.control}
                  name="assembly_primers.min_tm"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Input
                          onChange={(e) =>
                            field.onChange(parseInt(e.currentTarget.value))
                          }
                          placeholder={DefaultAssemblyParametersv2.assembly_primers?.min_tm.toString()}
                          type="number"
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Label>Max tm</Label>
                <FormField
                  control={form.control}
                  name="assembly_primers.max_tm"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Input
                          onChange={(e) =>
                            field.onChange(parseInt(e.currentTarget.value))
                          }
                          placeholder={DefaultAssemblyParametersv2.assembly_primers?.max_tm.toString()}
                          type="number"
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Label>Optimal tm</Label>
                <FormField
                  control={form.control}
                  name="assembly_primers.optimal_tm"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Input
                          onChange={(e) =>
                            field.onChange(parseInt(e.currentTarget.value))
                          }
                          placeholder={DefaultAssemblyParametersv2.assembly_primers?.optimal_tm.toString()}
                          type="number"
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <FormField
              control={form.control}
              name="nb_primers"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>
                    Number of Step 2 primers set you want to use.
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={DefaultAssemblyParametersv2.nb_primers.toString()}
                      {...field}
                      type="number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="primer_first"
              render={({ field }) => {
                const checked = field.value;
                return (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={checked}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>
                      Design primers before designing blocks (primer first)
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            {isPrimerFirst && (
              <div className="flex flex-col space-y-1 px-1">
                <div className="grid grid-cols-3 items-center gap-2">
                  <Label>Block size range (bp)</Label>
                  <FormField
                    control={form.control}
                    name="primer_first_parameters.min_block_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={"200"}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="primer_first_parameters.max_block_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={"300"}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-3 items-center gap-2">
                  <Label>Block overlap size range (bp)</Label>
                  <FormField
                    control={form.control}
                    name="primer_first_parameters.min_block_overlap_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={"40"}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="primer_first_parameters.max_block_overlap_size"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(parseInt(e.currentTarget.value))
                            }
                            placeholder={"60"}
                            type="number"
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            )}
            <FormField
              control={form.control}
              name="reversed_oligos"
              render={({ field }) => {
                const checked = field.value;
                return (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={checked}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                        }}
                      />
                    </FormControl>
                    <FormLabel>
                      Design oligos in the reverse direction
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </>
        )}
        <div className="flex flex-row justify-end">
          <Button disabled={isDisabled} isLoading={isPending} type="submit">
            {submitButtonLabel}
          </Button>
        </div>
      </form>
    </Form>
  );
};

type MutateGeneDesignProps = {
  assemblyParameters?: CutGeneAssemblyParameters;
  designName: string;
  geneId: string;
  onSuccess?: (geneId: string) => void;
  submitButtonLabel?: string;
};

export const NewGeneDesign = ({
  designName,
  geneId,
  assemblyParameters,
  onSuccess,
}: MutateGeneDesignProps) => {
  const { createGeneDesign, isPending } = useCreateGeneDesign(onSuccess);

  return (
    <GeneDesignForm
      assemblyParameters={assemblyParameters}
      designName={designName}
      isPending={isPending}
      onSubmit={(parameters) => {
        createGeneDesign(geneId, parameters);
      }}
      submitButtonLabel="Design"
    />
  );
};

export const RedesignGeneDesign = ({
  designName,
  geneId,
  designId,
  assemblyParameters,
  onSuccess,
  isDisabled,
}: MutateGeneDesignProps & { designId: string; isDisabled: boolean }) => {
  const { toast } = useToast();
  const { mutate: redesignGeneDesign, isPending } =
    trpc.assay.steps.gene.design.redesignGene.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Design failed",
          variant: "warning",
        });
      },
      onSuccess(designId) {
        toast({
          description:
            "The design is being processed. You will be notified when it's ready.",
          title: "Design in progress",
          variant: "success",
        });

        onSuccess?.(designId);
      },
    });

  return (
    <GeneDesignForm
      assemblyParameters={assemblyParameters}
      designName={designName}
      isDisabled={isDisabled}
      isPending={isPending}
      onSubmit={(parameters) => {
        redesignGeneDesign({ ...parameters, designId, geneId, inQueue: true });
      }}
      submitButtonLabel="Redesign"
    />
  );
};
