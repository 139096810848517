import z from "zod";

import { geneDesignParametersSchema } from "./geneDesign";

export type Direction = "forward" | "reverse complement";

export const defaultUpperTM = 60;
export const defaultLowerTM = 40;

export enum DesignAssemblyState {
  Design = "design",
  Error = "error",
  Ready = "ready",
}

const primersParameters = z.object({
  min_size: z.number(),
  max_size: z.number(),
  optimal_size: z.number(),
  min_tm: z.number(),
  max_tm: z.number(),
  optimal_tm: z.number(),
});

export const assemblyParameters = z.discriminatedUnion("version", [
  z.object({
    version: z.literal("v1"),
    advanced: z.object({
      add_stubs: z.string().nullable().default(null),
      n_2_design: z.boolean().default(false),
      nb_primers: z.number().min(1).default(1),
      primer_first: z.boolean().default(false),
      smart_block_design: z.boolean().default(false),
      smart_oligo_design: z.boolean().default(false),
      use_error_correction: z.boolean().default(false),
    }),
    lower_tm_threshold: z.number().default(defaultLowerTM),
    name: z.string(),
    oligo_size: z.number().default(30),
    one_block_design: z.boolean().default(false),
    overlap_subblocks: z.number().default(50),
    priority: z.enum(["tm", "length"]).default("tm"),
    smart_design_parameters: z
      .object({
        max_block_overlap_size: z.number(),
        max_block_size: z.number(),
        min_block_overlap_size: z.number(),
        min_block_size: z.number(),
      })
      .optional(),
    subblock_size: z.number().default(300),
    upper_tm_threshold: z.number().default(defaultUpperTM),
    validationParameters: geneDesignParametersSchema.optional(),
    status: z
      .object({
        state: z.nativeEnum(DesignAssemblyState),
        error: z.string().optional(),
      })
      .optional(),
  }),
  z.object({
    version: z.literal("v2"),
    add_stubs: z.union([z.boolean(), z.string()]).nullable().default(false),
    delta_tm: z.number().default(5),
    lower_tm_threshold: z.number().default(defaultLowerTM),
    delta_tm_primers: z.number().default(2.0),
    name: z.string(),
    nb_primers: z.number().min(1).default(1),
    oligo_size: z.number().default(30),
    one_block_design: z.boolean().default(false),
    overlap_subblocks: z.number().default(50),
    primer_first: z.boolean().default(false),
    subblock_size: z.number().default(300),
    upper_tm_threshold: z.number().default(defaultUpperTM),
    use_error_correction: z.boolean().default(true),
    reversed_oligos: z.boolean().default(false),
    primer_first_parameters: z.object({
      min_block_size: z.number().default(200),
      max_block_size: z.number().default(300),
      min_block_overlap_size: z.number().default(40),
      max_block_overlap_size: z.number().default(60),
    }),
    error_correction_primers: primersParameters
      .default({
        min_size: 18,
        max_size: 25,
        optimal_size: 20,
        min_tm: 50,
        max_tm: 60,
        optimal_tm: 55,
      })
      .optional(),
    assembly_primers: primersParameters
      .default({
        min_size: 20,
        max_size: 30,
        optimal_size: 25,
        min_tm: 57,
        max_tm: 72,
        optimal_tm: 65,
      })
      .optional(),
    validationParameters: geneDesignParametersSchema.optional(),
    status: z
      .object({
        state: z.nativeEnum(DesignAssemblyState),
        error: z.string().optional(),
      })
      .optional(),
  }),
]);
