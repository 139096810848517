import { Heading } from "@radix-ui/themes";
import { Edit2Icon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import OrganizationFlagConfiguration from "./components/organization-flag-configuration";
import OrganizationKitConfiguration from "./components/organization-kit-configuration";

import { Button } from "../../../../components/ui/button";
import { useGetOrganizationProfile } from "../hooks";

export default function OrganizationAdminSettings() {
  const { data: organizationProfile, isPending } = useGetOrganizationProfile();
  const navigate = useNavigate();

  if (isPending) {
    return <Heading>Loading...</Heading>;
  }

  if (!organizationProfile) {
    throw new Error("Organization not found");
  }

  const { configuration } = organizationProfile;

  return (
    <div className="bg-card space-y-4 rounded-lg border p-4">
      <div className="flex justify-between">
        <h3 className="font-bold">Configuration</h3>
        <Button onClick={() => navigate("edit")} variant={"secondary"}>
          <Edit2Icon />
        </Button>
      </div>
      <div className="ml-4">
        <OrganizationFlagConfiguration
          bioSecurityEnabled={configuration.bioSecurityEnabled}
          clicksEnabled={configuration.clicksEnabled}
          customProcessEnabled={configuration.customProcessEnabled}
          degenerateBasesEnabled={configuration.degenerateBasesEnabled}
          geneDesignerEnabled={configuration.geneDesignerEnabled}
          geneEnabled={configuration.geneEnabled}
          isActive={organizationProfile.isActive}
          mfaMandatory={configuration.mfaMandatory}
          modsEnabled={configuration.modsEnabled}
          notificationsEnabled={configuration.notificationsEnabled}
          remoteRunTriggerEnabled={configuration.remoteRunTriggerEnabled}
          serviceEnabled={configuration.serviceEnabled}
        />
      </div>
      <OrganizationKitConfiguration kits={configuration.kits} />
    </div>
  );
}
