import { PlateIndices96 } from "../../build/new-workflow/utils";

export type NewPlateProperties = {
  kit: string;
  name: {
    id: string;
    suffix: string;
  };
  wells: {
    oligoId: string;
    wellIndex: string;
  }[];
};
export type NewPlate = NewPlateProperties | null;

export enum NewPlateAction {
  AddOligos = "AddOligos",
  AssignOligo = "AssignOligo",
  AssignOligos = "AssignOligos",
  Cancel = "Cancel",
  ChangeId = "ChangeId",
  ChangeKit = "ChangeKit",
  ChangeNameSuffix = "ChangeNameSuffix",
  Init = "Init",
  MoveWell = "MoveWell",
  RemoveOligo = "RemoveOligo",
}

export type NewPlateReducerActions =
  | {
      type: NewPlateAction.Cancel;
    }
  | {
      payload: string;
      type: NewPlateAction.ChangeNameSuffix;
    }
  | {
      payload: string;
      type: NewPlateAction.ChangeId;
    }
  | {
      payload: string;
      type: NewPlateAction.ChangeKit;
    }
  | {
      payload: string[];
      type: NewPlateAction.AddOligos;
    }
  | {
      type: NewPlateAction.Init;
    }
  | {
      payload: string;
      type: NewPlateAction.RemoveOligo;
    }
  | {
      payload: {
        oligoId: string;
        wellIndex: string;
      };
      type: NewPlateAction.AssignOligo;
    }
  | {
      payload: {
        oligoIds: string[];
        wellIndex: string;
      };
      type: NewPlateAction.AssignOligos;
    }
  | {
      payload: {
        from: string;
        to: string;
      };
      type: NewPlateAction.MoveWell;
    };

export function newPlateReducer(
  plate: NewPlate,
  action: NewPlateReducerActions,
): NewPlate {
  switch (action.type) {
    case NewPlateAction.Cancel:
      return null;
    case NewPlateAction.Init:
      if (plate?.name) {
        return { ...plate, wells: [] };
      }
      return {
        kit: "B6_HPURE_96_LONG",
        name: {
          id: "",
          suffix: "",
        },
        wells: [],
      };
    case NewPlateAction.ChangeNameSuffix:
      if (!plate) return null;
      return { ...plate, name: { ...plate.name, suffix: action.payload } };
    case NewPlateAction.ChangeId:
      if (!plate) return null;
      return { ...plate, name: { id: action.payload, suffix: "" } };
    case NewPlateAction.ChangeKit:
      if (!plate) return null;
      return { ...plate, kit: action.payload };
    case NewPlateAction.RemoveOligo:
      if (!plate) return null;
      return {
        ...plate,
        wells: plate.wells.filter((w) => w.wellIndex !== action.payload),
      };
    case NewPlateAction.AssignOligo:
      if (!plate) return null;
      const { oligoId, wellIndex } = action.payload;
      return {
        ...plate,
        wells: plate.wells
          .filter((w) => w.wellIndex !== wellIndex)
          .concat([{ oligoId, wellIndex }]),
      };
    case NewPlateAction.AssignOligos: {
      if (!plate) return null;
      const { oligoIds, wellIndex } = action.payload;
      const startingPlateIndex = PlateIndices96.indexOf(wellIndex);
      if (startingPlateIndex === -1) return plate;
      const indicesAfterPosition = PlateIndices96.slice(startingPlateIndex);
      const wellsUsed = new Set(plate.wells.map((w) => w.wellIndex));
      const availableIndices = indicesAfterPosition.filter(
        (index) => !wellsUsed.has(index),
      );

      const newFilledWells: NewPlateProperties["wells"] = [];
      for (const [i, oligoId] of oligoIds.entries()) {
        if (availableIndices[i] === undefined) break;
        newFilledWells.push({
          oligoId,
          wellIndex: availableIndices[i],
        });
      }
      return {
        ...plate,
        wells: plate.wells.concat(newFilledWells),
      };
    }
    case NewPlateAction.MoveWell:
      if (!plate) return null;
      const { from, to } = action.payload;
      const wellFrom = plate.wells.find((w) => w.wellIndex === from);
      const wellTo = plate.wells.find((w) => w.wellIndex === to);
      if (!wellFrom || wellTo) {
        return plate;
      }
      return {
        ...plate,
        wells: plate.wells
          .filter((w) => w.wellIndex !== from)
          .concat([
            {
              oligoId: wellFrom.oligoId,
              wellIndex: to,
            },
          ]),
      };
    case NewPlateAction.AddOligos:
      if (!plate) return null;
      const availableIndices = PlateIndices96.filter(
        (index) => !plate.wells.some((w) => w.wellIndex === index),
      );
      const oligoIds = action.payload;
      const newWells = oligoIds
        .slice(0, availableIndices.length)
        .map((oligoId, i) => ({
          oligoId,
          wellIndex: availableIndices[i],
        }));
      return {
        ...plate,
        wells: plate.wells.concat(newWells),
      };
    default:
      return plate;
  }
}
