import type { BacklogOligo } from "../oligos/components/oligo-row";

const MIN_QUANTITY_PMOL = 50 * 1.1;

export function getOligoQCPrefilledValue(oligo: BacklogOligo): boolean {
  const { qcResults } = oligo;

  if (!qcResults) {
    return false;
  }

  if (!qcResults.runningMass) {
    return false;
  }
  return qcResults.runningMass > MIN_QUANTITY_PMOL;
}
