import { trpc } from "../../../../config/trpc";

export const useDeleteRun = (runId: number | undefined) => {
  const { mutate: deleteRunMutation, isPending } =
    trpc.plate.run.delete.useMutation();

  const deleteRun = () => {
    if (runId === undefined) {
      return;
    }
    deleteRunMutation({ id: runId });
  };

  return { deleteRun, isPending };
};
