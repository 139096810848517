import { Heading } from "@radix-ui/themes";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DEFAULT_PARAMETERS } from "./components/geneDesignView/logic/reducer";
import type {
  GeneDesign,
  GeneDesignParameters,
} from "./components/geneDesignView/logic/types";
import GeneDesignView from "./components/geneDesignView/view/index";
import { ValidationParameters } from "./components/geneDesignView/view/validationParameters";
import { RedesignGeneDesign } from "./components/new-design";
import {
  DeleteDesignButton,
  DownloadDesignButton,
  DownloadDesignParametersButton,
} from "./components/old-designs";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../components/ui/accordion";
import { CardContent, Card } from "../../../../components/ui/card";
import { useToast } from "../../../../components/ui/use-toast";
import { trpc } from "../../../../config/trpc";
import { OrganizationRoutes } from "../../organization-routes";

const useGeneDesign = (designId: string) => {
  const { toast } = useToast();

  const {
    refetch: refetchDesign,
    data: design,
    error: fetchDesignError,
    isPending: isDesignQueryPending,
  } = trpc.assay.steps.gene.design.getDesign.useQuery(
    {
      designId: designId ?? "",
    },
    {
      enabled: !!designId,
    },
  );

  const { mutate: udpateDesignAnalysis, isPending: isUpdatingDesignAnalysis } =
    trpc.assay.steps.gene.design.updateGeneDesign.useMutation();

  trpc.assay.steps.gene.design.subscribeDesignGeneStream.useSubscription(
    {
      designId: designId ?? "",
    },
    {
      onData: () => {
        refetchDesign();
      },
    },
  );

  const updateDesign = useCallback(
    (
      designAnalysis: GeneDesign,
      validationParameters: GeneDesignParameters,
    ) => {
      if (!design) {
        return;
      }

      udpateDesignAnalysis(
        {
          designId: design.id,
          geneId: design.geneId,
          payload: {
            analysis: designAnalysis,
            parameters: validationParameters,
          },
        },
        {
          onError: (error) => {
            toast({
              description: error.message,
              title: "Error updating design analysis",
              variant: "destructive",
            });
          },
          onSuccess: () => {
            toast({
              description: "Design analysis updated",
              title: "Success",
              variant: "success",
            });
          },
        },
      );
    },
    [design, toast, udpateDesignAnalysis],
  );

  useEffect(() => {
    if (fetchDesignError || design?.parameters.status?.state === "error") {
      toast({
        description:
          fetchDesignError?.message ?? design?.parameters.status?.error,
        title: "Error getting design analysis",
        variant: "destructive",
      });
    }
  }, [
    design?.parameters.status?.error,
    design?.parameters.status?.state,
    fetchDesignError,
    toast,
  ]);

  return {
    design,
    isDesignQueryPending,
    isUpdatingDesignAnalysis,
    refetchDesign,
    updateDesign,
  };
};

const EditGeneDesignPage: FC = () => {
  const { constructId, designId: paramDesignId } = useParams();
  const navigate = useNavigate();

  const [designId] = useState<string | undefined>(paramDesignId);
  const [activeAccordion] = useState<
    "configuration" | "parameters" | undefined
  >(undefined);
  const [validationParameters, setValidationParameters] =
    useState<GeneDesignParameters>(DEFAULT_PARAMETERS);

  const {
    design,
    isDesignQueryPending,
    isUpdatingDesignAnalysis,
    refetchDesign,
    updateDesign,
  } = useGeneDesign(designId ?? "");

  useEffect(() => {
    if (design?.parameters.validationParameters) {
      setValidationParameters(design.parameters.validationParameters);
    }
  }, [design?.parameters.validationParameters]);

  if (isDesignQueryPending) {
    return <>Loading...</>;
  }

  if (!design || !designId) {
    return <>Design {designId} not found</>;
  }

  const title = "Edit Gene Design";

  return (
    <div>
      <div className="flex flex-row items-center justify-between pb-4">
        <Heading>
          {title} {designId}
        </Heading>
        <div className="flex gap-2">
          <DownloadDesignButton
            designId={design.id}
            designName={design.name}
            geneId={design.geneId}
          />
          <DownloadDesignParametersButton
            designId={design.id}
            designName={design.name}
            geneId={design.geneId}
          />
          <DeleteDesignButton
            designId={design.id}
            geneId={design.geneId}
            onSuccess={() => {
              navigate(
                OrganizationRoutes.CONSTRUCT.replace(
                  ":constructId",
                  constructId ?? "",
                ),
              );
            }}
          />
        </div>
      </div>
      <Card>
        <CardContent>
          <Accordion collapsible type="single" value={activeAccordion}>
            <AccordionItem value="configuration">
              <AccordionTrigger>Configuration</AccordionTrigger>
              <AccordionContent className="flex flex-col py-2">
                <RedesignGeneDesign
                  assemblyParameters={design?.parameters}
                  designId={designId}
                  designName={design.name ?? ""}
                  geneId={design.geneId}
                  isDisabled={design.parameters.status?.state === "design"}
                  onSuccess={() => refetchDesign()}
                />
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="parameters">
              <AccordionTrigger>Parameters</AccordionTrigger>
              <AccordionContent className="flex flex-col py-2">
                <ValidationParameters
                  onChange={setValidationParameters}
                  parameters={validationParameters}
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <div className="py-2">
            {design && design.response ? (
              <GeneDesignView
                geneDesign={design.response}
                isUpdating={isUpdatingDesignAnalysis}
                onSubmit={(data, validationParameters, errors) => {
                  if (errors) {
                    return;
                  }

                  updateDesign(data, validationParameters);
                }}
                parameters={validationParameters}
              />
            ) : (
              <>
                Design analysis in {design.parameters.status?.state} state.
                <div>{design.parameters.status?.error ?? ""}</div>
                {design.parameters.status?.state === "error" && (
                  <>Please redesign the gene to obtain a valid design.</>
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EditGeneDesignPage;
