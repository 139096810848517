import { StepType } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import { ArrowRightFromLine } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { GeneStepActions } from "./components/gene-step-actions";
import Plate from "./components/plate";
import type { WorkflowStepTRPC } from "./components/plate/types";
import { PlateActions } from "./components/plate-actions";
import WorkflowStepName from "./components/workflow-step-name";
import { PlateProvider } from "./containers/PlateContainer";
import { GeneProgress } from "./gene-progress";
import { useGetWorkflowStep } from "./useGetWorkflowStep";

import { Button } from "../../../../components/ui/button";
import { trpc } from "../../../../config/trpc";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { OrganizationRoutes } from "../../organization-routes";
import useGetAssay from "../assay/useGetAssay";

export default function WorkflowStepWrapper() {
  const { data: workflowStep, isPending, error } = useGetWorkflowStep();

  if (isPending) {
    return null;
  }

  if (error) {
    return (
      <div>
        <Heading>Error retrieving workflow</Heading>
      </div>
    );
  }

  return <WorkflowStep workflowStep={workflowStep} />;
}

function WorkflowStep({ workflowStep }: { workflowStep: WorkflowStepTRPC }) {
  useScrollToTop();
  const navigate = useNavigate();
  const { data: assay } = useGetAssay();
  const { id, name, stepType } = workflowStep;
  const plateId =
    stepType === StepType.OligoSynthesis ? workflowStep.plateId : null;
  const isRunCreated =
    stepType === StepType.OligoSynthesis ? workflowStep.isRunCreated : false;
  const isEditable =
    !isRunCreated &&
    workflowStep.stepType === StepType.OligoSynthesis &&
    !workflowStep.locked;

  if (!assay) {
    return null;
  }

  const isAssayArchived = Boolean(assay.archived);

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <WorkflowStepName
          currentName={name}
          isEditable={isEditable}
          workflowStepId={id}
        />
        <div className="flex flex-row items-center space-x-3">
          <Button
            className="flex flex-row items-center space-x-1"
            onClick={() => {
              navigate(
                OrganizationRoutes.ASSAY.replace(
                  ":assayId",
                  workflowStep.workflow.assayId,
                ),
              );
            }}
            variant={"outline"}
          >
            <span>Assay</span>
            <ArrowRightFromLine />
          </Button>
          {workflowStep.stepType === StepType.HamiltonAssembly && (
            <GeneStepActions step={workflowStep} />
          )}
        </div>
      </div>

      {stepType === StepType.HamiltonAssembly && (
        <GeneProgress gene={workflowStep.gene} />
      )}
      {plateId ? (
        <>
          <WorkflowPlate
            isAssayArchived={isAssayArchived}
            isEditable={isEditable && !isAssayArchived}
            plateId={plateId}
          />
        </>
      ) : null}
    </div>
  );
}

export function WorkflowPlate({
  plateId,
  isEditable,
  isAssayArchived,
}: {
  isAssayArchived: boolean;
  isEditable: boolean;
  plateId: string;
}) {
  const { data: plate } = trpc.plate.get.useQuery(plateId, {
    enabled: !!plateId,
  });
  const utils = trpc.useUtils();
  const onPlateChange = () => {
    utils.assay.steps.get.invalidate();
  };

  if (!plate) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-end">
        <PlateActions plate={plate} uploadDisabled={isAssayArchived} />
      </div>
      <PlateProvider plate={plate}>
        <Plate
          isEditable={isEditable}
          onPlateChange={onPlateChange}
          plate={plate}
        />
      </PlateProvider>
    </div>
  );
}
