import {
  BlockPrimerLine,
  BlockPrimerInfo,
  BlockBgColors,
  BlockOverlap,
} from "./blocksComponents";

import type { GeneDesignConfigurationType } from "../../logic/configuration";
import type { GeneDesignReducerState } from "../../logic/reducer";
import { type GeneDesign } from "../../logic/types";
import { BlockMouseController } from "../common";

export const useBlockLayers = ({
  data,
  activeBlockIndex,
  setActiveBlock,
  configuration,
  sequence,
}: {
  activeBlockIndex: number;
  configuration: GeneDesignConfigurationType;
  data: GeneDesign;
  errors?: GeneDesignReducerState["validationErrors"];
  sequence: string;
  setActiveBlock: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { nuclWidth } = configuration;
  const blockBgColors = [] as (JSX.Element | null)[];
  const blockControls = [] as (JSX.Element | null)[];
  const overlapBlocks = [] as (JSX.Element | null)[];
  const oligosBgColors = [] as (JSX.Element | null)[];
  // primers
  const primerLines = [] as (JSX.Element | null)[];
  const forwardPrimersBlockInfo = [] as (JSX.Element | null)[];
  const reversePrimersBlockInfo = [] as (JSX.Element | null)[];

  {
    // data.primers.forward
    const forward = data.primers.forward;
    const forwardLenght = forward.end - forward.start;
    primerLines.push(
      <BlockPrimerLine
        bgColor={configuration.getFvPrimerColor(1)}
        currentPosition={forward.end}
        key="first-fw-primer"
        left={forward.start * nuclWidth}
        location="end"
        type="forward"
        width={forwardLenght * nuclWidth}
      />,
    );
    forwardPrimersBlockInfo.push(
      <BlockPrimerInfo
        color={configuration.getFvPrimerColor(1)}
        configuration={configuration}
        end={forward.end}
        key={forward.start + "-fw-primer-info"}
        label="gene fw primer"
        sequence={sequence}
        start={forward.start}
      />,
    );
  }

  {
    // data.primers.reverse
    const reverse = data.primers.reverse;
    const reverseLenght = reverse.end - reverse.start;
    primerLines.push(
      <BlockPrimerLine
        bgColor={configuration.getRvPrimerColor(1)}
        currentPosition={reverse.start}
        key="first-rv-primer"
        left={reverse.start * nuclWidth}
        location="start"
        type="reverse"
        width={reverseLenght * nuclWidth}
      />,
    );
    reversePrimersBlockInfo.push(
      <BlockPrimerInfo
        className="text-right"
        color={configuration.getRvPrimerColor(1)}
        configuration={configuration}
        end={reverse.end}
        key={reverse.start + "-rv-primer-info"}
        label="gene rv primer"
        sequence={sequence}
        start={reverse.start}
      />,
    );
  }

  data.blocks.map((block, currentBlockIndex) => {
    const startPosition = block.start;
    const endPosition = block.end;
    const blockWidth = (endPosition - startPosition) * nuclWidth;
    const leftOffset = startPosition * nuclWidth;

    // bg colors
    blockBgColors.push(
      <BlockBgColors
        bgColor={configuration.getColor(
          currentBlockIndex,
          activeBlockIndex === currentBlockIndex ? 1 : undefined,
        )}
        color={configuration.getColor(currentBlockIndex)}
        key={currentBlockIndex + "-bg"}
        left={leftOffset}
        width={blockWidth}
        zIndex={activeBlockIndex === currentBlockIndex ? 1 : 0}
      />,
    );

    // controls
    blockControls.push(
      <BlockMouseController
        activeIndex={activeBlockIndex}
        blockIndex={currentBlockIndex}
        className="absolute top-0 h-full rounded"
        endPosition={endPosition}
        index={currentBlockIndex}
        key={currentBlockIndex + "-mouse"}
        left={leftOffset}
        setActiveIndex={setActiveBlock}
        showLeftControl={currentBlockIndex > 0}
        showRightControl={currentBlockIndex < data.blocks.length - 1}
        startPosition={startPosition}
        type="block"
        width={blockWidth}
      />,
    );

    // overlap
    if (data.blocks[currentBlockIndex - 1]) {
      const overlapStartPos = block.start;
      const overlapEndPos =
        data.blocks[currentBlockIndex - 1]?.end ?? block.start;
      const overlapLength = overlapEndPos - overlapStartPos;
      const overlapWidth = overlapLength * nuclWidth;
      const overlapLeftOffest = overlapStartPos * nuclWidth;
      overlapBlocks.push(
        <BlockOverlap
          color={configuration.getOverlapColor(1)}
          key={currentBlockIndex + "-overlap"}
          left={overlapLeftOffest}
          width={overlapWidth}
        />,
      );
    }
  });

  return {
    blockBgColors,
    blockControls,
    forwardPrimersBlockInfo,
    oligosBgColors,
    overlapBlocks,
    primerBlocks: primerLines,
    reversePrimersBlockInfo,
  };
};
