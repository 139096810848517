import { Heading } from "@radix-ui/themes";

import { EditableText } from "../../../../../components/logic/editable-text";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function WorkflowStepName({
  workflowStepId,
  currentName,
  isEditable,
}: {
  currentName: string;
  isEditable: boolean;
  workflowStepId: string;
}) {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: updateName } = trpc.assay.steps.rename.useMutation({
    onError(e) {
      const message = e.data?.zodError ?? e.message;
      toast({
        description: message,
        title: "Error renaming step",
        variant: "destructive",
      });
    },
    onSuccess(_, variables) {
      utils.assay.steps.get.setData(workflowStepId, (prev) => {
        if (!prev) {
          return undefined;
        }
        return {
          ...prev,
          name: variables.name,
        };
      });
    },
  });
  const handleUpdateName = (newName: string) => {
    if (!workflowStepId) {
      return;
    }
    updateName({
      id: workflowStepId,
      name: newName,
    });
  };

  return (
    <Heading className="flex flex-row items-center space-x-2">
      <EditableText
        defaultValue={currentName ?? ""}
        disabled={!isEditable}
        onSubmit={handleUpdateName}
      />
    </Heading>
  );
}
