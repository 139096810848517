import { PlateSize } from "@console/shared";

import {
  ABILegend,
  ConcentrationLegend,
  MeasuredConcentrationLegend,
  ModificationLegend,
  OP2Legend,
  OrderLegend,
  PurityLegend,
  SequenceLegend,
  VolumeLegend,
  YieldLegend,
} from "./legends";
import type { PlateKit, WorkflowWell } from "./types";
import {
  getABIColors,
  getColorsFromPurity,
  getConcentrationColors,
  getLengthColors,
  getModificationColors,
  getOP2Colors,
  getOrderColors,
  getVolumeColors,
  getYieldColors,
} from "./useGetWellBackgroundColor";
import {
  ConcentrationWellInside,
  LengthWellInside,
  MeasuredConcentrationInside,
  ModificationInside,
  OrderInside,
  PurityInside,
  QCABINInside,
  QCConcentrationWellInside,
  QCOP2NInside,
  QCRunningMassInside,
  QCVolumeInside,
  QCYieldInside,
  VolumeInside,
  YieldInside,
} from "./well-inside";

export enum DisplayType {
  base = "base",
  qc = "qc",
  result = "result",
}

export enum Display {
  "concentration" = "concentration",
  "length" = "length",
  "measuredConcentration" = "measuredConcentration",
  "modification" = "modification",
  "order" = "order",
  "purity" = "purity",
  "qc_abi_n" = "qc_abi_n",
  "qc_concentration" = "qc_concentration",
  "qc_op2_n" = "qc_op2_n",
  "qc_volume" = "qc_volume",
  "qc_yield" = "qc_yield",
  "qc_yield_initial" = "qc_yield_initial",
  "volume" = "volume",
  "yield" = "yield",
}

export const DISPLAYS = Object.values(Display);

export const DISPLAY_PROPERTIES: Record<
  Display,
  {
    Legend: React.FC<{ kitProperties: PlateKit }>;
    WellInside: React.FC<{ iconSize: number; well: WorkflowWell }>;
    displayType?: DisplayType;
    getWellColor: (
      well: WorkflowWell,
      kitProperties: PlateKit,
      getWellOrderBgColor: (wellId: string) => string | undefined,
    ) => {
      background: string;
      text: string;
      useGradient?: {
        gradient: string;
        position: number;
      };
    };
    label: string;
    unit?: string;
  }
> = {
  concentration: {
    Legend: ConcentrationLegend,
    WellInside: ConcentrationWellInside,
    displayType: DisplayType.base,
    getWellColor: (well, kitProperties) =>
      getConcentrationColors(
        well.expectedConcentration,
        kitProperties.concentration,
      ),
    label: "Concentration (target)",
    unit: "μM",
  },
  length: {
    Legend: SequenceLegend,
    WellInside: LengthWellInside,
    displayType: DisplayType.base,
    getWellColor: (well) => getLengthColors(well),
    label: "Length",
  },
  measuredConcentration: {
    Legend: MeasuredConcentrationLegend,
    WellInside: MeasuredConcentrationInside,
    displayType: DisplayType.result,
    getWellColor: (well, kitProperties) =>
      getConcentrationColors(
        well.result?.concentrationPostNormalization,
        kitProperties.concentration,
      ),
    label: "Concentration (actual)",
    unit: "μM",
  },
  modification: {
    Legend: ModificationLegend,
    WellInside: ModificationInside,
    displayType: DisplayType.result,
    getWellColor: (well) => getModificationColors(well),
    label: "Modifications",
  },
  order: {
    Legend: OrderLegend,
    WellInside: OrderInside,
    displayType: DisplayType.base,
    getWellColor: (well, _, getWellOrderBgColor) =>
      getOrderColors(well, getWellOrderBgColor),
    label: "Order",
    unit: "",
  },
  purity: {
    Legend: PurityLegend,
    WellInside: PurityInside,
    displayType: DisplayType.base,
    getWellColor: (well) => getColorsFromPurity(well.purityFlag),
    label: "Score",
  },
  qc_abi_n: {
    Legend: ABILegend,
    WellInside: QCABINInside,
    displayType: DisplayType.qc,
    getWellColor: (well) => getABIColors(well.qc?.abi?.n),
    label: "ABI %n",
    unit: "%",
  },
  qc_concentration: {
    Legend: MeasuredConcentrationLegend,
    WellInside: QCConcentrationWellInside,
    displayType: DisplayType.qc,
    getWellColor: (well, kitProperties) =>
      getConcentrationColors(
        well.qc?.concentration,
        kitProperties.concentration,
      ),
    label: "Final concentration",
    unit: "μM",
  },
  qc_op2_n: {
    Legend: OP2Legend,
    WellInside: QCOP2NInside,
    displayType: DisplayType.qc,
    getWellColor: (well) => getOP2Colors(well.qc?.op2?.n),
    label: "OP2 %n",
    unit: "%",
  },
  qc_volume: {
    Legend: VolumeLegend,
    WellInside: QCVolumeInside,
    displayType: DisplayType.qc,
    getWellColor: (well, kitProperties) =>
      getVolumeColors(well.qc?.volume, kitProperties.volume),
    label: "Running volume",
    unit: "μL",
  },
  qc_yield: {
    Legend: YieldLegend,
    WellInside: QCRunningMassInside,
    displayType: DisplayType.qc,
    getWellColor: (well, kitProperties) =>
      getYieldColors(well.qc?.yield, kitProperties.yields),
    label: "Running mass",
    unit: "pmol",
  },
  qc_yield_initial: {
    Legend: YieldLegend,
    WellInside: QCYieldInside,
    displayType: DisplayType.qc,
    getWellColor: (well, kitProperties) =>
      getYieldColors(well.qc?.initialYield, kitProperties.yields),
    label: "Final yield",
    unit: "pmol",
  },
  volume: {
    Legend: VolumeLegend,
    WellInside: VolumeInside,
    displayType: DisplayType.result,
    getWellColor: (well, kitProperties) =>
      getVolumeColors(well.result?.totalVolume, kitProperties.volume),
    label: "Volume",
    unit: "μL",
  },
  yield: {
    Legend: YieldLegend,
    WellInside: YieldInside,
    displayType: DisplayType.result,
    getWellColor: (well, kitProperties) =>
      getYieldColors(well.result?.yield, kitProperties.yields),
    label: "Yield",
    unit: "pmol",
  },
};

export const DISPLAY_COLUMNS_AND_ROWS: Record<
  PlateSize,
  { columns: number; rows: number }
> = {
  [PlateSize.S384]: { columns: 25, rows: 17 },
  [PlateSize.S96]: { columns: 13, rows: 9 },
};
