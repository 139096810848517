import { Heading } from "@radix-ui/themes";
import { Dna } from "lucide-react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { QuickPlateUploadButton } from "./assay/quick-plate-upload";

import {
  CardBody,
  CardContainer,
  CardItem,
} from "../../../components/ui/3d-card";
import { Button } from "../../../components/ui/button";
import { Separator } from "../../../components/ui/separator";
import { useUser } from "../../../containers/hooks";
import { useIsServiceEnabled } from "../../../containers/user/hooks";
import { KitStandard } from "../../icons";
import { OrganizationRoutes } from "../organization-routes";

export default function Home() {
  const user = useUser();
  const navigate = useNavigate();
  const isService = useIsServiceEnabled();
  if (!user?.membership) {
    return (
      <div>
        <Heading>Home page</Heading>
        <Heading size="4">Not a member of any organization</Heading>
      </div>
    );
  }

  if (isService) {
    return <Navigate to={OrganizationRoutes.SERVICE} />;
  }

  const firstname = user?.firstname;

  return (
    <div className="mt-10 flex flex-col text-center">
      <Heading>{`Hi ${firstname}! Welcome back to the Console Dashboard`}</Heading>
      <div className="grid grid-cols-3 gap-4">
        <CardContainer className="inter-var col-span-1">
          <CardBody className="group/card bg-card flex h-[25rem] w-auto flex-col justify-between border border-black/[0.1] p-6 ">
            <div
              className="cursor-pointer"
              onClick={() => navigate(OrganizationRoutes.LIBRARY)}
            >
              <CardItem
                className="flex flex-row items-center space-x-2 text-xl font-bold text-neutral-600"
                translateZ="50"
              >
                <span>Constructs</span>
                <Dna />
              </CardItem>
              <CardItem
                as="p"
                className="mt-2 max-w-sm text-sm text-neutral-500"
                translateZ="60"
              >
                Create a new construct design or view existing ones in the
                library
              </CardItem>
              <CardItem
                as="p"
                className="mb-2 text-sm font-bold"
                translateZ="60"
              >
                Oligos
              </CardItem>
              <CardItem
                className="grid w-full grid-cols-4 justify-items-end gap-1 px-4 text-xs"
                translateZ={100}
              >
                <p className="col-span-2 ">CAGATCCAGT</p>
                <p className="col-span-1">oligo-1</p>
                <p className="col-span-1">A1</p>
                <Separator className="col-span-4" />
                <p className="col-span-2 col-end-auto">CGATCTAGCTAGCTAGCT</p>
                <p className="col-span-1">oligo-2</p>
                <p className="col-span-1">A2</p>
                <Separator className="col-span-4" />
                <p className="col-span-2 col-end-auto">AAACACGATCCGT</p>
                <p className="col-span-1">primer-1</p>
                <p className="col-span-1">B1</p>
                <Separator className="col-span-4" />
              </CardItem>
            </div>
            <div className="flex items-center justify-between">
              <CardItem
                as={Link}
                className="px-4 py-2 text-xs font-normal hover:underline"
                to={OrganizationRoutes.LIBRARY}
                translateZ={20}
              >
                Go to library →
              </CardItem>
              <CardItem
                as={Button}
                className="px-4 py-2 text-xs font-bold text-white"
                onClick={() => navigate(OrganizationRoutes.CONSTRUCT_NEW)}
                translateZ={20}
                variant="default"
              >
                New construct
              </CardItem>
            </div>
          </CardBody>
        </CardContainer>
        <CardContainer className="inter-var col-span-1">
          <CardBody className="group/card bg-card flex h-[25rem] w-auto flex-col justify-between border border-black/[0.1] p-6 ">
            <div
              className="cursor-pointer"
              onClick={() => navigate(OrganizationRoutes.ASSAYS)}
            >
              <CardItem
                className="flex flex-row space-x-2 text-xl font-bold text-neutral-600"
                translateZ="50"
              >
                <span>Assays</span>
                <KitStandard className="w-[60px]" />
              </CardItem>
              <CardItem
                as="p"
                className="max-w-sm text-sm text-neutral-500"
                translateZ="60"
              >
                View or create new assays to organize your experiments
              </CardItem>
              <CardItem
                className="mt-2 flex w-full justify-center"
                translateZ={100}
              >
                <img className="w-[80%] object-contain " src="/wellplate.svg" />
              </CardItem>
            </div>
            <div className="flex items-center justify-between">
              <CardItem
                as={Link}
                className="px-4 py-2 text-xs font-normal hover:underline"
                to={OrganizationRoutes.ASSAYS}
                translateZ={20}
              >
                View list →
              </CardItem>
              <div className="flex flex-row space-x-1">
                <CardItem
                  as={QuickPlateUploadButton}
                  className="px-4 py-2 text-xs font-bold text-white"
                  onClick={() => navigate(OrganizationRoutes.ASSAY_NEW)}
                  translateZ={20}
                  variant="default"
                >
                  New assay
                </CardItem>
                <CardItem
                  as={Button}
                  className="px-4 py-2 text-xs font-bold text-white"
                  onClick={() => navigate(OrganizationRoutes.ASSAY_NEW)}
                  translateZ={20}
                  variant="default"
                >
                  New assay
                </CardItem>
              </div>
            </div>
          </CardBody>
        </CardContainer>
        <CardContainer className="inter-var col-span-1">
          <CardBody className="group/card bg-card flex h-[25rem] w-auto flex-col justify-between border border-black/[0.1] p-6  ">
            <div
              className="cursor-pointer"
              onClick={() => navigate(OrganizationRoutes.INSTRUMENTS)}
            >
              <CardItem
                className="text-xl font-bold text-neutral-600"
                translateZ="50"
              >
                Instruments
              </CardItem>
              <CardItem
                as="p"
                className="mt-2 max-w-sm text-sm text-neutral-500"
                translateZ="60"
              >
                Manage your fleet of Syntax instruments
              </CardItem>
              <CardItem className="mt-4 w-full" translateZ={100}>
                <img className="w-full object-contain " src="/stx-200.png" />
              </CardItem>
            </div>
            <div className="flex items-center justify-between">
              <CardItem
                as={Link}
                className="px-4 py-2 text-xs font-normal hover:underline"
                to={OrganizationRoutes.INSTRUMENTS}
                translateZ={20}
              >
                View list →
              </CardItem>
              <CardItem
                as={Button}
                className="px-4 py-2 text-xs font-bold text-white"
                onClick={() =>
                  navigate(OrganizationRoutes.INSTRUMENT_SYNTAX_NEW)
                }
                translateZ={20}
                variant="default"
              >
                Install new
              </CardItem>
            </div>
          </CardBody>
        </CardContainer>
      </div>
    </div>
  );
}
