import {
  minOligoLength,
  maxOligoLength,
  minimumOligosPerPlate,
} from "@console/shared";

export default function RequestInstructions() {
  return (
    <div>
      <h3 className="font-semibold">Instructions</h3>
      <div className="text-sm">
        <ol className="list-decimal pl-4">
          <li>
            Copy and paste or upload your oligo sequences using the templates
            below (.csv, .fasta, .excel)
          </li>
          <li>
            {`Check that your sequences are within DNA Script's specifications (see below) by clicking the "Check sequences" button. If an error is flagged, modify your sequence accordingly.`}
          </li>
          <li>
            {`Once all your sequences are validated, download the excel request
              form by clicking on "Download excel request" and attach it to the order form or send it to your
              sales representative to receive a quote.`}
          </li>
        </ol>
        {/* <p>
          <h4 className="font-semibold">Gene fragment specifications</h4>
          <ul className="pl-4">
            <li>{`Sequence length range accepted: ${minGeneLength}nt - ${maxGeneLength / 1000}knt`}</li>
            <li>{`Fewer than ${maxGenes} gene fragments per order`}</li>
            <li>Only DNA (no uracil, no amino acids)</li>
            <li>No degenerate bases accepted</li>
          </ul>
        </p> */}
        <p>
          <h4 className="font-semibold">Oligos specifications</h4>
          <ul className="pl-4">
            <li>{`Sequence length range accepted: ${minOligoLength}nt – ${maxOligoLength}nt`}</li>
            <li>{`Fewer than ${maxOligoLength} oligos per order`}</li>
            <li>{`When requesting plate shipping, a minimum of ${minimumOligosPerPlate} oligos`}</li>
            <li>{"Only DNA (no uracil, no amino acids)"}</li>
            <li>No degenerate bases accepted</li>
            {/* <li>{"Up to 2 degenerate bases accepted"}</li> */}
          </ul>
        </p>
        <p>
          Either manually enter the sequences or upload file(s) (download file
          templates below).
        </p>
      </div>
    </div>
  );
}
