import { z } from "zod";

const rangeSchema = z
  .object({
    max: z.number(),
    min: z.number(),
  })
  .refine((range) => range.min < range.max, "Min must be less than max");

export const geneDesignParametersSchema = z.object({
  blocks: z.object({
    oligos: z.object({
      overlap: rangeSchema,
      size: rangeSchema,
    }),
    overlap: rangeSchema,
    primers: z.object({
      size: rangeSchema,
    }),
    size: rangeSchema,
  }),
  primers: z.object({
    size: rangeSchema,
  }),
});
export type GeneDesignParameters = z.infer<typeof geneDesignParametersSchema>;

export function getOligosOverlapsPositions(
  oligos: { direction: OligoDirectionType; end: number; start: number }[],
): {
  firstForwardStartPos: number;
  firstReversStartPos: number;
  lastForwardEndPos: number;
  lastReversEndPos: number;
  overlaps: number[];
} {
  let firstForwardStartPos = -1;
  let lastForwardEndPos = -1;
  let firstReversStartPos = -1;
  let lastReversEndPos = -1;
  const overlaps = Array.from(
    oligos.reduce((acc, oligo) => {
      if (oligo.direction === "forward") {
        if (firstForwardStartPos < 0) {
          firstForwardStartPos = oligo.start;
        }
        lastForwardEndPos = oligo.end;
      } else if (oligo.direction === "reverse") {
        if (firstReversStartPos < 0) {
          firstReversStartPos = oligo.start;
        }
        lastReversEndPos = oligo.end;
      }
      acc.add(oligo.start);
      acc.add(oligo.end);
      return acc;
    }, new Set() as Set<number>),
  )
    .filter(
      (pos) =>
        pos >= Math.max(firstForwardStartPos, firstReversStartPos) &&
        pos <= Math.min(lastReversEndPos, lastForwardEndPos),
    )
    .sort((a, b) => a - b);

  return {
    firstForwardStartPos,
    firstReversStartPos,
    lastForwardEndPos,
    lastReversEndPos,
    overlaps,
  };
}

const defaultRange = {
  max: Infinity,
  min: 0,
};
export const defaultGeneDesignParameters: GeneDesignParameters = {
  blocks: {
    oligos: {
      overlap: defaultRange,
      size: defaultRange,
    },
    overlap: defaultRange,
    primers: {
      size: defaultRange,
    },
    size: defaultRange,
  },
  primers: {
    size: defaultRange,
  },
};

type GeneDesignBlockParameters = GeneDesignParameters["blocks"];
type GeneDesignOligoParameters = GeneDesignBlockParameters["oligos"];
type GeneDesignPrimerParameters = GeneDesignParameters["primers"];

const oligoDirectionSchema = z.enum(["forward", "reverse"]);

const oligoSchema = (parameters: GeneDesignOligoParameters) =>
  z
    .object({
      direction: oligoDirectionSchema,
      end: z.number(),
      name: z.string(),
      start: z.number().min(0),
    })
    .refine(
      (oligo) => oligo.start < oligo?.end,
      "Oligo start must be less than end",
    )
    .refine(
      (oligo) => oligo?.end - oligo.start >= parameters.size.min,
      `Oligo must be at least ${parameters.size.min} bp long`,
    )
    .refine(
      (oligo) => oligo?.end - oligo.start <= parameters.size.max,
      `Oligo must be at most ${parameters.size.max} bp long`,
    );

const primerSchema = (parameters: GeneDesignPrimerParameters) =>
  z
    .object({
      end: z.number(),
      name: z.string(),
      start: z.number().min(0),
    })
    .refine(
      (primer) => primer.start < primer?.end,
      "Primer start must be less than end",
    )
    .refine(
      (primer) => Math.abs(primer?.end - primer.start) >= parameters.size.min,
      `Primer must be at least ${parameters.size.min} bp long`,
    )
    .refine(
      (primer) => Math.abs(primer?.end - primer.start) <= parameters.size.max,
      `Primer must be at most ${parameters.size.max} bp long`,
    );

const blockSchema = (parameters: GeneDesignBlockParameters) =>
  z
    .object({
      end: z.number(),
      name: z.string(),
      oligos: z.array(oligoSchema(parameters.oligos)).min(1),
      primers: z
        .object({
          forward: primerSchema(parameters.primers),
          reverse: primerSchema(parameters.primers),
        })
        .optional(),
      start: z.number().min(0),
    })
    .refine(
      (block) => block.start < block.end,
      "Block start must be less than end",
    )
    .refine(
      (block) => block.end - block.start >= parameters.size.min,
      `Block must be at least ${parameters.size.min} bp long`,
    )
    .refine(
      (block) => block.end - block.start <= parameters.size.max,
      `Block must be at most ${parameters.size.max} bp long`,
    )
    // check primer positions, if they exist
    .refine(
      (block) =>
        block.primers ? block.primers.forward?.start === block.start : true,
      "Forward primer position must be at the start of the block",
    )
    .refine(
      (block) =>
        block.primers ? block.primers.reverse.end === block.end : true,
      "Reverse primer position must be at the end of the block",
    )
    // check oligo name uniqueness
    .refine((block) => {
      const names = block.oligos.map((oligo) => oligo.name);
      return new Set(names).size === names.length;
    }, "Oligo names in block must be unique")
    // check oligo overlap
    .superRefine((block, ctx) => {
      const { overlaps } = getOligosOverlapsPositions(
        block.oligos.map((o) => ({
          direction: o.direction ?? "forward",
          end: o.end ?? -1,
          start: o.start ?? -1,
          name: o.name ?? "",
        })),
      );
      for (let index = 0; index < overlaps.length - 2; index++) {
        if (overlaps[index + 1] === undefined) return;

        const overlapLength = overlaps[index + 1] - overlaps[index];
        if (
          overlapLength < parameters.oligos.overlap.min ||
          overlapLength > parameters.oligos.overlap.max
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Oligo overlaps must be between ${parameters.oligos.overlap.min} and ${parameters.oligos.overlap.max}`,
            path: ["oligos", "overlap", overlaps[index]],
          });
        }
      }
    });

export const getGeneDesignSchema = (parameters: GeneDesignParameters) =>
  z
    .object({
      blocks: z.array(blockSchema(parameters.blocks)).min(1),
      gene: z.object({
        name: z.string(),
        sequence: z.string(),
      }),
      primers: z.object({
        forward: primerSchema(parameters.primers),
        reverse: primerSchema(parameters.primers),
      }),
    })
    .refine(
      (design) =>
        design.blocks[0].start === 0 &&
        design.blocks[design.blocks.length - 1]?.end ===
          design.gene.sequence.length,
      "Block positions must start at 0 and end at the length of the gene",
    )
    // check primer positions
    .refine(
      (design) =>
        design.primers.forward.start === 0 &&
        design.primers.reverse?.end ===
          design.blocks[design.blocks.length - 1]?.end,
      "Primer positions must be at the start and end of the gene",
    )
    // check block name uniqueness
    .refine((design) => {
      const names = design.blocks.map((block) => block.name);
      return new Set(names).size === names.length;
    }, "Block names must be unique")
    // check block overlap
    .superRefine((design, ctx) => {
      design.blocks.forEach((block, index) => {
        const nextBlock = design.blocks[index + 1];
        if (nextBlock) {
          const overlap = block?.end - nextBlock.start;
          if (
            overlap < parameters.blocks.overlap.min ||
            overlap > parameters.blocks.overlap.max
          ) {
            ctx.addIssue({
              code: "custom",
              message: `Block overlaps must be between ${parameters.blocks.overlap.min} and ${parameters.blocks.overlap.max}`,
              path: ["blocks", index, "overlap"],
            });
          }
        }
      });
    });

export const defaultGeneDesignSchema = getGeneDesignSchema(
  defaultGeneDesignParameters,
);

export type GeneDesignBlock = z.infer<ReturnType<typeof blockSchema>>;
export type GeneDesignOligo = z.infer<ReturnType<typeof oligoSchema>>;
export type GeneDesign = z.infer<ReturnType<typeof getGeneDesignSchema>>;
export type OligoDirectionType = z.infer<typeof oligoDirectionSchema>;
