import { Normalization } from "@console/shared";
import { useEffect, useState } from "react";

import { Label } from "../../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../../components/ui/radio";
import { cn } from "../../../../../lib/utils";
import TargetConcentration from "../../build/components/plate/target-concentration";

export default function PickConcentration({
  normalization,
  handleApplyConcentrationChange,
  isEditable,
  currentConcentration,
  kitConcentrationMax,
  kitConcentrationMin,
}: {
  currentConcentration: number;
  handleApplyConcentrationChange: (
    normalization: Normalization,
    concentration?: number,
  ) => void;
  isEditable: boolean;
  kitConcentrationMax: number;
  kitConcentrationMin: number;
  normalization: Normalization;
}) {
  const [localConcentration, setLocalConcentration] = useState<number[]>([
    currentConcentration,
  ]);

  useEffect(() => {
    setLocalConcentration([currentConcentration]);
  }, [currentConcentration]);

  const isTargetNormalization = normalization === Normalization.Target;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isTargetNormalization) {
      timeout = setTimeout(() => {
        handleApplyConcentrationChange(
          Normalization.Target,
          localConcentration[0],
        );
      }, 200);
    }
    return () => clearTimeout(timeout);
  }, [
    handleApplyConcentrationChange,
    isTargetNormalization,
    localConcentration,
  ]);

  const displayConcentrationSlider = isEditable && isTargetNormalization;

  return (
    <div className={cn("space-y-2", displayConcentrationSlider && "pb-4")}>
      <div
        className={cn(
          isEditable
            ? "flex flex-row items-center justify-between space-x-4"
            : "grid grid-cols-2",
        )}
      >
        <p className="font-medium">Normalization</p>
        {isEditable ? (
          <div className="justify-end">
            <RadioGroup
              className="flex flex-row gap-2"
              onValueChange={(v) => {
                const newNormalization = v as Normalization;
                handleApplyConcentrationChange(
                  newNormalization,
                  newNormalization === Normalization.Target
                    ? kitConcentrationMin
                    : undefined,
                );
              }}
              value={normalization}
            >
              {Object.values(Normalization).map((n) => {
                return (
                  <div className="flex items-center space-x-1" key={n}>
                    <RadioGroupItem id={n} value={n} />
                    <Label htmlFor={n}>{n}</Label>
                  </div>
                );
              })}
            </RadioGroup>
          </div>
        ) : (
          <span className="font-medium">
            {normalization !== Normalization.Target
              ? normalization
              : currentConcentration.toString()}
          </span>
        )}
      </div>
      {displayConcentrationSlider && (
        <div className="space-y-2">
          <p className="font-medium">Concentration (Target)</p>
          <TargetConcentration
            handleApplyConcentrationChange={handleApplyConcentrationChange}
            isEditable={isEditable}
            localConcentration={localConcentration}
            maxConcentration={kitConcentrationMax}
            minConcentration={kitConcentrationMin}
            setLocalConcentration={setLocalConcentration}
          />
        </div>
      )}
    </div>
  );
}
