import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function useReplicateOligo() {
  const utils = trpc.useUtils();
  const { toast } = useToast();
  const { mutate: replicateOligo } = trpc.order.replicateOligo.useMutation({
    onSuccess(newOligo, oligoId) {
      toast({
        title: "Oligo replicated",
        variant: "success",
      });
      utils.order.oligos.setData(undefined, (prev) => {
        if (!prev) {
          return undefined;
        }
        const prevWithNewCount = prev.map((o) =>
          o.itemId === newOligo.itemId
            ? { ...o, replicates: o.replicates + 1 }
            : o,
        );
        const index = prev.findIndex((oligo) => oligo.id === oligoId);
        return [
          ...prevWithNewCount.slice(0, index + 1),
          newOligo,
          ...prevWithNewCount.slice(index + 1),
        ];
      });
    },
  });
  return replicateOligo;
}
