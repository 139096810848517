import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";

import CreateQCPlate from "./create-qc-plate";
import QCPlateActions from "./qc-plate-actions";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card";
import DownloadFile from "../../../../../../components/ui/download-file";
import { SimpleTable } from "../../../../../../components/ui/simple-table";
import type { AppRouter } from "../../../../../../config/trpc";
import { trpc } from "../../../../../../config/trpc";
import { SimpleStorageLocation } from "../../../build/components/plate/storage-location";
import type { PlateFromTRPC } from "../../../build/components/plate/types";

type QCPlate = inferProcedureOutput<
  AppRouter["order"]["productionPlates"]["listQCPlates"]
>[number];

const columnHelper = createColumnHelper<QCPlate>();

const QCStorageLocation = ({
  plateId,
  storageLocation,
  parentPlateId,
}: {
  parentPlateId: string;
  plateId: string;
  storageLocation?: string | undefined;
}) => {
  const utils = trpc.useUtils();
  const onSuccess = (newStorage: string) => {
    utils.order.productionPlates.listQCPlates.setData(parentPlateId, (prev) => {
      if (!prev) return prev;
      return prev.map((plate) => {
        if (plate.id !== plateId) return plate;
        return {
          ...plate,
          storageLocation: newStorage,
        };
      });
    });
  };
  return (
    <SimpleStorageLocation
      onSuccess={onSuccess}
      plateId={plateId}
      storageLocation={storageLocation}
    />
  );
};

const plateEventsColumns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("createdAt", {
    cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
    header: "Creation",
  }),
  columnHelper.accessor("createdBy", {
    cell: (info) => info.getValue(),
    header: "Created by",
  }),
  columnHelper.accessor("abiUrl", {
    cell: (info) => <DownloadFile url={info.getValue()} />,
    header: "ABI file",
  }),
  columnHelper.accessor("op2Url", {
    cell: (info) => <DownloadFile url={info.getValue()} />,
    header: "OP2 Synthesis file",
  }),
  columnHelper.display({
    cell: ({ row }) => (
      <QCStorageLocation
        parentPlateId={row.original.parentPlateId}
        plateId={row.original.id}
        storageLocation={row.original.storageLocation}
      />
    ),
    header: "Storage location",
    id: "storageLocation",
  }),
  columnHelper.display({
    cell: ({ row }) => (
      <QCPlateActions id={row.original.id} plateName={row.original.name} />
    ),
    id: "actions",
  }),
];

export default function QCPlates({ plate }: { plate: PlateFromTRPC }) {
  const { data } = trpc.order.productionPlates.listQCPlates.useQuery(plate.id, {
    enabled: !plate.stepId,
  });

  const qcPlatesTable = useReactTable({
    columns: plateEventsColumns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  if (plate.stepId) {
    return null;
  }

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between">
        <CardTitle>QC Plates</CardTitle>
        <CreateQCPlate plate={plate} />
      </CardHeader>
      <CardContent className="max-h-[30vh] overflow-scroll">
        <SimpleTable table={qcPlatesTable} />
      </CardContent>
    </Card>
  );
}
