const defaultBgOpacity = 0.3;
export const GeneDesignConfiguration = {
  getColor: (colorPosition: number, opacity = defaultBgOpacity) => {
    const availableColors = [
      `rgba(213, 186, 255, ${opacity})`,
      `rgba(255, 204, 119, ${opacity})`,
      `rgba(169, 193, 255, ${opacity})`,
      `rgba(244, 166, 166, ${opacity})`,
      `rgba(189, 211, 139, ${opacity})`,
    ];
    return availableColors[colorPosition % availableColors.length];
  },
  getFvPrimerColor: (opacity = defaultBgOpacity) =>
    `rgba(162, 181, 120, ${opacity})`,

  getOverlapColor: (opacity = defaultBgOpacity) =>
    `rgba(59, 63, 130, ${opacity})`,
  getRvPrimerColor: (opacity = defaultBgOpacity) =>
    `rgba(254, 204, 121, ${opacity})`,
  nuclWidth: 8,
};

export type GeneDesignConfigurationType = typeof GeneDesignConfiguration;
