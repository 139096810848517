import type { AdditionalService, RequestFormType } from "@console/shared";
import { SequenceShippingFormat } from "@console/shared";
import { Download, Check } from "lucide-react";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "../../components/ui/button";
import { Progress } from "../../components/ui/progress";
import { trpc } from "../../config/trpc";
import { cn } from "../../lib/utils";
import { downloadBase64Excel } from "../../utils/useDownload";

function processFormSequence(formSequence: {
  additionalServices?: AdditionalService[] | undefined;
  format?: SequenceShippingFormat | undefined;
  name?: string | undefined;
  sequence?: string | undefined;
}) {
  return {
    format: formSequence.format ?? SequenceShippingFormat.Plate,
    name: formSequence.name ?? "",
    sequence: formSequence.sequence ?? "",
  };
}

export default function DownloadExcelAndVerify({
  isVerified,
  isRemoteChecking,
  progress,
  setIsVerified,
}: {
  isRemoteChecking: boolean;
  isVerified: boolean;
  progress: number;
  setIsVerified: (value: boolean) => void;
}) {
  const form = useFormContext<RequestFormType>();
  const formValue = useWatch({
    control: form.control,
  });
  const formString = JSON.stringify(formValue);

  useEffect(() => {
    if (!isVerified) {
      return;
    }
    setIsVerified(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formString]);

  const { mutate: generateExcelFile } =
    trpc.screening.generateExcelFile.useMutation({
      onSuccess(data) {
        downloadBase64Excel(data, "request.xlsx");
      },
    });

  const handleDownload = () => {
    generateExcelFile({
      geneFragments: (formValue.geneFragments ?? []).map(processFormSequence),
      oligos: (formValue.oligos ?? []).map(processFormSequence),
    });
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      <Button
        className="space-x-1"
        disabled={!isVerified}
        onClick={handleDownload}
        type="button"
      >
        <Download />
        <span>Download excel request</span>
      </Button>
      <Button
        className={cn("space-x-1", isVerified && "text-green-500")}
        isLoading={form.formState.isValidating || isRemoteChecking}
        type="submit"
        variant={"outline"}
      >
        <Check />
        <span>{isVerified ? "Checked" : "Check sequences"}</span>
      </Button>
      {isRemoteChecking && (
        <Progress indicatorClassName="bg-green-400" value={progress} />
      )}
    </div>
  );
}
