import { Heading } from "@radix-ui/themes";
import type { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { NewGeneDesign } from "./components/new-design";

import { CardContent, Card } from "../../../../components/ui/card";
import { trpc } from "../../../../config/trpc";
import { OrganizationRoutes } from "../../organization-routes";

const NewGeneDesignPage: FC = () => {
  const { constructId } = useParams();
  const navigate = useNavigate();

  const { data: construct, isPending: isConstructQueryPending } =
    trpc.construct.get.useQuery(
      {
        id: constructId ?? "",
      },
      {
        enabled: !!constructId,
      },
    );

  if (isConstructQueryPending) {
    return <>Loading...</>;
  }

  if (!construct) {
    return <>Construct {constructId} not found</>;
  }

  if (construct.constructType !== "Gene" || !construct.gene) {
    return <>Construct {constructId} is not a gene</>;
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between pb-4">
        <Heading>New Gene Design</Heading>
      </div>
      <Card className="pt-6">
        <CardContent>
          <CardContent>
            <NewGeneDesign
              designName={construct?.name ?? ""}
              geneId={construct?.gene?.id ?? ""}
              onSuccess={(designId) => {
                navigate(
                  OrganizationRoutes.CONSTRUCT_GENE_DESIGN_EDIT.replace(
                    ":constructId",
                    constructId ?? "",
                  ).replace(":designId", designId),
                );
              }}
            />
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewGeneDesignPage;
