import { useEffect, useRef, useState } from "react";

import type { GeneDesignConfigurationType } from "../logic/configuration";
import { GeneDesignActionType } from "../logic/reducer";
import type { UpdateFunctionType } from "../logic/reducer";

function getLeftScrollInPercentage(target: HTMLDivElement | null): number {
  if (!target) {
    return 0;
  }

  return (target.scrollLeft / (target.scrollWidth - target.clientWidth)) * 100;
}

function getOligosLeftScrollInPercentage(
  sourceElem: HTMLDivElement | null,
  targetElem: HTMLDivElement | null,
  startWidth: number,
): number {
  if (!sourceElem || !targetElem) {
    return 0;
  }

  const leftPercentage = getLeftScrollInPercentage(sourceElem);

  const oligosEndWidth =
    startWidth + (targetElem.scrollWidth ?? 0) - (targetElem.clientWidth ?? 0);

  const oligosStartInPercentage =
    (startWidth / (sourceElem.scrollWidth - sourceElem.clientWidth)) * 100;
  const oligosEndInPercentage =
    (oligosEndWidth / (sourceElem.scrollWidth - sourceElem.clientWidth)) * 100;

  const oligoScrollLeftPercentage =
    ((leftPercentage - oligosStartInPercentage) * 100) /
    (oligosEndInPercentage - oligosStartInPercentage);

  return oligoScrollLeftPercentage;
}

function setElementScrollLeftByPercentage(
  target: HTMLDivElement | null,
  percentage: number,
) {
  if (!target) {
    return;
  }

  target?.scrollTo({
    behavior: "instant",
    left: (percentage * (target.scrollWidth - target.clientWidth)) / 100,
  });
}

export function useGeneDesignScroll(
  startPosition: number,
  configuration: GeneDesignConfigurationType,
) {
  const [init, setInit] = useState(false);

  // scroll behaviour
  const previewScrollRef = useRef<HTMLDivElement>(null);
  const isPreviewScrolling = useRef(false);

  const blocksScrollRef = useRef<HTMLDivElement>(null);
  const isBlockScrolling = useRef(false);

  const oligosScrollRef = useRef<HTMLDivElement>(null);
  const isOligosScrolling = useRef(false);

  const scrollPreviewToPosition = (scrollLeft: number) => {
    if (previewScrollRef.current) {
      const leftPercentage =
        (scrollLeft * 100) /
        (previewScrollRef.current.scrollWidth -
          previewScrollRef.current.clientWidth);
      setElementScrollLeftByPercentage(
        previewScrollRef.current,
        leftPercentage,
      );
    }
  };

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    if (!init) {
      return;
    }

    let timeout = 0;
    if (previewScrollRef.current) {
      const handlePreviewScroll = (e: Event) => {
        if (isBlockScrolling.current || isOligosScrolling.current) {
          return;
        }
        isPreviewScrolling.current = true;

        const target = e.target as HTMLDivElement;

        const leftPercentage = getLeftScrollInPercentage(target);
        setElementScrollLeftByPercentage(
          blocksScrollRef.current,
          leftPercentage,
        );

        const oligosStartWidth = startPosition * configuration.nuclWidth;
        const oligoScrollLeftPercentage = getOligosLeftScrollInPercentage(
          target,
          oligosScrollRef.current,
          oligosStartWidth,
        );
        setElementScrollLeftByPercentage(
          oligosScrollRef.current,
          oligoScrollLeftPercentage,
        );

        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
          isPreviewScrolling.current = false;
        }, 100);
      };
      previewScrollRef.current.onscroll = handlePreviewScroll;
    }

    if (blocksScrollRef.current) {
      let timeout = 0;
      const handleScroll = (e: Event) => {
        if (isPreviewScrolling.current || isOligosScrolling.current) {
          return;
        }

        isBlockScrolling.current = true;

        const target = e.target as HTMLDivElement;

        const leftPercentage = getLeftScrollInPercentage(target);
        setElementScrollLeftByPercentage(
          previewScrollRef.current,
          leftPercentage,
        );

        const oligosStartWidth = startPosition * configuration.nuclWidth;
        const oligoScrollLeftPercentage = getOligosLeftScrollInPercentage(
          target,
          oligosScrollRef.current,
          oligosStartWidth,
        );
        setElementScrollLeftByPercentage(
          oligosScrollRef.current,
          oligoScrollLeftPercentage,
        );

        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
          isBlockScrolling.current = false;
        }, 100);
      };

      blocksScrollRef.current.onscroll = handleScroll;
    }

    if (oligosScrollRef.current) {
      let timeout = 0;
      const handleScroll = (e: Event) => {
        if (isPreviewScrolling.current || isBlockScrolling.current) {
          return;
        }

        isOligosScrolling.current = true;

        const target = e.target as HTMLDivElement;

        const oligosStartWidth = startPosition * configuration.nuclWidth;

        if (previewScrollRef.current) {
          const oligoScrollLeftInPercentage =
            ((oligosStartWidth + target.scrollLeft) * 100) /
            (previewScrollRef.current.scrollWidth -
              previewScrollRef.current.clientWidth);
          setElementScrollLeftByPercentage(
            previewScrollRef.current,
            oligoScrollLeftInPercentage,
          );
        }

        if (blocksScrollRef.current) {
          const oligoScrollLeftInPercentage =
            ((oligosStartWidth + target.scrollLeft) * 100) /
            (blocksScrollRef.current.scrollWidth -
              blocksScrollRef.current.clientWidth);
          setElementScrollLeftByPercentage(
            blocksScrollRef.current,
            oligoScrollLeftInPercentage,
          );
        }

        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
          isOligosScrolling.current = false;
        }, 100);
      };

      oligosScrollRef.current.onscroll = handleScroll;
    }
  }, [configuration.nuclWidth, init, startPosition]);

  return {
    blocksScrollRef,
    oligosScrollRef,
    previewScrollRef,
    scrollPreviewToPosition,
  };
}

export function useKeyboardController(update: UpdateFunctionType) {
  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      // Check if Ctrl (or Command on Mac) is pressed
      const isCtrlPressed = event.ctrlKey || event.metaKey;

      // Undo (Ctrl+Z or Command+Z)
      if (isCtrlPressed && event.key === "z") {
        event.preventDefault(); // Prevent default action (like browser undo)
        update({ type: GeneDesignActionType.UNDO });
      }

      // Redo (Ctrl+Y or Command+Shift+Z)
      if (isCtrlPressed && event.key === "Z") {
        event.preventDefault();
        update({ type: GeneDesignActionType.REDO });
      }

      // Reset (Custom key combination, e.g., Ctrl+R)
      if (isCtrlPressed && event.key === "r") {
        event.preventDefault();
        update({ type: GeneDesignActionType.RESET });
      }
    };
    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [update]);
}

export function useMouseController(
  update: UpdateFunctionType,
  nuclWidth: number,
) {
  useEffect(() => {
    const mouseDownCallback = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLDivElement &&
        event.target.dataset.blocktype !== undefined
      ) {
        event.preventDefault();
        event.stopPropagation();

        const blockType = event.target.dataset.blocktype as
          | "block"
          | "oligo"
          | "primer";
        const blockIndex = parseInt(event.target.dataset.blockindex ?? "");
        const location = event.target.dataset.location as "start" | "end";
        const oligoIndex = parseInt(event.target.dataset.oligoindex ?? "");
        const currentPosition = parseInt(
          event.target.dataset.currentpos ?? "0",
        );
        const primerType = event.target.dataset.primertype as
          | "forward"
          | "reverse";

        const startX = event.clientX;

        const currrent = currentPosition;
        let tempPos = currrent;

        const mouseMoveCallback = (event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();

          const endX = event.clientX;
          const offset = Math.round((endX - startX) / nuclWidth);

          const newPosition = currrent + offset;

          if (tempPos === newPosition) {
            return;
          }

          switch (blockType) {
            case "block": {
              update({
                payload: {
                  blockIndex,
                  currentPosition: tempPos,
                  location,
                  newPosition,
                },
                type: GeneDesignActionType.UPDATE_BLOCK_POSITION,
              });
              break;
            }
            case "oligo": {
              update({
                payload: {
                  blockIndex,
                  currentPosition: tempPos,
                  location,
                  newPosition,
                  oligoIndex,
                },
                type: GeneDesignActionType.UPDATE_OLIGO_POSITION,
              });
              break;
            }
            case "primer": {
              if (isNaN(blockIndex)) {
                update({
                  payload: {
                    location,
                    newPosition,
                    primerType,
                  },
                  type: GeneDesignActionType.UPDATE_PRIMER_POSITION,
                });
              } else {
                update({
                  payload: {
                    blockIndex,
                    location,
                    newPosition,
                    primerType,
                  },
                  type: GeneDesignActionType.UPDATE_BLOCK_PRIMER_POSITION,
                });
              }
              break;
            }
            default:
              break;
          }

          tempPos = newPosition;
        };

        const mouseUpCallback = (event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();

          update({ type: GeneDesignActionType.ADD_TO_HISTORY_AND_VALIDATE });

          document.removeEventListener("mousemove", mouseMoveCallback);
          document.removeEventListener("mouseup", mouseUpCallback);
        };

        document.addEventListener("mousemove", mouseMoveCallback);
        document.addEventListener("mouseup", mouseUpCallback);
      }
    };
    document.addEventListener("mousedown", mouseDownCallback);

    return () => {
      document.removeEventListener("mousedown", mouseDownCallback);
    };
  }, [nuclWidth, update]);
}
