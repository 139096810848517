import useNewPlateContext from "./useNewPlateContext";

const PrimingTime = 12 * 60;
const PSPTime = 2 * 3600;
const IncubationTimePerMaxNt = 0.1013 * 3600;
const DispenseTimePerNt = 2.9 * 3600 * 1e-4;

export function computeEstimatedRunTime(lengths: number[]) {
  if (!lengths.length) {
    return 0;
  }
  const maxLength = Math.max(...lengths);
  const cycles = lengths.reduce((acc, len) => acc + len, 0);

  const incubationTime = IncubationTimePerMaxNt * maxLength;
  const dispenseTime = DispenseTimePerNt * cycles;

  return PrimingTime + PSPTime + incubationTime + dispenseTime;
}

export default function useComputeEstimatedTime() {
  const { oligos } = useNewPlateContext();

  const lengths = oligos.filter((o) => o.assigment).map((o) => o.size);
  return computeEstimatedRunTime(lengths);
}
