import { OligoStatus } from "@console/shared";

import { Badge } from "../../../../../components/ui/badge";

export const oligoStatusColors: Record<OligoStatus, string> = {
  [OligoStatus.Queued]: "bg-blue-500",
  [OligoStatus.Assigned]: "bg-yellow-500",
  [OligoStatus.Opportunity]: "bg-blue-300",
  [OligoStatus.Printing]: "bg-gray-500",
  [OligoStatus.Synthesized]: "bg-purple-500",
  [OligoStatus.Failed]: "bg-red-500",
  [OligoStatus.Liberated]: "bg-green-500",
  [OligoStatus.PendingQC]: "bg-white",
  [OligoStatus.Canceled]: "bg-black",
};

export const OligoStatusComponent: Record<OligoStatus, React.ReactNode> = {
  [OligoStatus.Opportunity]: (
    <Badge className={oligoStatusColors[OligoStatus.Opportunity]}>
      {OligoStatus.Opportunity}
    </Badge>
  ),
  [OligoStatus.Queued]: (
    <Badge className={oligoStatusColors[OligoStatus.Queued]}>
      {OligoStatus.Queued}
    </Badge>
  ),
  [OligoStatus.Assigned]: (
    <Badge className={oligoStatusColors[OligoStatus.Assigned]}>
      {OligoStatus.Assigned}
    </Badge>
  ),
  [OligoStatus.Printing]: (
    <Badge className={oligoStatusColors[OligoStatus.Printing]}>
      {OligoStatus.Printing}
    </Badge>
  ),
  [OligoStatus.Synthesized]: (
    <Badge className={oligoStatusColors[OligoStatus.Synthesized]}>
      {OligoStatus.Synthesized}
    </Badge>
  ),
  [OligoStatus.Failed]: (
    <Badge className={oligoStatusColors[OligoStatus.Failed]}>
      {OligoStatus.Failed}
    </Badge>
  ),
  [OligoStatus.Liberated]: (
    <Badge className={oligoStatusColors[OligoStatus.Liberated]}>
      {OligoStatus.Liberated}
    </Badge>
  ),
  [OligoStatus.PendingQC]: (
    <Badge className={oligoStatusColors[OligoStatus.PendingQC]}>
      {OligoStatus.PendingQC}
    </Badge>
  ),
  [OligoStatus.Canceled]: (
    <Badge className={oligoStatusColors[OligoStatus.Canceled]}>
      {OligoStatus.Canceled}
    </Badge>
  ),
};
