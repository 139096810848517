import type { WellErrorSeverity } from "@console/shared";
import { PlateErrorSeverity } from "@console/shared";
import { AlertTriangle, XCircle } from "lucide-react";

import type { PlateFromTRPC } from "./plate/types";
import { getPlateProblems } from "./plateErrors";

import { Button } from "../../../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";

const ErrorSeverityToIcon: Record<
  PlateErrorSeverity | WellErrorSeverity,
  JSX.Element
> = {
  [PlateErrorSeverity.Error]: <XCircle size={24} />,
  [PlateErrorSeverity.Warning]: <AlertTriangle size={24} />,
};

export default function PlateErrors({
  plate,
}: {
  plate: PlateFromTRPC | undefined | null;
}) {
  if (!plate) {
    return null;
  }

  const plateProblems = getPlateProblems(plate);
  const plateHasError = plateProblems.length > 0;

  if (!plateHasError) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="text-red-600 hover:text-red-700" variant={"ghost"}>
          <AlertTriangle />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 text-sm">
        <ul className="flex flex-col">
          {plateProblems.map((problem) => {
            return (
              <li
                className="flex flex-row items-start space-x-2"
                key={problem.message}
              >
                <span>{ErrorSeverityToIcon[problem.severity]}</span>
                <p>{problem.message}</p>
              </li>
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
}
