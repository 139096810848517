import type {
  VisibilityState,
  SortingState,
  ColumnFiltersState,
  PaginationState,
  ExpandedState,
} from "@tanstack/react-table";
import { useState } from "react";
import { useQueryParams, withDefault, JsonParam } from "use-query-params";

import {
  usePaginationStateParam,
  ColumnsFiltersStateParam,
  ColumnsFilteredStateParam,
  SortingStateParam,
} from "./data-table-query-params";

export default function useStoreDataTableState(
  defaultPageSize: number,
  withQueryParams = true,
) {
  const [expandedState, setExpandedState] = useState<ExpandedState>({});
  const [visibilityState, setVisibilityState] = useState<VisibilityState>({});
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [columnsFilteredState, setColumnsFilteredState] = useState<string[]>(
    [],
  );
  const [columnFiltersState, setColumnFiltersState] =
    useState<ColumnFiltersState>([]);
  const [globalFilterState, setGlobalFilterState] = useState("");
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const PaginationStateParam = usePaginationStateParam(defaultPageSize);

  const [query, setQuery] = useQueryParams({
    columnFilters: ColumnsFiltersStateParam,
    columnsFiltered: ColumnsFilteredStateParam,
    expanded: withDefault(JsonParam, {} as ExpandedState),
    globalFilter: withDefault(JsonParam, ""),
    pagination: PaginationStateParam,
    sorting: SortingStateParam,
    visibility: withDefault(JsonParam, {} as VisibilityState),
  });

  const {
    visibility: columnVisibilityQuery,
    sorting: sortingQuery,
    columnsFiltered: columnsFilteredQuery,
    columnFilters: columnFiltersQuery,
    pagination: paginationQuery,
    globalFilter: globalFilterQuery,
    expanded: expandedQuery,
  } = query;

  const setPaginationFn = (value: PaginationState) => {
    if (withQueryParams) {
      setQuery(
        {
          pagination: value,
        },
        "replaceIn",
      );
    } else {
      setPaginationState(value);
    }
  };
  const setVisibilityFn = (value: VisibilityState) => {
    if (withQueryParams) {
      setQuery(
        {
          visibility: value,
        },
        "replaceIn",
      );
    } else {
      setVisibilityState(value);
    }
  };
  const setSortingFn = (value: SortingState) => {
    if (withQueryParams) {
      setQuery(
        {
          sorting: value,
        },
        "replaceIn",
      );
    } else {
      setSortingState(value);
    }
  };
  const setColumnsFilteredFn = (value: string[]) => {
    if (withQueryParams) {
      setQuery(
        {
          columnsFiltered: value,
        },
        "replaceIn",
      );
    } else {
      setColumnsFilteredState(value);
    }
  };
  const setColumnFiltersFn = (value: ColumnFiltersState) => {
    if (withQueryParams) {
      setQuery(
        {
          columnFilters: value,
        },
        "replaceIn",
      );
    } else {
      setColumnFiltersState(value);
    }
  };
  const setGlobalFilterFn = (value: string) => {
    if (withQueryParams) {
      setQuery(
        {
          globalFilter: value,
        },
        "replaceIn",
      );
    } else {
      setGlobalFilterState(value);
    }
  };
  const setExpandedFn = (value: ExpandedState) => {
    if (withQueryParams) {
      setQuery(
        {
          expanded: value,
        },
        "replaceIn",
      );
    } else {
      setExpandedState(value);
    }
  };

  const pagination = withQueryParams ? paginationQuery : paginationState;
  const visibility = withQueryParams ? columnVisibilityQuery : visibilityState;
  const sorting = withQueryParams ? sortingQuery : sortingState;
  const columnsFiltered = withQueryParams
    ? columnsFilteredQuery
    : columnsFilteredState;
  const columnFilters = withQueryParams
    ? columnFiltersQuery
    : columnFiltersState;
  const globalFilter = withQueryParams ? globalFilterQuery : globalFilterState;
  const expanded = withQueryParams ? expandedQuery : expandedState;

  return {
    columnFilters,
    columnsFiltered,
    expanded,
    globalFilter,
    pagination,
    setColumnFiltersFn,
    setColumnsFilteredFn,
    setExpandedFn,
    setGlobalFilterFn,
    setPaginationFn,
    setSortingFn,
    setVisibilityFn,
    sorting,
    visibility,
  };
}
