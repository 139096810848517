import type { PlateFromTRPC } from "./plate/types";

import { Button } from "../../../../../components/ui/button";
import { IS_DEVELOPMENT } from "../../../../../config";
import { trpc } from "../../../../../config/trpc";
import { downloadBase64Excel } from "../../../../../utils/useDownload";

export default function GenerateSynthesisFile({
  plate,
}: {
  plate: PlateFromTRPC;
}) {
  const { mutate, isPending } =
    trpc.order.productionPlates.generatePlateSynthesisFile.useMutation({
      onSuccess(data) {
        downloadBase64Excel(data, "file.xlsx");
      },
    });

  const generateSynthesisFile = () => {
    mutate(plate.id);
  };

  if (!IS_DEVELOPMENT) {
    return null;
  }

  return (
    <Button isLoading={isPending} onClick={generateSynthesisFile}>
      Generate synthesis file
    </Button>
  );
}
