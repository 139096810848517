import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import type { QueryParamConfig } from "use-query-params";
import { JsonParam } from "use-query-params";

export const SortingStateParam: QueryParamConfig<SortingState> = {
  decode(value) {
    if (!value || Array.isArray(value)) return [];
    return value.split("|").map((sort) => {
      const [columnId, direction] = sort.split(":");
      return {
        desc: direction === "desc",
        id: columnId,
      };
    });
  },
  default: [],
  encode(value) {
    return value
      ?.map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`)
      .join("|");
  },
};

export const usePaginationStateParam = (
  defaultPageSize: number,
): QueryParamConfig<PaginationState> => {
  return {
    decode(value) {
      if (!value || Array.isArray(value)) {
        return { pageIndex: 0, pageSize: defaultPageSize };
      }
      const [pageIndex, pageSize] = value.split(":");
      return { pageIndex: Number(pageIndex), pageSize: Number(pageSize) };
    },
    default: { pageIndex: 0, pageSize: defaultPageSize },
    encode(value) {
      return `${value.pageIndex}:${value.pageSize}`;
    },
  };
};

export const ColumnsFilteredStateParam: QueryParamConfig<string[]> = {
  decode(value) {
    if (!value || Array.isArray(value)) return [];
    return value.split("|");
  },
  default: [],
  encode(value) {
    return value.join("|");
  },
};

export const ColumnsFiltersStateParam: QueryParamConfig<ColumnFiltersState> = {
  decode(value) {
    if (!value || Array.isArray(value)) return [];
    return value.split("|").map((filter) => {
      const [columnId, filterValue] = filter.split(":");
      return {
        id: columnId,
        value: JsonParam.decode(filterValue),
      };
    });
  },
  default: [],
  encode(value) {
    return value
      .map((filter) => `${filter.id}:${JsonParam.encode(filter.value)}`)
      .join("|");
  },
};
