import { RefreshCcw, SaveIcon } from "lucide-react";
import { useState } from "react";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import UploadExcelFile from "../../../../../components/ui/upload-excel-file";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../../containers/user/hooks";
import { arrayBufferTobase64 } from "../../../../../utils/useDownload";

export default function ReplaceOrderContent({ id }: { id: string }) {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const {
    order: { create: canChangeOrderContent },
  } = useGetUIAuthorizedCreations();

  const utils = trpc.useUtils();
  const { isPending, mutate: replaceOrderContent } =
    trpc.order.update.content.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error updating order content",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess(_, variables) {
        toast({
          title: "Order content updated",
          variant: "success",
        });
        utils.order.read.invalidate(variables.id);
        setOpen(false);
      },
    });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      replaceOrderContent({
        content: base64,
        id,
      });
    };

    submit();
  };

  if (!canChangeOrderContent) {
    return null;
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          aria-label="add-user-to-owners"
          className="flex items-center justify-center space-x-1"
          variant={"outline"}
        >
          <span>Replace content</span>
          <RefreshCcw />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Replace order content</DialogTitle>
        </DialogHeader>
        <UploadExcelFile file={file} setFile={setFile} />
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
