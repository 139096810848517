import { trpc } from "../../../../../config/trpc";
import type { BacklogOligo } from "../oligos/components/oligo-row";

export default function useRemoveOligo(oligo: BacklogOligo) {
  const utils = trpc.useUtils();
  const { mutateAsync: removeOligo } = trpc.order.removeOligo.useMutation({
    onSuccess(undefined, oligoId) {
      utils.order.oligos.setData(undefined, (prev) => {
        if (!prev) {
          return undefined;
        }
        const prevWithNewCount = prev
          .filter((o) => o.id !== oligoId)
          .map((o) =>
            o.itemId === oligo.itemId
              ? { ...o, replicates: o.replicates - 1 }
              : o,
          );
        return prevWithNewCount;
      });
    },
  });
  return removeOligo;
}
