import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function useReplicateOligos() {
  const utils = trpc.useUtils();
  const { toast } = useToast();

  const { mutate: replicateOligos } = trpc.order.replicateOligos.useMutation({
    onSuccess(newOligos, oligoIds) {
      toast({
        title: "Oligos replicated",
        variant: "success",
      });
      utils.order.oligos.setData(undefined, (prev) => {
        if (!prev) {
          return undefined;
        }
        const lastIndex = Math.max(
          ...oligoIds.map((id) => prev.findIndex((o) => o.id === id)),
        );
        const itemToReplicates = new Map<string, number>();
        for (const newOligo of newOligos) {
          itemToReplicates.set(
            newOligo.itemId,
            (itemToReplicates.get(newOligo.itemId) ?? 0) + 1,
          );
        }
        for (const oligo of prev) {
          itemToReplicates.set(
            oligo.itemId,
            (itemToReplicates.get(oligo.itemId) ?? 0) + 1,
          );
        }

        return [
          ...prev.slice(0, lastIndex + 1),
          ...newOligos,
          ...prev.slice(lastIndex + 1),
        ].map((o) => ({
          ...o,
          replicates: itemToReplicates.get(o.itemId) ?? 0,
        }));
      });
    },
  });
  return replicateOligos;
}
