import type { MouseEvent, PropsWithChildren } from "react";

import { RowActions } from "./data-table-row-actions";

import { AlertDialogWrapper } from "../alert-dialog";
import { DropdownMenuItem } from "../dropdown-menu";

export function TableActions({
  items,
  modal = false,
}: {
  items: ({
    alertDialog?: {
      message: string;
      title: string;
    };
    dialogChildren?: React.ReactNode;
    disabled?: boolean;
    id: string;
    onClick?: (event?: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
    preventDefault?: boolean;
    skip?: boolean;
  } & PropsWithChildren)[];
  modal?: boolean;
}) {
  return (
    <div className="flex justify-end">
      <RowActions modal={modal}>
        {items.map((item) => {
          if (item.skip) {
            return null;
          }
          return (
            <DropdownMenuItem
              disabled={item.disabled}
              key={item.id}
              onClick={
                item.alertDialog || item.preventDefault
                  ? (e) => e.preventDefault()
                  : item.onClick
              }
            >
              {item.alertDialog ? (
                <AlertDialogWrapper
                  description={item.alertDialog.message}
                  onConfirm={item.onClick}
                  title={item.alertDialog.title}
                >
                  {item.children}
                </AlertDialogWrapper>
              ) : (
                <>{item.children}</>
              )}
            </DropdownMenuItem>
          );
        })}
      </RowActions>
    </div>
  );
}

export type TableActionItem = React.ComponentProps<
  typeof TableActions
>["items"][number];
