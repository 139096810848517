import { AccountStatus } from "@console/shared";
import { Link as RadixLink } from "@radix-ui/themes";
import { ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";

import GlobalSearch from "./global-search";
import OrganizationTabs from "./header-tabs";
import Logo from "./logo";
import { LogoutItem } from "./logout";
import Notifications from "./notifications";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "../../../components/ui/menubar";
import { useUser } from "../../../containers/hooks";
import { AdminRoutes } from "../../admin/adminRoutes";
import { RootRoutes } from "../../routes";
import { OrganizationSettingsRoutes } from "../../settings/organization-settings/organization-settings-routes";
import { SettingRoutes } from "../../settings/setting-routes";

const AdminButton = () => {
  const user = useUser();

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <MenubarMenu>
      <MenubarTrigger>Admin</MenubarTrigger>
      <MenubarContent>
        <MenubarItem asChild className="cursor-pointer">
          <Link to={AdminRoutes.ORGANIZATIONS}>Organizations</Link>
        </MenubarItem>
        <MenubarSeparator />
        <MenubarItem asChild className="cursor-pointer">
          <Link to={AdminRoutes.USERS}>Users</Link>
        </MenubarItem>
        <MenubarSeparator />
        <MenubarItem asChild className="cursor-pointer">
          <Link to={AdminRoutes.INSTRUMENTS}>Instruments</Link>
        </MenubarItem>
        <MenubarSeparator />
        <MenubarItem asChild className="cursor-pointer">
          <Link to={AdminRoutes.SERVICE}>Service</Link>
        </MenubarItem>
      </MenubarContent>
    </MenubarMenu>
  );
};

const SettingsButton = () => {
  const user = useUser();
  const isDev = import.meta.env.DEV;
  const canSeeOrganizationSettings =
    user?.membership &&
    user?.membership.status === AccountStatus.ACTIVE &&
    user?.membership.isManager;
  const canSeePickOrganization = (user?.memberships ?? []).length > 1;

  return (
    <MenubarMenu>
      <MenubarTrigger>Settings</MenubarTrigger>
      <MenubarContent>
        {canSeeOrganizationSettings && (
          <>
            <MenubarSub>
              <MenubarSubTrigger>Organization</MenubarSubTrigger>
              <MenubarSubContent>
                <MenubarItem asChild className="cursor-pointer">
                  <Link to={OrganizationSettingsRoutes.DETAILS}>Details</Link>
                </MenubarItem>
                <MenubarItem asChild className="cursor-pointer">
                  <Link to={OrganizationSettingsRoutes.USERS}>Users</Link>
                </MenubarItem>
                <MenubarItem asChild className="cursor-pointer">
                  <Link to={OrganizationSettingsRoutes.TEAMS}>Teams</Link>
                </MenubarItem>
                <MenubarItem asChild className="cursor-pointer">
                  <Link to={OrganizationSettingsRoutes.SUPPORT}>Support</Link>
                </MenubarItem>
                <MenubarItem asChild className="cursor-pointer">
                  <Link to={OrganizationSettingsRoutes.ADMIN}>Admin</Link>
                </MenubarItem>
              </MenubarSubContent>
            </MenubarSub>
            <MenubarSeparator />
          </>
        )}
        {canSeePickOrganization && (
          <>
            <MenubarItem asChild className="cursor-pointer">
              <Link to={RootRoutes.PICK_ORGANIZATION}>Change organization</Link>
            </MenubarItem>
            <MenubarSeparator />
          </>
        )}
        <MenubarItem asChild className="cursor-pointer">
          <Link to={SettingRoutes.PROFILE}>Profile</Link>
        </MenubarItem>
        {isDev && (
          <>
            <MenubarSeparator />
            <MenubarItem asChild className="cursor-pointer">
              <Link to={RootRoutes.EMAILS}>Emails</Link>
            </MenubarItem>
            <MenubarSeparator />
            <MenubarItem asChild className="cursor-pointer">
              <Link to={RootRoutes.ICONS}>Icons</Link>
            </MenubarItem>
          </>
        )}
        <MenubarSeparator />
        <RadixLink
          href="https://www.dnascript.com/resources/faq/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <MenubarItem className="cursor-pointer space-x-2">
            <span>FAQ</span>
            <ExternalLink />
          </MenubarItem>
        </RadixLink>
        <RadixLink
          href="https://www.dnascript.com/resources/support/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <MenubarItem className="cursor-pointer space-x-2">
            <span>Support</span>
            <ExternalLink />
          </MenubarItem>
        </RadixLink>
        <MenubarSeparator />
        <LogoutItem />
      </MenubarContent>
    </MenubarMenu>
  );
};

export default function Header() {
  const user = useUser();
  const isLoggedIn = Boolean(user?.membership);

  return (
    <header className="bg-secondary sticky top-0 z-50 w-full border-b backdrop-blur">
      <div className="container flex h-14 items-center justify-between">
        <Logo />
        <OrganizationTabs />
        <div className="flex">
          <div className="pe-5">{isLoggedIn && <Notifications />}</div>
          <Menubar className="bg-card">
            <GlobalSearch />
            <AdminButton />
            <SettingsButton />
          </Menubar>
        </div>
      </div>
    </header>
  );
}
