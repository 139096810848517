import { ZoomIn, ZoomOut } from "lucide-react";
import { useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

import { Button } from "../../../../../components/ui/button";
import { Slider } from "../../../../../components/ui/slider";

const ZOOM_STEP = 0.1;
const DEFAULT_ZOOM = 1;
const MAX_ZOOM = 3;
const MIN_ZOOM = 1;

export default function useZoom(
  { defaultZoom, maxZoom, minZoom, step } = {
    defaultZoom: DEFAULT_ZOOM,
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    step: ZOOM_STEP,
  },
) {
  const [localZoom, setLocalZoom] = useState<number>(1);
  const [zoom, setZoom] = useQueryParam("zoom", NumberParam);

  const applyNewZoom = (newZoom: number) => {
    setZoom(newZoom);
    setLocalZoom(newZoom);
  };

  const zoomElement = (
    <div className="flex flex-row items-center space-x-1">
      <Button
        onClick={() => applyNewZoom(Math.max(minZoom, localZoom - step))}
        size={"icon"}
        variant={"ghost"}
      >
        <ZoomOut />
      </Button>
      <Slider
        className="min-w-[100px]"
        defaultValue={[defaultZoom]}
        max={maxZoom}
        min={minZoom}
        onValueChange={(v) => {
          applyNewZoom(v[0]);
        }}
        onValueCommit={(v) => {
          setLocalZoom(v[0]);
        }}
        step={step}
        value={[localZoom]}
      />
      <Button
        onClick={() => {
          applyNewZoom(Math.min(maxZoom, localZoom + step));
        }}
        size={"icon"}
        variant={"ghost"}
      >
        <ZoomIn />
      </Button>
    </div>
  );

  return { zoom, zoomElement };
}
