import type { PlateFromTRPC, PlateRun } from "./plate/types";
import PlateDescription from "./plate-description";

import {
  CardHeader,
  CardTitle,
  CardContent,
  Card,
} from "../../../../../components/ui/card";
import { RunStateToBadge } from "../../../../instrument/components/runs-table";
import RunProgress from "../run-progress";

const RunProgressCard = ({ run }: { run: PlateRun }) => {
  const { state } = run;
  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle className="flex flex-row justify-between">
          <div className="flex flex-row items-center space-x-4">
            <span>Progress</span>
          </div>
          <div>{RunStateToBadge[state]}</div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-row justify-between">
        <RunProgress run={run} />
      </CardContent>
    </Card>
  );
};

export default function PlateProgress({ plate }: { plate: PlateFromTRPC }) {
  const { run } = plate;

  return (
    <div className="grid grid-cols-2 gap-2">
      <Card className="col-span-1">
        <CardHeader>
          <CardTitle>Details</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col space-y-4 overflow-x-auto">
          {plate && <PlateDescription plate={plate} />}
        </CardContent>
      </Card>
      {run && <RunProgressCard run={run} />}
    </div>
  );
}
