import type { ForwardedRef } from "react";
import { forwardRef, useEffect, useState } from "react";

import {
  BlockName,
  OverlapInfo,
  PrimerForwardBlock,
  PrimerReversBlock,
} from "./common";

import type { GeneDesignConfigurationType } from "../logic/configuration";
import type { GeneDesign } from "../logic/types";

const renderPreviewBlocks = ({
  activeBlock,
  data,
  setActiveBlock,
  configuration,
  sequence,
  errors = {},
}: {
  activeBlock: number | null;
  configuration: GeneDesignConfigurationType;
  data: GeneDesign;
  errors?: Record<string, string>;
  sequence: string;
  setActiveBlock: (index: number) => void;
}) => {
  const blocks = [] as (JSX.Element | null)[];
  const overlap = [] as (JSX.Element | null)[];

  const fullSize =
    data.blocks[data.blocks.length - 1] && data.blocks[0]
      ? data.blocks[data.blocks.length - 1].end - data.blocks[0].start
      : 0;

  data.blocks.forEach((block, index) => {
    const prevBlock = data.blocks[index - 1];
    const leftOverlapLength = (prevBlock?.end ?? block.start) - block.start;
    const mainBlockSize =
      (data.blocks[index + 1]?.start ?? block.end) -
      (data.blocks[index - 1]?.end ?? block.start);

    const overlapWidthInPercent = (leftOverlapLength / fullSize) * 100;
    const mainWidthInPercent = (mainBlockSize / fullSize) * 100;

    const widthInPercent = ((block.end - block.start) / fullSize) * 100;

    const active = index === activeBlock;
    const hasActive = activeBlock !== null;
    const opacity = hasActive && !active ? 0.4 : 1;

    const blockErrors = new Set(
      Object.keys(errors)
        .map((key) => {
          const error = errors?.[key];
          if (key.startsWith(`blocks.${index}`)) {
            return error;
          }

          return null;
        })
        .filter((error) => error),
    );

    blocks.push(
      <div
        className="relative inline-block whitespace-nowrap rounded text-center"
        key={index + block.name + "block"}
        style={{
          backgroundColor: configuration.getColor(index),
          borderColor: configuration.getColor(index, 1),
          borderWidth: active ? 2 : 1,
          marginLeft: `${index === 0 ? 0 : -overlapWidthInPercent}%`,
          opacity,
          width: `${widthInPercent}%`,
          zIndex: active ? 1 : undefined,
        }}
      >
        <div
          className="relative flex h-[35px] cursor-pointer items-center justify-center"
          onClick={() => setActiveBlock(index)}
          style={{
            left: active
              ? "0px"
              : `${(overlapWidthInPercent * 100) / widthInPercent}%`,
            width: active
              ? ""
              : `${(mainWidthInPercent * 100) / widthInPercent}%`,
          }}
        >
          <BlockName
            color={configuration.getColor(index, 1)}
            hasError={blockErrors.size > 0}
            label={block.name}
          >
            {Array.from(blockErrors).map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </BlockName>
        </div>
        {active && !!block.primers?.forward && (
          <div className="absolute left-0 top-0 -translate-y-full pb-2">
            <PrimerForwardBlock
              configuration={configuration}
              label="gene fw primer"
            />
          </div>
        )}
        {active && !!block.primers?.reverse && (
          <div className="absolute bottom-0 right-0 translate-y-full">
            <PrimerReversBlock
              configuration={configuration}
              label="gene fw primer"
            />
          </div>
        )}
      </div>,
    );

    if (prevBlock) {
      const leftOffset = (block.start * 100) / fullSize;
      const hightlight = !!errors?.[`blocks.${index - 1}.overlap`];
      overlap.push(
        <div
          className="absolute top-[50%] h-[110%] -translate-y-1/2 transform rounded border border-dashed"
          key={index + block.name + "overlap"}
          style={{
            borderColor: configuration.getOverlapColor(1),
            left: `${leftOffset}%`,
            opacity: hasActive ? 0.05 : 1,
            width: `${overlapWidthInPercent}%`,
            zIndex: 2,
          }}
        >
          <div className="absolute left-0 right-0 top-0 flex -translate-y-full flex-col items-center pb-2 text-xs">
            <OverlapInfo
              configuration={configuration}
              end={prevBlock.end}
              hightlight={hightlight}
              sequence={sequence}
              start={block.start}
            >
              {errors?.[`blocks.${index - 1}.overlap`]}
            </OverlapInfo>
          </div>
        </div>,
      );
    }
  });

  return {
    blocks,
    overlap,
  };
};

type PreviewProps = {
  activeBlock: number | null;
  configuration: GeneDesignConfigurationType;
  data: GeneDesign;
  errors?: Record<string, string>;
  scrollLeft?: number;
  sequence: string;
  setActiveBlock: (index: number) => void;
  updateScrollLeft?: (scrollLeft: number) => void;
};
function Preview(
  {
    data,
    setActiveBlock,
    activeBlock,
    configuration,
    sequence,
    errors,
  }: PreviewProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [previewScrollBoxWidth, setPreviewScrollBoxWidth] = useState(0);
  const fullSize =
    data.blocks[data.blocks.length - 1] && data.blocks[0]
      ? data.blocks[data.blocks.length - 1].end - data.blocks[0].start
      : 0;

  useEffect(() => {
    const $elem = (ref as React.MutableRefObject<HTMLDivElement>)?.current;
    if ($elem) {
      const viewBockSize = ($elem.clientWidth * 100) / $elem.scrollWidth;
      setPreviewScrollBoxWidth(viewBockSize);
    }
  }, [configuration.nuclWidth, fullSize, ref]);

  const { blocks, overlap } = renderPreviewBlocks({
    activeBlock,
    configuration,
    data,
    errors,
    sequence,
    setActiveBlock,
  });

  return (
    <div className="relative max-w-full bg-slate-100 py-2">
      <div className="relative ml-auto mr-auto max-w-[900px]">
        <div
          className="absolute top-0 top-[50%] h-2 w-2 -translate-y-1/2 bg-white"
          style={{
            height: "100%",
            left: `${scrollLeft}%`,
            width: `${previewScrollBoxWidth}%`,
          }}
        ></div>
        {/* Scroll */}
        <div
          className="scrollbar relative ml-auto mr-auto overflow-y-auto pt-4"
          onScroll={(e) => {
            const scrollSize = e.currentTarget.scrollWidth;
            const scrollLeft = e.currentTarget.scrollLeft;
            const scrollPercent = (scrollLeft / scrollSize) * 100;
            setScrollLeft(scrollPercent);
          }}
          ref={ref}
        >
          <div className="sticky left-0 flex flex-col gap-3">
            <PrimerForwardBlock
              className={activeBlock !== null ? "opacity-5" : undefined}
              configuration={configuration}
              label="gene fw primer"
            />

            {/* Blocks */}
            <div className="relative whitespace-nowrap text-center">
              {blocks}
              {overlap}
            </div>

            <PrimerReversBlock
              className={activeBlock !== null ? "opacity-5" : undefined}
              configuration={configuration}
              label="gene rv primer"
            />
          </div>
          <div
            style={{
              width: fullSize * configuration.nuclWidth,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export const BlocksPreview = forwardRef<HTMLDivElement, PreviewProps>(Preview);
