import ChooseHamiltonInstrument from "./choose-hamilton-instrument";
import { GenerateInstructionButton } from "./gene/generate-instructions";
import type { GeneWorkflowStep } from "./plate/types";

export function GeneStepActions({ step }: { step: GeneWorkflowStep }) {
  const hasAssignedHamiltonInstrument = Boolean(step.gene.instrument);

  return (
    <>
      <ChooseHamiltonInstrument step={step} />
      {hasAssignedHamiltonInstrument && (
        <GenerateInstructionButton stepId={step.id} />
      )}
    </>
  );
}
