import { forwardRef } from "react";

import { useOligosLayers } from "./useOligosLayers";

import type { GeneDesignConfigurationType } from "../../logic/configuration";
import type { GeneDesignReducerState } from "../../logic/reducer";
import type { GeneDesign } from "../../logic/types";
import { GeneDesignSequenceView } from "../common";

function View(
  {
    activeBlock,
    data,
    sequence,
    configuration,
    errors,
  }: {
    activeBlock: number;
    configuration: GeneDesignConfigurationType;
    data: GeneDesign;
    errors?: GeneDesignReducerState["validationErrors"];
    sequence: string;
  },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const nuclWidth = configuration.nuclWidth; // in px
  const block = data.blocks[activeBlock];
  const blockWidth = (block?.end - block?.start) * nuclWidth;

  const {
    backgroundColorView,
    forwardOligosView,
    reverseOligosView,
    blockControlsView,
    overlapInfoViews,
    overlapViews,
    forwardPrimerBg,
    forwardPrimerInfo,
    reversePrimerBg,
    reversePrimerInfo,
  } = useOligosLayers({
    blockIndex: activeBlock,
    configuration,
    errors,
    geneDesign: data,
    nuclWidth,
    sequence,
  });

  return (
    <div className="px-4 pb-5">
      <div className="scrollbar flex flex-col overflow-y-auto" ref={ref}>
        <div className="ml-auto mr-auto" style={{ width: blockWidth }}>
          {/* Forward primer info */}
          <div className="ml-auto mr-auto flex w-full items-center whitespace-nowrap text-xs">
            {forwardPrimerInfo}
          </div>

          {/* Overlap information */}
          <div className="ml-auto mr-auto flex w-full items-center whitespace-nowrap text-xs">
            {overlapInfoViews}
          </div>

          {/* Oligo information */}
          <div className="ml-auto mr-auto flex w-full items-center whitespace-nowrap py-1 text-xs">
            {forwardOligosView}
          </div>

          {/* Sequence layer */}
          <div className="relative ml-auto mr-auto h-[52px] w-full whitespace-nowrap">
            <div className="absolute bottom-0 left-0 right-0 top-0 w-full">
              {backgroundColorView}
            </div>
            <div className="absolute left-0 top-0 w-full">
              <GeneDesignSequenceView sequence={sequence} type="oligo" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 top-0 w-full">
              {overlapViews}
            </div>
            <div className="absolute bottom-0 left-0 right-0 top-0 w-full">
              {blockControlsView}
              {forwardPrimerBg}
              {reversePrimerBg}
            </div>
          </div>

          {/* Oligo information */}
          <div className="ml-auto mr-auto flex w-full items-center whitespace-nowrap pb-2 pt-1 text-xs">
            {reverseOligosView}
          </div>

          {/* Reverse primer info */}
          <div className="ml-auto mr-auto flex w-full items-center whitespace-nowrap text-xs">
            {reversePrimerInfo}
          </div>
        </div>
      </div>
    </div>
  );
}

export const OligosView = forwardRef(View);
