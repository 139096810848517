import { RunReportFileFormat } from "@console/shared";
import { Download } from "lucide-react";
import { useState } from "react";

import { Button } from "../../../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useDownloadFile } from "../../../../../utils/useDownload";

export default function DownloadReport({ runId }: { runId: number }) {
  const [format, setFormat] = useState<RunReportFileFormat>(
    RunReportFileFormat.Excel,
  );
  const { toast } = useToast();
  const downloadFile = useDownloadFile();

  const { mutate: downloadReport } = trpc.plate.run.downloadReport.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error when downloading report",
        variant: "destructive",
      });
    },
    onSuccess(url) {
      downloadFile(url, "report.xlsx").catch((error) => {
        const message =
          "message" in (error as any) ? (error as any).message : "";
        toast({
          description: message,
          title: "Error when downloading report",
          variant: "destructive",
        });
      });
    },
  });

  const handleDownload = () => {
    downloadReport({
      format,
      id: runId,
    });
  };

  return (
    <div className="flex flex-row items-center space-x-1 rounded-lg border px-2">
      <Button className="space-x-1" onClick={handleDownload} variant={"ghost"}>
        <span>Download</span>
        <Download />
      </Button>
      <Select
        onValueChange={(v) => setFormat(v as RunReportFileFormat)}
        value={format}
      >
        <SelectTrigger className="h-8 w-32">
          <SelectValue />
        </SelectTrigger>
        <SelectContent side="top">
          <SelectItem value={RunReportFileFormat.CSV}>
            CSV <span className="text-gray-500">legacy</span>
          </SelectItem>
          <SelectItem value={RunReportFileFormat.Excel}>Excel</SelectItem>
          <SelectItem value={RunReportFileFormat.JSON}>JSON</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
