import { PlateSize, WellErrorSeverity } from "@console/shared";
import { CircleAlert, AlertTriangle } from "lucide-react";
import { forwardRef, useCallback } from "react";
import { useDrag, useDrop } from "react-dnd";

import { Display, DISPLAY_PROPERTIES, DisplayType } from "./display";
import { FailedNormalizationIcon } from "./legends";
import type { PlateKit, WorkflowWell } from "./types";
import { defaultWellColors } from "./useGetWellBackgroundColor";
import { WellInside } from "./well-inside";

import { cn } from "../../../../../../lib/utils";
import { usePlateContext } from "../../containers/PlateContainer";

type WellProps = {
  display: Display;
  handleClickOnWell: (
    ev: React.MouseEvent<HTMLDivElement>,
    wellIndex: string,
  ) => void;
  handleMoveWell: (newWellIndex: string, originWellIndex: string) => void;
  isDeleted: boolean;
  isEditable: boolean;
  isFilteredOut: boolean;
  isModified: boolean;
  isNew: boolean;
  isSelected: boolean;
  kitProperties: PlateKit;
  size: PlateSize;
  well: WorkflowWell | undefined;
  wellIndex: string;
};

const isWellEmpty = (well: WorkflowWell | undefined) => {
  return (
    !well || (!well?.sequence && !well?.expectedConcentration && !well?.name)
  );
};

const Well = forwardRef(function Well(
  {
    display,
    handleMoveWell,
    handleClickOnWell,
    isDeleted,
    isEditable,
    isModified,
    isFilteredOut,
    isNew,
    isSelected,
    kitProperties,
    size,
    well,
    wellIndex,
  }: WellProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const selectWellOnClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      handleClickOnWell(ev, wellIndex);
    },
    [handleClickOnWell, wellIndex],
  );

  const isPlate96 = size === PlateSize.S96;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, drag] = useDrag(
    () => ({
      canDrag: !isWellEmpty(well) && isEditable,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: {
        wellIndex,
      },
      type: "Well",
    }),
    [well, wellIndex],
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Well",
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      drop: (item) => {
        const originIndex = (item as { wellIndex: string }).wellIndex;
        handleMoveWell(originIndex, wellIndex);
      },
    }),
    [handleMoveWell],
  );

  const { getWellOrderBgColor } = usePlateContext();
  const {
    background: backgroundColor,
    text: textColor,
    useGradient,
  } = well
    ? DISPLAY_PROPERTIES[display].getWellColor(
        well,
        kitProperties,
        getWellOrderBgColor,
      )
    : defaultWellColors;

  return (
    <div
      className="relative"
      id={wellIndex}
      onClick={selectWellOnClick}
      ref={(node) => {
        drop(node);
        if (typeof ref === "function") {
          ref(node);
        }
      }}
    >
      <div
        className={cn(
          "hover:border-1 flex aspect-square cursor-pointer items-center justify-center  border hover:border-slate-500 hover:opacity-60",
          isSelected
            ? "aspect-square scale-110 items-center justify-center border-2 border-slate-500"
            : "",
          backgroundColor,
          textColor,
          isOver && "ring",
          useGradient && !isNew && "bg-well",
          isNew && "bg-green border-2",
          isDeleted && "bg-destructive border-2",
          isFilteredOut && "opacity-20",
          isPlate96 ? "rounded-full text-xl" : "rounded-sm text-base",
          isModified && "outline-dashed outline-slate-500",
        )}
        ref={drag}
        style={
          useGradient && !isNew
            ? {
                backgroundImage: useGradient.gradient,
                backgroundPosition: `${useGradient.position}%`,
              }
            : {}
        }
      >
        <WellInside
          display={display}
          iconSize={isPlate96 ? 24 : 12}
          well={well}
        />
      </div>
      <WellInfoWrapper isPlate96={isPlate96}>
        <WellWarning display={display} well={well} />
      </WellInfoWrapper>
    </div>
  );
});
Well.displayName = "Well";

export default Well;

function WellInfoWrapper({
  children,
  isPlate96,
}: {
  children: React.ReactNode;
  isPlate96: boolean;
}) {
  return (
    <div
      className={cn(
        "bg-card svg:text-[30cqw] pointer-events-none absolute z-40 rounded-[20px] drop-shadow",
        isPlate96 ? "right-0 top-0" : "right-[-6px] top-[-6px]",
      )}
    >
      {children}
    </div>
  );
}

function WellWarning({
  well,
  display,
}: {
  display: Display;
  well: WorkflowWell | undefined;
}) {
  if (!well) {
    return null;
  }
  const isQCDisplay =
    DISPLAY_PROPERTIES[display].displayType === DisplayType.qc;
  if (isQCDisplay) {
    return null;
  }
  const { errors } = well;
  const hasBlockingErrors =
    errors.filter((error) => error.severity === WellErrorSeverity.Error)
      .length > 0;
  const hasWarnings =
    errors.filter((error) => error.severity === WellErrorSeverity.Warning)
      .length > 0;
  const hasResults = Boolean(well.result);
  const unusualQuantification = Boolean(well.result?.yieldError);
  const failedNormalization = Boolean(well.result?.concentrationError);

  if (unusualQuantification) {
    return (
      <CircleAlert
        className="text-white"
        fill="#f97316"
        height={16}
        width={16}
      />
    );
  }
  if (hasBlockingErrors) {
    return <CircleAlert className="text-red-500" height={16} width={16} />;
  }
  if (hasWarnings && !hasResults) {
    return <AlertTriangle className="text-orange-300" height={16} width={16} />;
  }
  if (failedNormalization && display === Display.measuredConcentration) {
    return <FailedNormalizationIcon />;
  }

  return null;
}
