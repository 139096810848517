import { OligoStatus } from "@console/shared";

import { oligoStatusColors, OligoStatusComponent } from "./oligo-status";

import { cn } from "../../../../../lib/utils";

export default function OrderCompletionGraph({
  itemStatuses,
}: {
  itemStatuses: OligoStatus[];
}) {
  const counts: Map<OligoStatus, number> = new Map();
  itemStatuses.forEach((status) => {
    counts.set(status, (counts.get(status) ?? 0) + 1);
  });
  return (
    <div className="relative mx-3 mb-2 mt-8 flex h-5 w-full flex-row rounded-lg bg-slate-200">
      {[
        OligoStatus.Opportunity,
        OligoStatus.Canceled,
        OligoStatus.Queued,
        OligoStatus.Assigned,
        OligoStatus.Failed,
        OligoStatus.Printing,
        OligoStatus.Synthesized,
        OligoStatus.PendingQC,
        OligoStatus.Liberated,
      ].map((status) => {
        const percentage =
          ((counts.get(status) ?? 0) / itemStatuses.length) * 100;
        if (percentage <= 0) {
          return null;
        }
        return (
          <div
            className={cn(
              "relative h-full rounded-lg",
              oligoStatusColors[status],
            )}
            key={status}
            style={{ width: `${percentage}%` }}
          >
            <div className="absolute -top-8 left-1/2 -translate-x-1/2">
              {OligoStatusComponent[status]}
            </div>
            <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-white">
              {percentage.toFixed(1)}%
            </p>
          </div>
        );
      })}
    </div>
  );
}
