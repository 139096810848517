import { RunState } from "@console/shared";
import { SelectColumn } from "@frontend/components/logic/select-column";
import type { ColumnDef } from "@tanstack/react-table";
import { Trash2Icon } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AlertDialogWrapper } from "../../../components/ui/alert-dialog";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { CardTitle } from "../../../components/ui/card";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../components/ui/data-table/filters";
import { LoadingSpinner } from "../../../components/ui/spinner";
import { trpc, type RunOfInstrumentTRPC } from "../../../config/trpc";
import { OrganizationRoutes } from "../../organization/organization-routes";

// eslint-disable-next-line react-refresh/only-export-components
export const RunStateToBadge: Record<RunState, JSX.Element> = {
  [RunState.Canceled]: <Badge className="bg-gray-400">Canceled</Badge>,
  [RunState.Unknown]: <Badge className="bg-gray-400">Unknown</Badge>,
  [RunState.Completed]: <Badge className="bg-green-400">Completed</Badge>,
  [RunState.Failed]: <Badge className="bg-red-500">Failed</Badge>,
  [RunState.Loaded]: <Badge className="bg-slate-700">Loaded</Badge>,
  [RunState.Printing]: <Badge className="bg-purple-400">Printing</Badge>,
  [RunState.Queued]: <Badge className="bg-slate-400">Queued</Badge>,
};

const columns: ColumnDef<RunOfInstrumentTRPC>[] = [
  SelectColumn(
    ({ row }) => (
      <Badge variant={"secondary"}>
        <p className="space-x-1">
          <span>{`Step ${row.original?.stepName}`}</span>
          <span>from assay</span>
          <span>{row.original?.assayName}</span>
        </p>
      </Badge>
    ),
    true,
  ),
  {
    accessorKey: "state",
    cell: ({ row }) => RunStateToBadge[row.original.state],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "state",
    meta: {
      title: "State",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "id",
    cell: ({ row }) => row.original.id.toString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "id",
    meta: {
      title: "ID",
    },
  },
  {
    accessorKey: "stepName",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorFn: (row) => row.kitLabel,
    cell: (info) => info.getValue(),
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "kitLabel",
    meta: {
      title: "Kit label",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "createdAt",
    cell: ({ row }) => row.original.createdAt.toLocaleString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "createdAt",
    meta: {
      title: "Created at",
    },
  },
  {
    accessorKey: "updatedAt",
    cell: ({ row }) => row.original.updatedAt.toLocaleString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "updatedAt",
    meta: {
      title: "Updated at",
    },
  },
  {
    accessorKey: "creatorName",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Created by",
    },
  },
  {
    accessorKey: "startTime",
    cell: ({ row: { original } }) => {
      const startTime = original.startTime;
      if (!startTime) return "N/A";
      return new Date(startTime).toLocaleString();
    },
    header: "Started at",
  },
];

const useDeleteRuns = (instrumentId: string) => {
  const utils = trpc.useUtils();
  return trpc.plate.run.deleteMany.useMutation({
    onSuccess() {
      utils.instrument.syntax.listRuns.invalidate({ instrumentId });
    },
  });
};

export default function RunsTable({
  instrumentId,
  runs,
}: {
  instrumentId: string;
  runs: RunOfInstrumentTRPC[];
}) {
  const navigate = useNavigate();
  const [selectedRuns, setSelectedRuns] = useState<number[]>([]);
  const [resetSelection, setResetSelection] = useState(0);

  const { mutate: deleteRuns, isPending } = useDeleteRuns(instrumentId);

  const handleDeleteRuns = () => {
    deleteRuns({ ids: selectedRuns });
    setResetSelection((prev) => prev + 1);
  };

  return (
    <div className="flex flex-col space-y-2">
      <CardTitle>Runs</CardTitle>
      <DataTable
        columns={columns}
        data={runs ?? []}
        defaultSorting={[{ desc: true, id: "updatedAt" }]}
        enableRowSelection={(row) => row.original.state === RunState.Queued}
        onRowClick={(row) =>
          navigate(
            OrganizationRoutes.ASSAY_WORKFLOW_STEP.replace(
              ":assayId",
              row.original.assayId,
            ).replace(":stepId", row.original.stepId),
          )
        }
        onSelectionChange={(selectedIndices) => {
          if (!runs) {
            return;
          }
          setSelectedRuns(
            selectedIndices.map((s) => runs?.[parseInt(s.id)].id),
          );
        }}
        resetSelection={resetSelection}
      />
      <div className="flex justify-end">
        {selectedRuns.length > 0 && (
          <AlertDialogWrapper
            description={`Are you sure you want to delete the runs ?`}
            onConfirm={handleDeleteRuns}
            title={`Remove runs`}
          >
            <Button variant={"secondary"}>
              <div className="flex flex-row items-center space-x-1">
                {isPending ? (
                  <>
                    <LoadingSpinner />
                    <span>Deleting runs</span>
                  </>
                ) : (
                  <>
                    <Trash2Icon />
                    <span>Delete selected runs</span>
                  </>
                )}
              </div>
            </Button>
          </AlertDialogWrapper>
        )}
      </div>
    </div>
  );
}
