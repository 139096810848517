import type { AppRouter } from "@console/backend/appRouter";
import { OligoStatus } from "@console/shared";
import { cn } from "@shared-ui/lib/utils";
import type { inferProcedureOutput } from "@trpc/server";
import equal from "fast-deep-equal";
import React, { useCallback, useRef } from "react";
import { useDrag } from "react-dnd";

import { DragObjects } from "../../../build/new-workflow/types";
import useNewPlateContext from "../../hooks/useNewPlateContext";

type OligoFromList = inferProcedureOutput<AppRouter["order"]["oligos"]>[number];

export type BacklogOligo = OligoFromList & {
  assigment?: string;
};

const areOligosEqual = (prevOligo: BacklogOligo, nextOligo: BacklogOligo) => {
  return equal(prevOligo, nextOligo);
};

const OligoRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    isSelected?: boolean;
    original: BacklogOligo;
  }
>(
  (
    {
      children,
      className,
      original,
      onMouseEnter,
      onMouseLeave,
      onClick,
      isSelected,
      ...props
    },
    ref,
  ) => {
    const rowRef = useRef<HTMLTableRowElement>(null);

    const { getSelectedOligos } = useNewPlateContext();
    const selectedOligos = getSelectedOligos();
    const { id, status, assigment } = original;

    const canDrag = status === OligoStatus.Queued && !assigment;

    const [collected, drag] = useDrag(
      () => ({
        canDrag,
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
        item: () => {
          if (isSelected) {
            return {
              oligoIds: selectedOligos,
            };
          }
          return {
            oligoId: id,
          };
        },
        type: DragObjects.Oligo,
      }),
      [id, canDrag, isSelected, selectedOligos],
    );

    const onMouseEnterCustom = () => {
      document.querySelectorAll(`[data-group='${id}']`).forEach((el) => {
        el.classList.add("ring");
      });
    };

    const onMouseLeaveCustom = () => {
      document.querySelectorAll(`[data-group='${id}']`).forEach((el) => {
        el.classList.remove("ring");
      });
    };

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLTableRowElement>) => {
        rowRef.current?.focus();
        onClick?.(e);
      },
      [onClick],
    );

    return (
      <tr
        className={cn(
          "data-[state=selected]:bg-muted selectable border-b transition-colors hover:ring",
          className,
          canDrag && "cursor-grab",
          collected.isDragging && "bg-slate-400",
        )}
        data-group={id}
        id={id}
        onClick={handleClick}
        onMouseEnter={(e) => {
          onMouseEnterCustom();
          onMouseEnter?.(e);
        }}
        onMouseLeave={(e) => {
          onMouseLeaveCustom();
          onMouseLeave?.(e);
        }}
        ref={(node) => {
          drag(node);
          // @ts-expect-error - ref is not a function
          rowRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          }
        }}
        {...props}
      >
        {children}
      </tr>
    );
  },
);
OligoRow.displayName = "OligoRow";

const OligoRowMemoized = React.memo(
  OligoRow,
  (prevProps, nextProps) =>
    areOligosEqual(prevProps.original, nextProps.original) &&
    prevProps.onClick === nextProps.onClick,
);
OligoRowMemoized.displayName = "OligoRowMemoized";

export default OligoRowMemoized;
