import { Normalization, ModificationStatus } from "@console/shared";
import { ChevronsDown, Dna, EyeOff, Minus } from "lucide-react";

import { PurityFlagToIcon } from "./constants";
import { DISPLAY_PROPERTIES, type Display } from "./display";
import type { WorkflowWell } from "./types";

import {
  getSequenceLength,
  parseInitiatorDNASequence,
} from "../../../../../../utils/parser";

const NormalizationNodeInside = ({
  expectedConcentration,
  normalization,
  iconSize,
}: {
  expectedConcentration: number | null | undefined;
  iconSize: number;
  normalization: Normalization;
}) => {
  if (normalization === Normalization.None) {
    return <Minus size={iconSize} />;
  }
  if (normalization === Normalization.Lowest) {
    return <ChevronsDown size={iconSize} />;
  }
  return <span>{expectedConcentration}</span>;
};

const ValueInside = ({
  value,
  precision = 1,
}: {
  precision?: number;
  value: number | undefined;
}) => {
  if (value === undefined) {
    return "-";
  }
  return (
    <div className="relative">
      <span>{value.toFixed(precision)}</span>
    </div>
  );
};

export const LengthWellInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { initiatorSequence } = parseInitiatorDNASequence(well.sequence);
  return (
    <div className="relative">
      <span>{getSequenceLength(well.sequence)}</span>
      {initiatorSequence && (
        <div className="absolute -right-2 -top-2 text-purple-600">
          <Dna size={(iconSize * 2) / 3} />
        </div>
      )}
    </div>
  );
};

export const ConcentrationWellInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { expectedConcentration, normalization } = well;
  return (
    <div className="relative">
      <NormalizationNodeInside
        expectedConcentration={expectedConcentration}
        iconSize={iconSize}
        normalization={normalization}
      />
    </div>
  );
};

export const QCConcentrationWellInside = ({ well }: { well: WorkflowWell }) => {
  const concentration = well.qc?.concentration;
  return <ValueInside value={concentration} />;
};

export const PurityInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { purityFlag } = well;
  const Icon = PurityFlagToIcon[purityFlag];
  return (
    <div className="relative">
      <Icon size={iconSize} />
    </div>
  );
};

export const OrderInside = ({
  well,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  return <div className="relative">{well.index}</div>;
};

export const MeasuredConcentrationInside = ({
  well,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { result } = well;
  return <ValueInside value={result?.concentrationPostNormalization} />;
};

export const VolumeInside = ({ well }: { well: WorkflowWell }) => {
  const { result } = well;
  return <ValueInside precision={0} value={result?.totalVolume} />;
};

export const QCVolumeInside = ({ well }: { well: WorkflowWell }) => (
  <ValueInside precision={0} value={well.qc?.volume} />
);

export const YieldInside = ({ well }: { well: WorkflowWell }) => (
  <ValueInside precision={0} value={well.result?.yield} />
);

export const QCYieldInside = ({ well }: { well: WorkflowWell }) => (
  <ValueInside precision={0} value={well.qc?.initialYield} />
);

export const QCRunningMassInside = ({ well }: { well: WorkflowWell }) => (
  <ValueInside precision={0} value={well.qc?.yield} />
);

export const QCABINInside = ({ well }: { well: WorkflowWell }) => (
  <ValueInside precision={0} value={well.qc?.abi?.n} />
);

export const QCOP2NInside = ({ well }: { well: WorkflowWell }) => (
  <ValueInside precision={0} value={well.qc?.op2?.n} />
);

export const ModificationInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { result } = well;
  if (
    !result ||
    result.sequenceModificationStatus !== ModificationStatus.NonQuantifiable
  ) {
    return null;
  }
  return (
    <div className="relative">
      <EyeOff size={iconSize} />
    </div>
  );
};

const isWellEmpty = (well: WorkflowWell | undefined) => {
  return !well?.sequence && !well?.name && well?.errors.length === 0;
};

export const WellInside = ({
  well,
  display,
  iconSize = 24,
}: {
  display: Display;
  iconSize?: number;
  well: WorkflowWell | undefined;
}) => {
  if (!well || isWellEmpty(well)) {
    return null;
  }

  const Component = DISPLAY_PROPERTIES[display].WellInside;
  return <Component iconSize={iconSize} well={well} />;
};
