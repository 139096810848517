import { Button } from "@frontend/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@frontend/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@frontend/components/ui/form";
import { Input } from "@frontend/components/ui/input";
import { useToast } from "@frontend/components/ui/use-toast";
import { trpc } from "@frontend/config/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export default function DeleteOrganizationForm({
  id,
  name,
}: {
  id: string;
  name: string;
}) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: deleteOrganization } =
    trpc.account.organization.delete.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess() {
        utils.account.organization.list.invalidate();
        setOpen(false);
      },
    });

  const deleteOrganizationSchema = z.object({
    organizationName: z
      .string()
      .min(1)
      .refine((value) => value === name, {
        message: "Name is not correct.",
      }),
  });
  type DeleteOrganizationFormType = z.infer<typeof deleteOrganizationSchema>;

  const form = useForm<DeleteOrganizationFormType>({
    mode: "onChange",
    resolver: zodResolver(deleteOrganizationSchema),
  });

  const handleDeleteOrganization = () => {
    deleteOrganization({ id });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <div className="flex items-center gap-2">
          <Trash2 /> Delete
        </div>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(handleDeleteOrganization)}
          >
            <DialogHeader>
              <DialogTitle>Delete organization</DialogTitle>
              <DialogDescription>
                After deleting this organization, all associated data like
                assays, instruments, runs, ect will be lost without possibility
                to restore.
                <br />
                If you still want to delete this organization, please provide
                the organization name.
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="organizationName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button
                onClick={() => setOpen(false)}
                type="button"
                variant={"outline"}
              >
                Cancel
              </Button>
              <Button
                disabled={!form.formState.isValid}
                onClick={() => handleDeleteOrganization()}
              >
                Delete
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
