import { OrganizationStatus } from "@console/shared";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { useNavigate } from "react-router-dom";

import DeleteOrganizationForm from "./components/organization-action";
import { CheckIfTrueElseCross } from "./components/ui";

import { IsActive } from "../../../components/table/basic";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import { trpc, type AppRouter } from "../../../config/trpc";
import { AdminRoutes } from "../adminRoutes";

export type OrganizationDisplay = inferProcedureOutput<
  AppRouter["account"]["organization"]["list"]
>[0];

const columns: ColumnDef<OrganizationDisplay>[] = [
  {
    accessorKey: "id",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Organization ID",
    },
  },
  {
    accessorKey: "name",
    cell: ({ row }) => row.getValue("name"),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorFn: (row) => row.status === OrganizationStatus.ACTIVE,
    cell: ({ row }) => (
      <IsActive isActive={row.original.status === OrganizationStatus.ACTIVE} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "isActive",
    meta: {
      booleanFilter: true,
      title: "Active",
    },
  },
  {
    accessorKey: "configuration.clicksEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.clicksEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Labels",
    },
  },
  {
    accessorKey: "configuration.modsEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.modsEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Non-label modifications",
    },
  },
  {
    accessorKey: "configuration.degenerateBasesEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.degenerateBasesEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Degenerate bases",
    },
  },
  {
    accessorKey: "configuration.customProcessEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.customProcessEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Custom processes",
    },
  },
  {
    accessorKey: "configuration.geneEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.geneEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Gene processes",
    },
  },
  {
    accessorKey: "configuration.remoteRunTriggerEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.remoteRunTriggerEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "UI run trigger",
    },
  },
  {
    accessorKey: "configuration.serviceEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.serviceEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Service",
    },
  },
  {
    accessorKey: "configuration.bioSecurityEnabled",
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.bioSecurityEnabled} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "BioSecurity",
    },
  },
  {
    cell: (info) => (
      <TableActions
        items={[
          {
            children: (
              <DeleteOrganizationForm
                id={info.row.original.id}
                name={info.row.original.name}
              />
            ),
            id: "deleteOrganization",
            preventDefault: true,
          },
        ]}
      />
    ),
    id: "actions",
  },
];

export default function OrganizationList() {
  const navigate = useNavigate();
  const { data: organizations, isPending } =
    trpc.account.organization.list.useQuery(undefined);

  return (
    <DataTable
      columns={columns}
      data={organizations ?? []}
      loading={isPending}
      onRowClick={(row) =>
        navigate(
          AdminRoutes.ORGANIZATION_SETTINGS.replace(
            ":organizationId",
            row.original.id,
          ),
        )
      }
    />
  );
}
