import { Input } from "@frontend/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@frontend/components/ui/table";
import type {
  ColumnDef,
  Row,
  RowData,
  SortingState,
  Table as TanStackTableType,
  TableMeta,
} from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Fragment, useCallback, useEffect, useState } from "react";

import { DataTablePagination } from "./data-table-pagination";
import { DataTableViewOptions } from "./data-table-view-options";
import useStoreDataTableState from "./useStoreDataTableState";

import { cn } from "../../../lib/utils";
import { LoadingSpinner } from "../spinner";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    SelectionComponent?: React.ComponentType<{ row: Row<TData> }>;
    booleanFilter?: boolean;
    defaultFilter?: unknown;
    title?: string;
    uniqueFilter?: boolean;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    columnsFiltered?: string[];
    defaultPageSize?: number;
    paginationSizes?: number[];
    toggleFilterColumn?: (columnId: string) => void;
  }
}

export function DataTable<TData, TValue>({
  columns,
  data,
  loading,
  defaultSorting,
  disableQueryParams = false,
  onRowClick,
  onSelectionChange,
  tableContainerClassName,
  meta,
  resetSelection,
  enableRowSelection = undefined,
  RowComponent = TableRow,
  useBorders = true,
  usePagination = true,
  getRowId,
  tableRef,
  CustomSubRow,
  getRowCanExpand,
  paginateExpandedRows = true,
  getSubRows,
  autoResetPageIndex = true,
}: {
  CustomSubRow?: React.ComponentType<{ row: Row<TData> }>;
  RowComponent?: typeof TableRow;
  autoResetPageIndex?: boolean;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  defaultSorting?: SortingState;
  disableQueryParams?: boolean;
  enableRowSelection?: boolean | ((row: Row<TData>) => boolean) | undefined;
  getRowCanExpand?: (row: Row<TData>) => boolean;
  getRowId?: (row: TData) => string;
  getSubRows?: (originalRow: TData, index: number) => undefined | TData[];
  loading?: boolean;
  meta?: TableMeta<TData> | undefined;
  onRowClick?: (
    row: Row<TData>,
    ev: React.MouseEvent<HTMLTableRowElement>,
  ) => void;
  onSelectionChange?: (value: { id: string }[]) => void;
  paginateExpandedRows?: boolean;
  resetSelection?: number;
  tableContainerClassName?: string;
  tableRef?: React.MutableRefObject<TanStackTableType<TData> | null>;
  useBorders?: boolean;
  usePagination?: boolean;
}) {
  const [rowSelection, setRowSelection] = useState({});
  const [lastRowSelectedId, setLastRowSelectedId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (resetSelection) {
      setRowSelection({});
    }
  }, [resetSelection]);

  const {
    pagination,
    setPaginationFn,
    visibility: columnVisibility,
    setVisibilityFn,
    sorting,
    setSortingFn,
    columnsFiltered,
    setColumnsFilteredFn,
    columnFilters,
    setColumnFiltersFn,
    globalFilter,
    setGlobalFilterFn,
    expanded,
    setExpandedFn,
  } = useStoreDataTableState(meta?.defaultPageSize ?? 20, !disableQueryParams);

  useEffect(() => {
    if (!defaultSorting) {
      return;
    }
    setSortingFn(defaultSorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocalRowId = useCallback(
    (original: TData, index: number) =>
      getRowId ? getRowId(original) : index.toString(),
    [getRowId],
  );

  const table = useReactTable({
    autoResetPageIndex,
    columns,
    data,
    enableRowSelection,
    enableSubRowSelection: true,
    filterFromLeafRows: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: usePagination ? getPaginationRowModel() : undefined,
    getRowCanExpand: getRowCanExpand,
    getRowId: getLocalRowId,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: getSubRows,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    meta: {
      columnsFiltered,
      toggleFilterColumn(columnId) {
        const newColumnsFiltered = columnsFiltered.includes(columnId)
          ? columnsFiltered.filter((id) => id !== columnId)
          : [...columnsFiltered, columnId];
        setColumnsFilteredFn(newColumnsFiltered);
      },
      ...(meta || {}),
    },
    onColumnFiltersChange: (value) => {
      const newFilters =
        typeof value === "function" ? value(columnFilters) : value;
      setColumnFiltersFn(newFilters);
    },
    onColumnVisibilityChange: (value) => {
      const newVisibility =
        typeof value === "function" ? value(columnVisibility) : value;
      setVisibilityFn(newVisibility);
    },
    onExpandedChange: (value) => {
      const newExpanded = typeof value === "function" ? value(expanded) : value;
      setExpandedFn(newExpanded);
    },
    onGlobalFilterChange: (value) => {
      setGlobalFilterFn(value);
    },
    onPaginationChange: (value) => {
      const newPagination =
        typeof value === "function" ? value(pagination) : value;
      setPaginationFn(newPagination);
    },
    onRowSelectionChange: (value) => {
      const newRowSelection =
        typeof value === "function" ? value(rowSelection) : value;
      onSelectionChange?.(
        Object.entries(newRowSelection)
          .filter(([, value]) => value)
          .map(([key]) => ({ id: key })),
      );
      setRowSelection(newRowSelection);
    },
    onSortingChange: (value) => {
      const newSorting = typeof value === "function" ? value(sorting) : value;
      setSortingFn(newSorting);
    },
    paginateExpandedRows,
    state: {
      columnFilters,
      columnVisibility,
      expanded,
      globalFilter,
      pagination,
      rowSelection,
      sorting,
    },
  });

  const handleClickRow = useCallback(
    (row: Row<TData>, ev: React.MouseEvent<HTMLTableRowElement>) => {
      if (onRowClick) {
        onRowClick(row, ev);
      }
      if (!enableRowSelection) {
        return;
      }
      if (ev.shiftKey && lastRowSelectedId !== null) {
        const currentRows = table.getRowModel().rows;
        const indexLast = currentRows.findIndex(
          (o) => getLocalRowId(o.original, o.index) === lastRowSelectedId,
        );
        const indexCurrent = currentRows.findIndex(
          (o) =>
            getLocalRowId(o.original, o.index) ===
            getLocalRowId(row.original, row.index),
        );

        const [start, end] =
          indexLast < indexCurrent
            ? [indexLast, indexCurrent]
            : [indexCurrent, indexLast];
        table.setRowSelection((oldSelection) => {
          const newSelection = { ...oldSelection };
          for (let i = start; i <= end; i++) {
            const row = currentRows[i];
            newSelection[getLocalRowId(row.original, row.index)] = true;
          }
          return newSelection;
        });

        return;
      }

      setLastRowSelectedId(
        getRowId ? getRowId(row.original) : row.index.toString(),
      );

      row.getToggleSelectedHandler()({});
    },
    [
      enableRowSelection,
      getLocalRowId,
      getRowId,
      lastRowSelectedId,
      onRowClick,
      table,
    ],
  );

  useEffect(() => {
    if (!tableRef) {
      return;
    }
    tableRef.current = table;
  }, [table, tableRef]);

  if (loading) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      className={cn("bg-card", useBorders && "rounded-md border p-4 shadow-md")}
    >
      <div className="flex items-center space-x-2 py-4">
        <Input
          className="max-w-sm"
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          placeholder="Filter..."
          value={globalFilter as string}
        />
        <DataTableViewOptions table={table} />
      </div>
      <div className={cn(tableContainerClassName, loading && "opacity-50")}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={
                        header.getSize() !== 150 // default column size
                          ? {
                              width: header.getSize(),
                            }
                          : {}
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <RowComponent
                    className={onRowClick ? "cursor-pointer" : ""}
                    data-state={row.getIsSelected() && "selected"}
                    isSelected={row.getIsSelected()}
                    key={row.id}
                    onClick={(e) => {
                      handleClickRow(row, e);
                    }}
                    original={row.original}
                    tabIndex={0}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </RowComponent>
                  {row.getIsExpanded() && CustomSubRow && (
                    <RowComponent key={`sub-row-${row.id}`}>
                      <TableCell colSpan={columns.length}>
                        {flexRender(CustomSubRow, { row })}
                      </TableCell>
                    </RowComponent>
                  )}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {usePagination && (
        <div className="py-4">
          <DataTablePagination table={table} />
        </div>
      )}
    </div>
  );
}
