import type { PropsWithChildren } from "react";

import { cn } from "../../../../../../../../lib/utils";
import type { GeneDesignConfigurationType } from "../../logic/configuration";
import { OverlapInfo, PrimerLabel, PrimerLine } from "../common";

export function BlockPrimerInfo({
  configuration,
  sequence,
  start,
  end,
  label,
  color,
  className = "",
  children,
}: {
  className?: string;
  color: string;
  configuration: GeneDesignConfigurationType;
  end: number;
  label: string;
  sequence: string;
  start: number;
} & PropsWithChildren) {
  return (
    <div
      className={cn("group relative inline-block", className)}
      key="first-fw-primer-info"
      style={{
        marginLeft: start * configuration.nuclWidth,
        width: (end - start) * configuration.nuclWidth,
      }}
    >
      <div className="sticky left-0 right-0 inline-block text-sm">
        <PrimerLabel color={color} label={label} />
        <OverlapInfo
          configuration={configuration}
          end={end}
          sequence={sequence}
          start={start}
        />
        {children}
      </div>
    </div>
  );
}

export function BlockPrimerLine({
  bgColor,
  width,
  left,
  type,
  currentPosition,
  location,
}: {
  bgColor: string;
  currentPosition: number;
  left: number;
  location: "start" | "end";
  type: "forward" | "reverse";
  width: number;
}) {
  return (
    <div
      className={cn(
        "absolute z-10",
        type === "reverse" ? "bottom-[5px] " : "top-[5px] ",
      )}
      style={{
        left,
      }}
    >
      <PrimerLine bgColor={bgColor} type={type} width={width}>
        <div
          className={cn(
            "absolute top-0 flex h-full w-[10px] cursor-pointer select-none items-center bg-black text-white opacity-0 group-hover:opacity-20",
            type === "reverse" ? "left-0" : "right-0",
          )}
          data-blocktype="primer"
          data-currentpos={currentPosition}
          data-location={location}
          data-primertype={type}
        />
      </PrimerLine>
    </div>
  );
}

export function BlockBgColors({
  bgColor,
  color,
  left,
  width,
  zIndex,
}: {
  bgColor: string;
  color: string;
  left: number;
  width: number;
  zIndex: number;
}) {
  return (
    <div
      className="absolute top-0 top-0 inline-block h-full rounded"
      style={{
        backgroundColor: bgColor,
        border: `1px solid ${color}`,
        left,
        width,
        zIndex,
      }}
    />
  );
}

export function BlockOverlap({
  color,
  left,
  width,
}: {
  color: string;
  left: number;
  width: number;
}) {
  return (
    <div
      className="absolute top-[-5%] h-[110%] rounded border-2 border-dashed opacity-50"
      style={{
        borderColor: color,
        left,
        width,
        zIndex: 1,
      }}
    />
  );
}
