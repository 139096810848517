export function OligoBackgroundColor({
  bgColor,
  color,
  width,
  left,
  top,
}: {
  bgColor: string;
  color: string;
  left: number;
  top: number | string;
  width: number;
}) {
  return (
    <div
      className="absolute h-[49%] rounded"
      style={{
        backgroundColor: bgColor,
        border: `1px solid ${color}`,
        left,
        top,
        width,
      }}
    />
  );
}
